import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import HeroBannerImage from "../../../assets/images/hp_guy.png";
import HeroBannerImageGuy1 from "../../../assets/images/hp_guy1.png";
import HeroBannerImageGuy2 from "../../../assets/images/hp_guy2.png";
import HeroBannerImageGuy3 from "../../../assets/images/hp_guy3.png";
import HeroBannerImageGuy4 from "../../../assets/images/hp_guy4.png";

import HeroBannerImage2 from "../../../assets/images/Ellipse2.png";
import HeroBannerImage3 from "../../../assets/images/iso2.png";
import HeroBannerImage4 from "../../../assets/images/hp_orange.png";
import HeroBannerImage5 from "../../../assets/images/insights_1.png";
import HeroBannerImage6 from "../../../assets/images/form_type.png";

import marketing2 from '../../../assets/images/insights_1.png';
import marketing3 from '../../../assets/images/trainings_hp.png';
import marketing4 from '../../../assets/images/inventory_hp.png';
import marketing5 from '../../../assets/images/assets_hp.png';
import marketing6 from '../../../assets/images/actions_hp.png';

const ratingUrls = {
    capterra: "https://www.capterra.com/p/246063/SafetyLoop/reviews/",
    getApp: "https://www.getapp.com/operations-management-software/a/safetyloop/reviews/",
    softwareAdvice: "https://www.softwareadvice.ie/software/501273/SafetyLoop"
};

export default function HomePageHeroSection() {
    const [showDemoModal, setShowDemoModal] = useState(false);

    const handleOpenDemoModal = () => setShowDemoModal(true);
    const handleCloseDemoModal = () => setShowDemoModal(false);

    const demoUrl = "https://meetings.hubspot.com/bernie-borgmann";

    const heroTitles = [
        "Safety Compliance",
        "Compliance Simplified",
        "Compliance Solved",
        "Optimizing Safety",
        "Streamlined Safety",
        "Next-Gen Compliance",
        "Effortless Safety",
        "Compliance Mastery"
    ];
    
    const heroSubtexts = [
        "SAFETY MADE EASIER",
        "PROTECTING YOU SIMPLY",
        "SAFETY AT YOUR FINGERTIPS",
        "KEEPING YOU SAFE MADE SIMPLE",
        "INNOVATION THAT SAVES LIVES",
        "SOLUTIONS THAT PROTECT",
        "EFFORTLESS SAFETY COMPLIANCE",
        "SEAMLESS SAFETY INTEGRATION"
    ];
    
    const heroContentTexts = [
        "Protect your team with SafetyLoop's dynamic software. Streamline safety operations effortlessly, making compliance a natural part of keeping everyone safe every day.",
        "Empower your safety management with SafetyLoop. Our intuitive software transforms complex compliance into easy-to-handle tasks, always keeping your team's well-being at the forefront.",
        "Revolutionize your workplace safety with SafetyLoop's all-in-one platform. Our solutions bring clarity and efficiency to your safety efforts, ensuring everyone goes home safe.",
        "Achieve peace of mind with SafetyLoop’s intelligent software. Seamlessly integrate safety into your daily workflow, with real-time insights that protect what matters most—your people.",
        "Transform how you safeguard your team with SafetyLoop. Our innovative tools offer a comprehensive approach to compliance, giving your team the power to maintain a safe environment.",
        "Embrace a safer future with SafetyLoop. Our cutting-edge software combines advanced technology with ease of use, making safety management more personal and effective than ever.",
        "Enhance workplace safety with SafetyLoop’s Inventory and Order Management solutions. Track and control critical resources, ensuring safe and efficient operations at every step.",
        "Keep your assets secure and your team safe with SafetyLoop's Asset Management systems. Minimize risks and maximize safety by effectively managing all essential equipment and resources.",
        "SafetyLoop streamlines Time Tracking Management, helping you manage hours efficiently and safely. Optimize team availability with accurate tracking to ensure a secure and compliant workplace.",
        "Control inventory and orders with SafetyLoop, maintaining the safety and readiness of essential supplies. Let safety drive your inventory decisions for a more secure environment.",
        "SafetyLoop’s Asset Management solutions empower teams to monitor equipment life cycles with safety-first insights, reducing hazards and ensuring all assets remain reliable and secure.",
        "Gain real-time visibility into inventory status with SafetyLoop. Our system keeps your team prepared with safe, compliant stock levels for every critical operation.",
        "SafetyLoop optimizes Time Tracking with compliance in mind, ensuring every hour logged supports a safe, well-managed environment. Safety is in every second with SafetyLoop.",
        "Reduce downtime and improve safety through streamlined Inventory and Order Management. With SafetyLoop, know that essential supplies are readily available to keep operations secure.",
        "Track and manage critical assets seamlessly with SafetyLoop, keeping equipment functional and safe. From procurement to disposal, SafetyLoop safeguards every asset’s life cycle."
    ];
    
    const getRandomText = (texts) => {
        return texts[Math.floor(Math.random() * texts.length)];
    };

    const getRandomImage = (images) => {
        return images[Math.floor(Math.random() * images.length)];
    };

    const getRandomImageGuy = (images) => {
        return images[Math.floor(Math.random() * images.length)];
    };

    const marketingImages = [
        marketing2,
        marketing3,
        marketing4,
        marketing5,
        marketing6
    ];

    const randomGuys = [
        HeroBannerImage,
        HeroBannerImageGuy1,
        HeroBannerImageGuy2,
        HeroBannerImageGuy3,
        HeroBannerImageGuy4
    ];

    const heroBannerImageGuyRandom = getRandomImageGuy(randomGuys);
    const randomImage = getRandomImage(marketingImages);

    return (
        <div className="hero-section-container2">
            <div className="text-content">
                <h1 className="hero-title">{getRandomText(heroTitles)}</h1>
                <p className="hero-subtitle">{getRandomText(heroSubtexts)}</p>
                <p className="hero-description">
                    {getRandomText(heroContentTexts)}
                </p>
                <div className="hero-buttons">
                    {/* Replace the anchor with a Button triggering the modal */}
                    <Button className="book-demo-btn" onClick={handleOpenDemoModal}>
                        Book Demo
                    </Button>
                    <a href="/features">
                        <Button className="all-features-btn">See All Features</Button>
                    </a>
                </div>
                <div className="rating-section">
                    <div className="rating">
                        <a href={ratingUrls.capterra} target="_blank" rel="noopener noreferrer">
                            <img src="https://assets.capterra.com/badge/3c084a585dbd8a18109cb49be84b6bf7.svg?v=2203506&p=246063" style={{width:'190px'}} alt="Capterra Rating" />
                        </a>
                    </div>
                    <div className="rating">
                        <a href={ratingUrls.getApp} target="_blank" rel="noopener noreferrer">
                            <img src="https://brand-assets.getapp.com/badge/fe9aded2-7100-4e8c-ba7e-f3736937e112.png" style={{height:"64px", width:'100px'}} alt="GetApp Rating" />
                        </a>
                    </div>
                    <div className="rating">
                        <a href={ratingUrls.softwareAdvice} target="_blank" rel="noopener noreferrer">
                            <img src="https://brand-assets.softwareadvice.com/badge/73ed112e-c31c-4761-b78b-6adfc47782d2.png" style={{width:'170px', position:'absolute'}} alt="Software Advice Rating" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="image-content">
                <img src={heroBannerImageGuyRandom} alt="Compliance Simplified" className="hero-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage2} alt="Compliance Simplified" className="ellipse-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage3} alt="Compliance Simplified" className="iso-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage4} alt="Compliance Simplified" className="orange-main-image" />
            </div>
            <div className="image-content">
                <img src={randomImage} alt="Compliance Simplified" className="insights-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage6} alt="Compliance Simplified" className="form-type-main-image" />
            </div>


            {/* Modal for the Book Demo iframe */}
            <Modal show={showDemoModal} onHide={handleCloseDemoModal} size="xl" style={{ width: "100%", height: "81vh" }} >
                <Modal.Header closeButton>
                    <Modal.Title>Book Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe 
                        src={demoUrl}
                        title="Book a Demo"
                        style={{ width: "100%", height: "80vh", border: "none" }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}