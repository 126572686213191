import React, {useEffect, useRef, useState} from "react";
import SideNav from "../../../components/SideNav";
import { Link } from "react-router-dom";
import TaskPopup from "./Popups/Task";
import Cookies from "js-cookie";
import NotesPopup from "./Popups/Notes";
import ApproverPopup from "./Popups/Approver";
import ControlsPopup from "./Popups/Controls";
import Application from "../../../config/ApplicationWrapper";
import Norecords from "../../../assets/images/no-records.png";
import { toast } from 'react-toastify';
import _ from "lodash"
import Success from "../../../assets/fonts/Success.svg";
import PDFIMG from "../../../assets/fonts/pdf.png";
import IMG from "../../../assets/fonts/imgfile.png";
import Delete from "../../../assets/fonts/Delete.svg";
import Upload from "../../../assets/images/Upload.svg";
import axios from "axios";

const CreateRiskAssessment = (params) => {
  const [riskTypes, setRiskTypes] = React.useState([]);
  const [hazardId, setHazardId] = React.useState("");
  const [ratDetails, setRatDetails] = React.useState();
  const [image, setImage]  = useState();
  const [deletedImg, setDeletedImg] = useState([]);
  const uploadInputRef = useRef(null);
  const [notes, setNotes] = React.useState([]);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [changeData, setChangeData] = React.useState([]);
  const [showTaskPopup, setShowTaskPopup] = React.useState(false);
  const [showApproverPopup, setApproverPopup] = React.useState(false);
  const [showNotesPopup, setNotesTaskPopup] = React.useState(false);
  const [controlPopup, setControlPopup] = React.useState(false);
  const [approverData, setApproverData] = React.useState([]);
  const [riskRanges, setRiskRanges] = React.useState();
  const cookieDetails = Cookies.get("token");
  const [controlType, setControlType] = React.useState();
  const [type, setType] = React.useState();


  const createNewTask = (value) => {
    setShowTaskPopup(false);
  };

  const openTaskPopup = async (data) => {
    try {
      if (data) {
        setTaskDetails(data);
      } else {
        setTaskDetails("");
      }
      setShowTaskPopup(true);
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const closeShowPopup = (data) => {
    if (data) {
      setChangeData(data);
    }
    setShowTaskPopup(false);
  };

  const openNotesPopup = () => {
    setNotesTaskPopup(true);
  };

  const closeNotePopup = (data) => {
    if (data) {
      setChangeData(data);
    }
    setNotesTaskPopup(false);
  };

  const deleteTask = async (id) => {
    let delteTsk = await fetch(
        `${Application.api_url}/api/v2/risk_assesments/tasks/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: cookieDetails,
          },
        }
    );
    delteTsk = await delteTsk.json();
    setChangeData(delteTsk);
  };

  const openApproverPopup = () => {
    setApproverData(ratDetails.ctra_approved_users);
    setApproverPopup(true);
  };

  const closeApproverPopup = (data) => {
    if (data) {
      setChangeData(data);
    }
    setApproverPopup(false);
  };

  const createControl = (id, type) => {
    setHazardId(id);
    setControlPopup(true);
  };

  const closeControlPopup = (data) => {
    setControlPopup(false);
    if (data) {
      setChangeData(data);
    }
  };

  const deleteControl = async (id) => {
    try {
      let deleteCtrl = await fetch(
          `${Application.api_url}/api/v2/risk_assesments/controls/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: cookieDetails,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
      );
      setChangeData(deleteCtrl);
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const create = async (type) => {
    try {
      const formData = new FormData();
      formData.append("rat[name]", ratDetails.name);
      formData.append("rat[risk_type_id]", ratDetails.risk_type_id);
      formData.append("rat[assessment_type]", ratDetails.assessment_type);
      if(image)
        formData.append("rat[file]", image);

      await axios({
        method: "PUT",
        url: `${Application.api_url}/api/v2/rats/${params.match.params.id}`,
        data: formData,
      });

      if (!type) {
        window.location = "/risk-assessments";
        toast.success('Created successfully');
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const CustomComponent = ({ obj }) => {
    if (riskRanges && riskRanges.risk_calc != "Multiplication") {
      return (
          <>
          <span class="badge badge-primary" style={{ marginRight: "4px" }}>
            PRE :{" "}
            {Number(obj.pre_f) +
                Number(obj.pre_np) +
                Number(obj.pre_p) +
                Number(obj.pre_sp)}
          </span>
            <span class="badge badge-primary">
            POST :{" "}
              {Number(obj.post_f) +
                  Number(obj.post_np) +
                  Number(obj.post_p) +
                  Number(obj.post_sp)}
          </span>
          </>
      );
    } else {
      return (
          <>
          <span class="badge badge-primary" style={{ marginRight: "4px" }}>
            PRE :{" "}
            {Number(obj.pre_f) *
                Number(obj.pre_np) *
                Number(obj.pre_p) *
                Number(obj.pre_sp)}
          </span>
            <span class="badge badge-primary">
            POST :{" "}
              {Number(obj.post_f) *
                  Number(obj.post_np) *
                  Number(obj.post_p) *
                  Number(obj.post_sp)}
          </span>
          </>
      );
    }
  };

  useEffect(() => {
    const getRiskTypeDetails = async () => {
      try {
        const riskTypeDetails = await fetch(
            `${Application.api_url}/api/v2/risk_assesments/risk_types`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
        );
        const riskType = await riskTypeDetails.json();
        setRiskTypes(
            riskType && riskType.risk_types ? riskType.risk_types : []
        );

        let riskRangesData = await fetch(
            `${Application.api_url}/api/v2/risk_assesments/risk_ranges`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
        );
        riskRangesData = await riskRangesData.json();
        setRiskRanges(
            riskRangesData && riskRangesData.risk_range
                ? riskRangesData.risk_range
                : {}
        );
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getRiskTypeDetails();
  }, []);

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        let ratsData = await fetch(
            `${Application.api_url}/api/v2/rats/${params.match.params.id}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
        );
        ratsData = await ratsData.json();
        setRatDetails(ratsData && ratsData.rat ? ratsData.rat : {});
        setImage(ratsData && ratsData.rat ? ratsData.rat.file && ratsData.rat.file : '');
        setNotes(ratsData && ratsData.notes ? ratsData.notes : []);
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [changeData]);

  const addTask = () => {
    // riskRanges.green and riskRanges.yellow will be used in CustomComponent inside TaskPopup
    if (_.isEmpty(riskRanges) || _.isNil(riskRanges.green) || _.isNil(riskRanges.yellow)) {
      toast.error('You must complete the settings before adding a task')
    } else {
      openTaskPopup()
      setType("add")
      create("new")
    }
  }

  const removeImg = (val) => {
    setImage(null);
    if (params.match.params.id) {
      setDeletedImg([...deletedImg, val.id])
    }
  }

  function Upload() {
    console.log("image Data ============>", image)
    try {
      if (image) {
        return (
            <div className="attachments">
              <div className="list-file">
                <img src={Success} />
                <img src={image.name.split('.').pop().toLowerCase() === 'pdf' ? PDFIMG : IMG} className="uploaded-file" />
                <div>
                  <p>{image.name}</p>
                  <span>{image.size / 1000}KB</span>
                </div>
                <img src={Delete} className="delete" onClick={(e) => { removeImg(image) }} />
              </div>
            </div>
        )
      }
      else {
        return (
            <div className="row upload">
              <div className="col-md-6 upload-field">
                <input
                    color="primary"
                    accept="image/*,.pdf"
                    type="file"
                    id="icon-button-file"
                    ref={uploadInputRef}
                    style={{display: 'none',}}
                    onChange={(e) => {
                      setImage(e.target.files[0])
                    }}
                />
                <button onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        type="file">
                  <i className="fa fa-upload" style={{paddingRight: "5px"}}></i> Upload File</button>
              </div>
            </div>)
      }
    } catch (err) {
      console.log("error===>", err)
    }
  }

  return (
      <React.Fragment>
        {/* <div className="msmcontainer dashboard-container">
          <div className="row d-flex">
            <SideNav /> */}
            <div className="content-wrap col-sm-12 create-risk-assessment mt-75">
              <div className="card">
                <div className="card-body">
                  <div class="risk-assessment-link" style={{ padding: "15px" }}>
                    <Link to="/risk-assessments" id="create-risk-assessment-link">
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </Link>
                    &nbsp; Create Risk Assessment
                  </div>
                  <div style={{ padding: "0 15px 15px 15px" }}>
                    <h2>Risk Assessment Information</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <label style={{color:"#a3a3a3", letterSpacing:".3px", fontSize:"11px"}}>Risk Type
                          <span style={{color:"red"}}>*</span>
                        </label>
                        <select
                            className="form-control"
                            name="risk_type_id"
                            value={
                              ratDetails && ratDetails.risk_type_id
                                  ? ratDetails.risk_type_id
                                  : ""
                            }
                            onChange={(e) => {
                              setRatDetails({
                                ...ratDetails,
                                ...{ risk_type_id: e.target.value },
                              });
                            }}
                        >
                          <option value={""}>---Select--</option>
                          {riskTypes.map((val, index) => (
                              <option value={val.id}>{val.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label style={{color:"#a3a3a3", letterSpacing:".3px", fontSize:"11px"}}>Assessment Name
                          <span style={{color:"red"}}>*</span>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={
                              ratDetails && ratDetails.name ? ratDetails.name : ""
                            }
                            onChange={(e) => {
                              setRatDetails({
                                ...ratDetails,
                                ...{ name: e.target.value },
                              });
                            }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label style={{color: "#a3a3a3", letterSpacing: ".3px", fontSize: "11px", paddingTop: "10px"}}>Assessment
                          Type
                          <span style={{color: "red"}}>*</span>
                        </label>
                        <select
                            className="form-control"
                            name="assessment_id"
                            value={
                              ratDetails && ratDetails.assessment_type
                                  ? ratDetails.assessment_type
                                  : ""
                            }
                            onChange={(e) => {
                              setRatDetails({
                                ...ratDetails,
                                ...{ assessment_type: e.target.value },
                              });
                            }
                            }
                        >
                          <option value={""}>---Select--</option>
                          <option value="manual">Manual</option>
                          <option value="upload">Upload</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {ratDetails && ratDetails.assessment_type === 'manual' && (
                      <div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div className="risk-assessment-step-header">
                              <h2>Risk Assessment Step</h2>
                              {ratDetails && ratDetails.risk_type_id && (
                                  <button
                                      type="button"
                                      class="btn"
                                      style={{
                                        margin: "0 0px 15px 0",
                                        backgroundColor: "#64b5e3",
                                        color: "#fff",
                                        letterSpacing: ".4px",
                                        fontSize: "12px",
                                        fontWeight: "200"
                                      }}
                                      onClick={addTask}
                                  >
                                    <i class="fa fa-plus" aria-hidden="true"></i> Add Task
                                  </button>
                              )}
                            </div>
                            {ratDetails &&
                            ratDetails.tasks &&
                            ratDetails.tasks.length > 0 ? (
                                ratDetails.tasks.map((val, index) => (
                                    <table class="table">
                                      <thead>
                                      <tr>
                                        <th>Task Name</th>
                                        <th>
                                          Hazards / Controls
                                          <button
                                              className="btn"
                                              style={{ margin: "0 20px",backgroundColor: "#64b5e3", color: "#fff", letterSpacing: ".4px", fontSize: "12px", fontWeight: "200" }}
                                              onClick={(e) => {
                                                openTaskPopup(val);
                                              }}
                                          >
                                            <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                            ></i>
                                            Add Hazards
                                          </button>
                                        </th>
                                        <th style={{textAlign: "center"}}>Action</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr key={index}>
                                        <td>{val.name}</td>
                                        <td>
                                          {val &&
                                              val.hazards &&
                                              val.hazards.length > 0 &&
                                              val.hazards.map((obj, index) => (
                                                  <table className="hazards table table-striped">
                                                    <thead>
                                                    <tr>
                                                      <th>
                                                        <i
                                                            class="fa fa-exclamation-triangle"
                                                            aria-hidden="true"
                                                        ></i>
                                                        <span>{obj.name}</span>
                                                      </th>
                                                      <th>
                                                        <CustomComponent obj={obj} />
                                                        {/* <span
                                                class="badge badge-primary "
                                                style={{ marginRight: "4px" }}
                                              >
                                                PRE :{" "}
                                                {Number(obj.pre_f) +
                                                  Number(obj.pre_np) +
                                                  Number(obj.pre_p) +
                                                  Number(obj.pre_sp)}
                                              </span>
                                              <span class="badge badge-primary">
                                                POST :{" "}
                                                {Number(obj.post_f) +
                                                  Number(obj.post_np) +
                                                  Number(obj.post_p) +
                                                  Number(obj.post_sp)}
                                              </span> */}
                                                      </th>
                                                      <th>
                                                        <button
                                                            type="button"
                                                            class="btn"
                                                            style={{
                                                              border: "1px solid lightgrey",
                                                              marginRight: "5px"
                                                            }}
                                                            onClick={(e) => {
                                                              setControlType("Link")
                                                              createControl(obj.id);
                                                            }}
                                                        >
                                                          <i
                                                              class="fa fa-plus"
                                                              aria-hidden="true"
                                                          ></i>
                                                          Add Link
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn"
                                                            style={{
                                                              border: "1px solid lightgrey",
                                                            }}
                                                            onClick={(e) => {
                                                              setControlType("")
                                                              createControl(obj.id);
                                                            }}
                                                        >
                                                          <i
                                                              class="fa fa-plus"
                                                              aria-hidden="true"
                                                          ></i>
                                                          Add Control
                                                        </button>
                                                      </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {obj.controls.map((value, index) => (
                                                        <tr>
                                                          {
                                                            value.control_type == "Link" ? (
                                                                <td><a href={value.name} target="_blank" className="control-link">{value.name}</a></td>
                                                            ) : (
                                                                <td>{value.name}</td>
                                                            )
                                                          }
                                                          <td></td>
                                                          <td>
                                                            <i
                                                                class="fa fa-trash"
                                                                onClick={(e) => {
                                                                  deleteControl(value.id);
                                                                }}
                                                                style={{
                                                                  paddingTop: "5px",
                                                                  fontSize: "16px",
                                                                  color: "#FF3030",
                                                                }}
                                                            ></i>
                                                          </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                  </table>
                                              ))}
                                        </td>

                                        <td className="template-options" style={{textAlign: "center"}}>
                                          <i
                                              class="fa fa-trash"
                                              onClick={(e) => {
                                                deleteTask(val.id);
                                              }}
                                              style={{
                                                paddingTop: "5px",
                                                fontSize: "16px",
                                                color: "#FF3030",
                                              }}
                                          ></i>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                ))
                            ) : (
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th>Task Name</th>
                                    <th>Hazards / Controls</th>
                                    <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                            )}
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body" style={{ margin: "15px" }}>
                            <div className="risk-assessment-step-header">
                              <h2>Approver</h2>
                              <button
                                  type="button"
                                  class="btn"
                                  style={{ margin: "0 0px 15px 0", backgroundColor: "#64b5e3", color: "#fff", letterSpacing: ".4px", fontSize: "12px", fontWeight: "200" }}
                                  onClick={(e) => {
                                    openApproverPopup();
                                    create("new");
                                  }}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i> Add
                                Approver
                              </button>
                            </div>
                            <table className="table table-striped">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Company</th>
                              </tr>
                              </thead>
                              <tbody>
                              {ratDetails &&
                              ratDetails.ctra_approved_users &&
                              ratDetails.ctra_approved_users.length > 0 ? (
                                  ratDetails.ctra_approved_users.map((val, index) => (
                                      <tr>
                                        <td>{val.display_name}</td>
                                        <td>{val.company_name}</td>
                                      </tr>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="card  notes">
                          <div className="card-body"
                               style={{ margin: "15px" }}
                          >
                            <div className="risk-assessment-step-header">
                              <h2>Notes</h2>
                              <button
                                  type="button"
                                  class="btn"
                                  style={{ margin: "0 0px 15px 0", backgroundColor: "#64b5e3", color: "#fff", letterSpacing: ".4px", fontSize: "12px", fontWeight: "200" }}
                                  onClick={(e) => {
                                    openNotesPopup();
                                    create("new");
                                  }}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i> Add Note
                              </button>
                            </div>
                            <table className="table table-striped ">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Created On</th>
                                <th>Notes</th>
                              </tr>
                              </thead>
                              <tbody>
                              {notes && notes.length > 0 ? (
                                  notes.map((val, index) => (
                                      <tr key={index}>
                                        <td>{val.submitter}</td>
                                        <td>{val.created_at}</td>
                                        <td>{val.note}</td>
                                      </tr>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  )}
                  {ratDetails && ratDetails.assessment_type === 'upload' && (
                      <div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div className="risk-assessment-step-header">
                              <h2>Attachment</h2>
                            </div>
                            <Upload/>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div className="risk-assessment-step-header">
                              <h2>Approver</h2>
                              <button
                                  type="button"
                                  class="btn"
                                  style={{
                                    margin: "0 0px 15px 0",
                                    backgroundColor: "#64b5e3",
                                    color: "#fff",
                                    letterSpacing: ".4px",
                                    fontSize: "12px",
                                    fontWeight: "200"
                                  }}
                                  onClick={(e) => {
                                    openApproverPopup();
                                    create("new");
                                  }}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i> Add Approver
                              </button>
                            </div>
                            <table className="table table-striped">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Company</th>
                              </tr>
                              </thead>
                              <tbody>
                              {ratDetails &&
                              ratDetails.ctra_approved_users &&
                              ratDetails.ctra_approved_users.length > 0 ? (
                                  ratDetails.ctra_approved_users.map((val, index) => (
                                      <tr>
                                        <td>{val.display_name}</td>
                                        <td>{val.company_name}</td>
                                      </tr>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  )}
                  <div className="card-footer">
                    <button
                        type="button"
                        class="btn"
                        onClick={(e) => {
                          window.location = "/risk-assessments";
                        }}
                        style={{
                          marginRight: "10px",
                          color: "black",
                          backgroundColor: "#ECECEC",
                        }}
                    >
                      Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        disabled={
                          ratDetails && ratDetails.risk_type_id && ratDetails.name
                              ? false
                              : true
                        }
                        onClick={(e) => {
                          create();
                        }}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          {/* </div>
        </div> */}
        <TaskPopup
            show={showTaskPopup}
            closepopup={closeShowPopup}
            saveTask={createNewTask}
            ratDetails={ratDetails}
            taskDetails={taskDetails}
            type={type}
        />
        <NotesPopup
            show={showNotesPopup}
            closepopup={closeNotePopup}
            ratId={params.match.params.id}
        />
        <ControlsPopup
            show={controlPopup}
            closepopup={closeControlPopup}
            hazardId={hazardId}
            type={controlType}
        />
        <ApproverPopup
            show={showApproverPopup}
            closepopup={closeApproverPopup}
            ratId={params.match.params.id}
            data={approverData}
        />
      </React.Fragment>
  );
};

export default CreateRiskAssessment;
