import { Tab, Tabs, styled } from '@mui/material'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

const AntTabs = styled(Tabs)({
  minHeight: '40px',
  marginRight: '1px',
  borderBottom: '2px solid #5C5C5C',
  fontFamily: 'Inter !important',
  width: 'fit-content',
  '& .MuiTabs-indicator': {
    backgroundColor: '#407797',
    height: '1px',
  },
})

const AntTab = styled(Tab)({
  textTransform: 'none',
  color: '#5C5C5C',
  fontWeight: 600,
  minHeight: '40px',
  marginRight: '1px',
  padding: '8px 16px',
  backgroundColor: '#ECF1F5',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  background: '#F6F6F6',
  '.custom-label': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.count': {
      display: 'flex',
      height: '18px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '32px',
      background: '#5C5C5C',
      color: '#fff',
      padding: '0 8px',
    },
    '.count.active': {
      background: '#407797',
    },
  },
  '&.Mui-selected': {
    color: '#407797',
  },
  '.MuiTab-root': {
    borderBottom: '1px solid #5c5c5c',
    padding: '0 16px',
    // fontFamily: 'Inter !important'
  },
})

const Wrapper = styled('div')({
  backgroundColor: '#65B5E3',
  borderRadius: '4px 4px 0px 0px',
  paddingInline: '24px',
  paddingTop: '15px',
  '.title': {
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: '700',
  },
  '.title-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '15px',
  },
})

const SubTabs = ({
  children,
  tabList,
  withTitle,
  title,
  extras = [],
  pageCount = '',
  activeCallback,
}) => {
  const [activeKey, setActive] = useState(tabList[0].value)
  const handleChange = async (_, value) => {
    if (activeCallback) {
      const isValid = await activeCallback(value)
      if (isValid) {
        setActive(value)
        Cookies.set('tab', value)
      }
    } else {
      setActive(value)
      Cookies.set('tab', value)
    }
  }
  return (
    <>
      {!withTitle ? (
        <AntTabs style={{borderColor: '#D3D3D3', width: '100%'}} value={activeKey} onChange={handleChange}>
          {tabList.map((tab) => (
            <AntTab value={tab.value} label={tab.label} />
          ))}
        </AntTabs>
      ) : (
        <Wrapper>
          <div className="title-wrapper">
            <div className="title">{title || ''}</div>
            {title === 'Records Of Training'
              ? ['all', 'archived'].includes(activeKey) && extras
              : extras}
          </div>
          <AntTabs value={activeKey} onChange={handleChange}>
            {tabList.map((tab) => (
              <AntTab
                value={tab.value}
                label={
                  <div className="custom-label">
                    <span style={{ padding: '0 10px' }}>{tab.label}</span>{' '}
                    {pageCount[activeKey] || pageCount[tab.value] ? (
                      <span
                        className={` ${pageCount[tab.value] ? 'count' : ''} ${
                          activeKey === tab.value ? 'active' : ''
                        }`}
                      >
                        {activeKey === tab.value
                          ? (pageCount && pageCount[activeKey]) || ''
                          : pageCount[tab.value] || ''}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                }
              />
            ))}
          </AntTabs>
        </Wrapper>
      )}
      {children && children(activeKey)}
    </>
  )
}

export default SubTabs
