import React from "react"
import { Link } from "react-router-dom"
import PricingCircleTick from "./PricingCircleTick"
import '../../assets/styles/themes.scss'
import freeTrial  from '../../assets/images/free-trial.png'

const PricingCard = ({
  id,
  name,
  description,
  price,
  currency,
  interval,
  intervalUnit,
  billingCycle
}) => {
  const hasPrice = price != null
  let type
  let priceText
  let options
  let buttonText
  let buttonLink = `/checkout?subscription_plan_id=${id}`
  const integerPrice = parseFloat(price)

  if (integerPrice === 0) {
    type = 'free'
    // priceText = integerPrice.toString()
    options = [
      // { text: '2 User Account Max', isBold: false },
      // { text: 'Up to 25 Action Items monthly', isBold: false },
      // { text: 'Up to 5 Risk Assessments', isBold: false },
      // { text: 'Up to 3 Form Templates', isBold: false },
      // { text: '100mb of Document Storage', isBold: false },
      // { text: 'Up to 2 Emergency Plans', isBold: false },
      // { text: 'Up to 10 Training Records', isBold: false },
      // { text: 'No Access to Eco-system', isBold: false },
      // { text: 'Up to 10 TO DO Lists', isBold: false },
      // { text: 'Up to 10 QR Codes', isBold: false },
      // { text: 'Up to 25 SMS sends per month', isBold: false },
      // { text: 'Access to Mobile App lite', isBold: false }
    ]
    buttonText = 'Create an Account Now'
  } else if (hasPrice) {

    if(name=="Safety Starter") {
      type = 'starter'
      // priceText = integerPrice.toString()
      options = [
        // { text: 'Each user seat is $20/mo', isBold: false },
        // { text: 'Up to 50 Action Items monthly', isBold: false },
        // { text: 'Up to 5 Risk Assessments', isBold: false },
        // { text: 'Up to 10 Form Templates', isBold: false },
        // { text: 'Up to 100 Forms Completed Monthly', isBold: false },
        // { text: '1GB of Document Storage', isBold: false },
        // { text: 'Up to 5 Emergency Plans', isBold: false },
        // { text: 'Up to 100 Total Training Records', isBold: false },
        // { text: 'Unlimited TO DO Lists', isBold: false },
        // { text: 'Up to 100 QR Codes', isBold: false },
        // { text: 'Up to 25 SMS sends per week', isBold: false },
        // { text: 'Access to Mobile App', isBold: false },
        // { text: 'Access to basic analytics', isBold: false },
        // { text: 'Access to Inventory*', isBold: false },
        // { text: 'Access to Assets*', isBold: false },
        // { text: 'Access to Timetracking*', isBold: false }
      ]
      // buttonText = `Start ${interval} ${intervalUnit} Free Trial`
      buttonText = 'Create an Account Now'

    } if(name=="Basic") {
    
      type = 'starter-basic'
      // priceText = integerPrice.toString()
      options = [
      ]

      buttonText = 'Create an Account Now'

    } else if(name=="Plus") {
    
      type = 'starter-plus'
      // priceText = integerPrice.toString()
      options = [
      ]

      buttonText = 'Start a 14 day FREE Trial'

    }  else {

      type = 'pro'
      // priceText = integerPrice.toString()
      options = [
        // { text: 'In Addition to Free', isBold: true },
        // { text: 'Unlimited  Assets', isBold: false },
        // { text: 'Sub-Contractor Management', isBold: false },
        // { text: 'Statistics Dashboard', isBold: false }
        // { text: 'Each user seat is $50/mo', isBold: false },
        // { text: 'Unlimited Action Items', isBold: false },
        // { text: 'Up to 50 Risk Assessments per user', isBold: false },
        // { text: 'Unlimited Assets', isBold: false },
        // { text: 'Unlimited Form Templates', isBold: false },
        // { text: 'Up to 1000 Forms Completed Monthly', isBold: false },
        // { text: '20GB of Document Storage', isBold: false },
        // { text: 'Unlimited Emergency Plans', isBold: false },
        // { text: 'Unlimited Training Records', isBold: false },
        // { text: 'Asset Management', isBold: false },
        // { text: 'Unlimited access to Eco-system', isBold: false },
        // { text: 'Unlimited TO DO Lists', isBold: false },
        // { text: 'Unlimited QR Codes', isBold: false },
        // { text: 'Access to integrations', isBold: false },
        // { text: 'Access to Mobile App', isBold: false },
        // { text: 'Access to Analytics*', isBold: false },
        // { text: 'Access to Inventory*', isBold: false },
        // { text: 'Access to Assets*', isBold: false },
        // { text: 'Access to Timetracking*', isBold: false }
      ]
      // buttonText = `Start ${interval} ${intervalUnit} Free Trial`
      buttonText = 'Start a 14 day FREE Trial'
    }

  } else {
    type = 'enterprise'
    priceText = `Sign-up`
    options = [
      // { text: 'Plans starting at $250/mo (when paid annually). FLAT RATE PLANS to support all levels. Sign up and we will contact you during the free trial. Along with a custom quote', isBold: true },
      // { text: 'Monthly plans', isBold: false },
      // { text: 'Annual savings plans', isBold: false },
      // { text: 'Access to custom analytics*', isBold: false },
      // { text: 'Access to OHS Professionals', isBold: false },
      // { text: 'Annual Support', isBold: false },
      // { text: 'Group or Project Based Billing', isBold: false },
    ]
    buttonText = `Get Started today`
    // buttonLink = '/contact'
  }

  return (
    <div className='plan__card'>
      <div className={`price ${type} ${hasPrice ? '' : 'contact'}` }>
        {type === 'enterprise' ?
          <div style={{fontSize: "18px", position:"absolute", marginTop:"-20px", marginLeft:"45px", color: "#407797", }}><i className="fa fa-star" aria-hidden="true"></i> Most Popular <i className="fa fa-star" aria-hidden="true"></i></div>
          :
          <div></div>
        }

        <p className='title' style={{fontSize:'28px'}}>
          {name}
        </p>
        {
          type === 'enterprise' ?
          <div className="price-text" style={{fontSize:'12px', textAlign:'center', marginBottom:'75px', marginTop:'15px'}}>
            For any size businesses who need advanced integrations and dedicated support.  Flat fee pricing monthly or annual.
          </div>
          : type === 'starter' ?
            <div>
              <div className="price-text" style={{fontSize:'12px', textAlign:'center', marginBottom:'50px', marginTop:'15px'}}>
                Perfect for smaller businesses needing a safety solution, that doesn't break any budgets.
              </div>
              <div className="row" >

                <div className="col-md-4 price-text">
                  {price}
                </div>
                
                <div className="col-md-8" style={{textAlign:'left'}}>
                  <div className="price-sub-text">
                    / User / Month
                    <br />
                    when billed {billingCycle}
                  </div>
                </div>
              </div>
            </div>
          : type === 'free' ?
            <div>
              <div className="price-text" style={{fontSize:'12px', textAlign:'center', marginBottom:'50px', marginTop:'15px'}}>
                For businesses interested in exploring a limited version with no financial commitment. 2 users max.
              </div>
              <div className="row" >

                <div className="col-md-4 price-text">
                  {price}
                </div>
                <div className="col-md-8" style={{textAlign:'left'}}>
                  <div className="price-sub-text">
                    / User / {billingCycle}
                    <br />
                    when billed {billingCycle}
                  </div>
                </div>
              </div>
            </div>



          :
            <div>
              <div className="price-text" style={{fontSize:'12px', textAlign:'center', marginBottom:'3px', marginTop:'15px'}}>
                For organizations seeking advanced insights and enhanced collaboration to elevate their business performance.
              </div>
              <div className="row" style={{marginTop:'49px'}} >

                <div className="col-md-4 price-text" >
                  {price}
                </div>
                <div className="col-md-8" style={{textAlign:'left'}}>
                  <div className="price-sub-text">
                    / User / Month
                    <br />
                    when billed {billingCycle}
                  </div>
                </div>
              </div>
            </div>
        }




        {/* <span className='price--label'>{currency}</span> */}
        {type === 'starter' || type === 'free' || type == 'starter-basic' ?
          <></>
          : type === 'enterprise' ?
          <img src={freeTrial} style={{width:"70px",position:"absolute", marginLeft:"-190px", marginTop:"0px"}}/>
          :
          <img src={freeTrial} style={{width:"70px",position:"absolute", marginLeft:"-190px", marginTop:"0px"}}/>
        }

      </div>


      <div className='eSeparator--horizontal'></div>
      <div className='description'>
        {description}
      </div>
      <div className={`eButton ${type}`}>
        <Link to={buttonLink} style={{ color: 'inherit', textDecoration: 'inherit'}}>
          {buttonText}
        </Link>
      </div>
      <div className='options'>
        {options.map((option) => {
          return (
            <div key={option.text} className="option">
              {!option.isBold && <PricingCircleTick className={`prefix ${type}`} />}
              <p className={`text ${option.isBold && 'isBold'}`}>{option.text}</p>
            </div>
          )
        })}
      </div>
      {
          // type === 'pro' ?
          //   // <div className='card--footer' style={{fontSize:'11px', backgroundColor:'#fff', padding: '2px', marginTop:'-10px', position: 'relative'}}>Custom Analytics, Inventory, Timetracking are an additional charge monthly.  This plan gives access to these sections to try or purchase as small additional fee monthly.</div>
          // : type === 'starter' ?
          //   // <div className='card--footer' style={{fontSize:'11px', backgroundColor:'#fff', padding: '2px', marginTop:'45px', position: 'relative'}}>Custom Analytics, Inventory, Timetracking are an additional charge monthly.  This plan gives access to these sections to try or purchase as small additional fee monthly.</div>
          // :
          // ""
      }
    </div>
  )
}

export default PricingCard
