import React, { useEffect, useState, createContext } from "react";
import SideNav from "../../../../components/SideNav";
import CloseButton from "../../../../assets/images/CloseMenu.svg";
import LeftArrow from "../../../../assets/images/LeftArrow.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Application from "../../../../config/ApplicationWrapper";
import AssetInfo from "./AssetInfo";
import RelatedAssetDetails from "./RelatedAssetDetails";
import MaintenanceSchedule from "./MaintenanceSchedule";
import GeoLocationView from "./GeoLocation";
import AssetDocuments from "./AssetDocuments";
import HistoricalLogs from "./HistoricalLogs";
import RelatedForms from "./RelatedForms";
import loader from "../../../../assets/images/newloader.gif";


export const AssetDetailsContext = createContext();

const ViewAsset = (params) => {
  const [pageData, setPageData] = useState("");
  const [type, setType] = useState("");
  const cookieDetails = Cookies.get("token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        const result = await fetch(
          `${Application.api_url}/api/v2/asset_trackings/${params.match.params.id}`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );

        if (result.status == 200) {
          let assetDetails = await result.json();
          setPageData(
            assetDetails && assetDetails.data ? assetDetails.data : ""
          );
          setTimeout(function () {
            setLoading(true);
          }, 500)
        }
      } catch (err) {
        console.log("error==>", err);
      }
    };
    setType(params.match.params.type);
    getPageDetails();
  }, []);

  return (
    <React.Fragment>
      {/* <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav /> */}
          <div className="content-wrap asset-tracking-wrap risk-assessment view-asset">
            <div className="title-tab-wrap">
              <h1>
                <img src={CloseButton} alt="" className="header-closer-img" />
                <b>Asset Tracking</b>
              </h1>
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#risk-assessment">
                    Asset Tracking
                  </a>
                </li>
              </ul>
            </div>
            <div className="asset-tracking-create-column">
              <div className="create-body">
                <div className="create-title">
                  <Link to="/asset-tracking">
                    <img src={LeftArrow} alt="" />
                  </Link>
                  &nbsp;
                  <span className="create-header"> View Asset</span>
                  <span className="create-header-desc">- {pageData.name}</span>
                </div>
                <div className="create-pages"></div>
              </div>
              <div>
                <div className="card-body">
                  <ul className="nav nav-tabs">
                    <li className="active">
                      <a data-toggle="tab" href="#asset-info">
                        Asset Information
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#related-asset">
                        Related Asset
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#maintenance-schedule">
                        Maintenance Schedule
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#geo-location-tracking">
                        Geo Location Tracking
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#asset-docs">
                        Asset Documents
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#historical-logs">
                        Historical Logs
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#related-forms">
                        Related Forms
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    {loading === true ? (
                      <AssetDetailsContext.Provider value={{ pageData, type }}>
                        <div
                          id="asset-info"
                          className="tab-pane fade in active active-risk-assessment"
                        >
                          <AssetInfo />
                        </div>
                        <div
                          id="related-asset"
                          className="tab-pane fade in active-risk-assessment"
                        >
                          <h3 className="title">
                            <b>Related Assets</b>
                          </h3>
                          <RelatedAssetDetails />
                        </div>
                        <div
                          id="maintenance-schedule"
                          className="tab-pane fade in active-risk-assessment"
                        >
                          <h3 className="title">
                            <b>Maintenance Schedule</b>
                          </h3>
                          <MaintenanceSchedule />
                        </div>
                        <div
                          id="geo-location-tracking"
                          className="tab-pane fade in active-risk-assessment geo-tracking"
                        >
                          <h3 className="title"><b>Geo Location Tracking</b></h3>
                          <GeoLocationView />
                        </div>
                        <div
                          id="asset-docs"
                          className="tab-pane fade in active-risk-assessment geo-tracking"
                        >
                          <h3 className="title"><b>Asset Documents</b></h3>
                          <AssetDocuments />
                        </div>
                        <div
                          id="historical-logs"
                          className="tab-pane fade in active-risk-assessment historical-logs"
                        >
                          <HistoricalLogs />
                        </div>
                        <div
                          id="related-forms"
                          className="tab-pane fade in related-forms"
                        >
                          <RelatedForms />
                        </div>
                      </AssetDetailsContext.Provider>
                    ) : (
                      <div className="new-loader">
                        <img src={loader} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default ViewAsset;
