import React from 'react'
export const UserGroupIcons = {
  pdf: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 11V15H12C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11H11Z"
        stroke="#080808"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 13H8.5C8.76522 13 9.01957 12.8946 9.20711 12.7071C9.39464 12.5196 9.5 12.2652 9.5 12C9.5 11.7348 9.39464 11.4804 9.20711 11.2929C9.01957 11.1054 8.76522 11 8.5 11H7.5V15"
        stroke="#080808"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 13H16.5"
        stroke="#080808"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 11H14.5V15"
        stroke="#080808"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  archiveBlue: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.76758 4C1.76758 3.64638 1.90805 3.30724 2.1581 3.05719C2.40815 2.80714 2.74729 2.66667 3.10091 2.66667H12.4342C12.7879 2.66667 13.127 2.80714 13.3771 3.05719C13.6271 3.30724 13.7676 3.64638 13.7676 4C13.7676 4.35362 13.6271 4.69276 13.3771 4.94281C13.127 5.19286 12.7879 5.33333 12.4342 5.33333H3.10091C2.74729 5.33333 2.40815 5.19286 2.1581 4.94281C1.90805 4.69276 1.76758 4.35362 1.76758 4Z"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.10083 5.33333V12C3.10083 12.3536 3.24131 12.6928 3.49135 12.9428C3.7414 13.1929 4.08054 13.3333 4.43416 13.3333H11.1008C11.4545 13.3333 11.7936 13.1929 12.0436 12.9428C12.2937 12.6928 12.4342 12.3536 12.4342 12V5.33333"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.43408 8H9.10075"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  QRCode: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M3,11h8V3H3V11z M5,5h4v4H5V5z" />
          <path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z" />
          <path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z" />
          <rect height="2" width="2" x="19" y="19" />
          <rect height="2" width="2" x="13" y="13" />
          <rect height="2" width="2" x="15" y="15" />
          <rect height="2" width="2" x="13" y="17" />
          <rect height="2" width="2" x="15" y="19" />
          <rect height="2" width="2" x="17" y="17" />
          <rect height="2" width="2" x="17" y="13" />
          <rect height="2" width="2" x="19" y="15" />
        </g>
      </g>
    </svg>
  ),
  view: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333 7.333 22 12Z"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  edit: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_9550_16620)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M9 7.00098H6C5.46957 7.00098 4.96086 7.21169 4.58579 7.58676C4.21071 7.96184 4 8.47054 4 9.00098V18.001C4 18.5314 4.21071 19.0401 4.58579 19.4152C4.96086 19.7903 5.46957 20.001 6 20.001H15C15.5304 20.001 16.0391 19.7903 16.4142 19.4152C16.7893 19.0401 17 18.5314 17 18.001V15.001"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 15.0012H12L20.5 6.5012C20.8978 6.10338 21.1213 5.56381 21.1213 5.0012C21.1213 4.43859 20.8978 3.89903 20.5 3.5012C20.1022 3.10338 19.5626 2.87988 19 2.87988C18.4374 2.87988 17.8978 3.10338 17.5 3.5012L9 12.0012V15.0012Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9550_16620">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  delete: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_9550_7109)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M4 7.00098H20"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 11.001V17.001"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 11.001V17.001"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 7.00098L6 19.001C6 19.5314 6.21071 20.0401 6.58579 20.4152C6.96086 20.7903 7.46957 21.001 8 21.001H16C16.5304 21.001 17.0391 20.7903 17.4142 20.4152C17.7893 20.0401 18 19.5314 18 19.001L19 7.00098"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 7.00098V4.00098C9 3.73576 9.10536 3.48141 9.29289 3.29387C9.48043 3.10633 9.73478 3.00098 10 3.00098H14C14.2652 3.00098 14.5196 3.10633 14.7071 3.29387C14.8946 3.48141 15 3.73576 15 4.00098V7.00098"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9550_7109">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  archive: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_12022_55004)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M2.65112 6.00098C2.65112 5.47054 2.86184 4.96184 3.23691 4.58676C3.61198 4.21169 4.12069 4.00098 4.65112 4.00098H18.6511C19.1816 4.00098 19.6903 4.21169 20.0653 4.58676C20.4404 4.96184 20.6511 5.47054 20.6511 6.00098C20.6511 6.53141 20.4404 7.04012 20.0653 7.41519C19.6903 7.79026 19.1816 8.00098 18.6511 8.00098H4.65112C4.12069 8.00098 3.61198 7.79026 3.23691 7.41519C2.86184 7.04012 2.65112 6.53141 2.65112 6.00098Z"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.65112 8.00098V18.001C4.65112 18.5314 4.86184 19.0401 5.23691 19.4152C5.61198 19.7903 6.12069 20.001 6.65112 20.001H16.6511C17.1816 20.001 17.6903 19.7903 18.0653 19.4152C18.4404 19.0401 18.6511 18.5314 18.6511 18.001V8.00098"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.65112 12.001H13.6511"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12022_55004">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  ArrowRight: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M7.08337 15.833L12.9167 9.99967L7.08337 4.16634"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  duplicate: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9892_7005)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M18 8.00098H10C8.89543 8.00098 8 8.89641 8 10.001V18.001C8 19.1055 8.89543 20.001 10 20.001H18C19.1046 20.001 20 19.1055 20 18.001V10.001C20 8.89641 19.1046 8.00098 18 8.00098Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 8.00098V6.00098C16 5.47054 15.7893 4.96184 15.4142 4.58676C15.0391 4.21169 14.5304 4.00098 14 4.00098H6C5.46957 4.00098 4.96086 4.21169 4.58579 4.58676C4.21071 4.96184 4 5.47054 4 6.00098V14.001C4 14.5314 4.21071 15.0401 4.58579 15.4152C4.96086 15.7903 5.46957 16.001 6 16.001H8"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9892_7005">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  requiredDocument: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9892_3101)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M5 4.00098H9L12 7.00098H19C19.5304 7.00098 20.0391 7.21169 20.4142 7.58676C20.7893 7.96184 21 8.47054 21 9.00098V17.001C21 17.5314 20.7893 18.0401 20.4142 18.4152C20.0391 18.7903 19.5304 19.001 19 19.001H5C4.46957 19.001 3.96086 18.7903 3.58579 18.4152C3.21071 18.0401 3 17.5314 3 17.001V6.00098C3 5.47054 3.21071 4.96184 3.58579 4.58676C3.96086 4.21169 4.46957 4.00098 5 4.00098"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 10.001V16.001"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 13.001H15"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9892_3101">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  clearInput: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="12"
      height="12"
      aria-hidden="true"
    >
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </svg>
  ),
  closeIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
}
