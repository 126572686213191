import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

const useFetch = (url, initalState = {}, maxRetries = 3) => {
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetchDataWithRetry = async (retryCount = maxRetries) => {
    const { filters = {}, pagination = {}, sort = {} } = initalState

    const params = {
      ...filters,
      ...pagination,
    }

    if (sort.name) {
      params.sort = `${sort.name || ''} ${sort.value || ''}`
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== '' && value !== null && value !== undefined
      )
    )

    for (let attempt = 0; attempt <= retryCount; attempt++) {
      try {
        const response = await axios.get(url, { params: filteredParams })
        setData(response.data)
        setError(null)
        return // Exit loop if successful
      } catch (err) {
        setError(err)
        if (attempt < retryCount) {
          // Wait for some time before retrying (exponential backoff)
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * 2 ** attempt)
          )
        } else {
          console.error(`Request failed after ${maxRetries} retries`, err)
        }
      }
    }
  }

  useEffect(() => {
    setIsFetching(true)
    setLoading(true)
    fetchDataWithRetry().finally(() => {
      setLoading(false)
      setIsFetching(false)
    })
  }, [url])

  const refetch = async ({ filters = {}, pagination = {}, sort = {} } = {}) => {
    setIsFetching(true)
    await fetchDataWithRetry()
    setIsFetching(false)
  }

  return { data, isLoading, error, refetch, isFetching, loading: isLoading }
}
export const useFetchInfiniteForKanban = (url) => {
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetchData = useCallback(async () => {
    setIsFetching(true)

    try {
      const response = await axios.get(url)
      // If we are fetching for the first time or when loading more, update data
      if (url.includes('page=1')) {
        setData(response.data)
      } else {
        setData((prevData) => ({
          ...prevData,
          metadata: response.data.metadata,
          data: [...prevData.data, ...response.data.data],
        }))
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
      setIsFetching(false)
    }
  }, [url])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    data,
    isLoading,
    error,
    isFetching,
    loading: isLoading,
    setData,
  }
}
export default useFetch
