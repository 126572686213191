import {
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { experimentalStyled as styled } from '@mui/material/styles'
import React from 'react'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))
export default function ROTCARD({
  label = '',
  value = '',
  handleCardClick,
  selectedCard,
  isBold,
}) {
  const isXlScreen = useMediaQuery('(min-width: 2560px)') // 2560px and above
  const isLgScreen = useMediaQuery('(min-width: 1920px)') // 1920px and above
  const isMdScreen = useMediaQuery('(min-width: 1440px)') // 1440px and above
  const isSmScreen = useMediaQuery('(min-width: 1024px)') // 1024px and above
  return (
    <>
      <Grid item xs={3} sm={3} md={3} lg={3} key={label}>
        <Item
          sx={{
            border: '1px solid #ececec',
            borderRadius: '4px',
            boxShadow: 'none',
            background: selectedCard === label ? '#ececec' : '#fff',
            padding: isSmScreen ? '10px' : '16px',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#909090',
                  fontWeight: 500,
                  fontSize: isXlScreen
                    ? '16px'
                    : isLgScreen
                    ? '14px'
                    : isMdScreen
                    ? '13px'
                    : '11px',
                }}
              >
                {label}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: selectedCard === label ? '#d92929' : '#407797',
                  fontWeight: isBold ? 700 : 500,
                  fontSize: isXlScreen
                    ? '30px'
                    : isLgScreen
                    ? '28px'
                    : isMdScreen
                    ? '26px'
                    : '22px',
                  lineHeight: '0.8',
                }}
              >
                {value}
              </Typography>
            </Stack>
            {!isBold && (
              <Link
                component="button"
                sx={{
                  color: selectedCard === label ? '#D92929' : '#407797',
                  fontWeight: 500,
                  fontSize: '13px',
                }}
                variant="body2"
                onClick={() => handleCardClick(label)}
              >
                {selectedCard === label ? 'Reset' : 'View'}
              </Link>
            )}
          </Stack>
        </Item>
      </Grid>
    </>
  )
}
