import {
  Autocomplete,
  Box,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import ActiveFilters from '../../../shared/components/activeFilters'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  LinkTraining,
} from '../../../shared/components/table'
import useFetch from '../../../shared/components/userFetch'
// import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { connect } from 'react-redux'
import { setROTPageCount } from '../../../../reducers/common'
import AlertDialog from '../../../shared/components/alretDialog'
import CustomDateRangeFilter from '../../../shared/components/customDateRangeFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'

const TableWrapper = styled('div')`
  .action_name,
  .action_priority_name,
  .action_sub_category,
  .action_notices_status,
  .action_action_type,
  .action_group,
  .action_created_by,
  .action_created_at,
  .action_create_on,
`

const ArchiveTrainingList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  rotCount,
  setROTPageCount,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/rot_management/record_of_trainings/archived', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/base_data?activated_eq=false'
  )
  const { mutate, isLoading: isRestoring } = usePatch()
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}
  const { permissions: { allow_restore = true } = {} } = baseData || {}

  useEffect(() => {
    metadata.count &&
      setROTPageCount({
        rot: {
          all: rotCount.rot.all,
          archived: metadata.count,
        },
      })
  }, [metadata.count])

  const {
    employee_ids = [],
    topics = [],
    last_names = [],
    status = [],
    first_names = [],
    permissions: {
      allow_delete = true,
      allow_edit = true,
      allow_assign_job_profile = true,
      allow_view = true,
    } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  const firstNameFilterData = createFilterData(first_names)
  const lastNameFilterData = createFilterData(last_names)
  const statusFilterData = createFilterData(status)
  const employeeIDsFilterData = createFilterData(employee_ids)
  const topicsFilterData = createFilterData(topics)

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleOk = () => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Restored Successfully' })
          callRefetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/restore`
    )
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const headers = [
    <TableCell className={`left training_topic`}>
      <HeaderItem
        isSort
        handleSort={() => handleSort('training_topic_name')}
        title="Training Topic"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              topicsFilterData.find(
                (e) => e.value === filterData['topic_id']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'topic_id')}
            options={[
              { label: 'All', value: 'All' },
              ...topicsFilterData.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`first_name`}>
      <HeaderItem
        title="First Name"
        isSort
        handleSort={() => handleSort('user_first_name')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              firstNameFilterData.find(
                (e) => e.value === filterData['user_first_name_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_first_name_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...firstNameFilterData.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`last_name`}>
      <HeaderItem
        title="Last Name"
        isSort
        handleSort={() => handleSort('user_last_name')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              lastNameFilterData.find(
                (e) => e.value === filterData['user_last_name_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_last_name_eq')
            }
            options={lastNameFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`employee_id`}>
      <HeaderItem
        title="Employee ID"
        isSort
        handleSort={() => handleSort('user_employee_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              employeeIDsFilterData.find(
                (e) => e.value === filterData['user_employee_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_employee_id_eq')
            }
            options={employeeIDsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    // <TableCell className={`job_profile`} style={{ minWidth: '275px' }}>
    //   <HeaderItem
    //     title="Job Profile"
    //     isSort
    //     handleSort={() => handleSort('action_type_id')}
    //     filter={
    //       <Autocomplete
    //         size="small"
    //         defaultValue="All"
    //         value={
    //           actionTypeFilterData.find(
    //             (e) => e.value === filterData['action_type_id_eq']
    //           ) || 'All'
    //         }
    //         disableClearable
    //         isOptionEqualToValue={(option, value) => option.value === value}
    //         onChange={(_, v) =>
    //           handleOnFilterChange(v || 'All', 'action_type_id_eq')
    //         }
    //         options={actionTypeFilterData}
    //         popupIcon={<img src={SearchIcon} />}
    //         sx={{ width: '100%', background: '#fff', border: 'none' }}
    //         renderInput={(params) => <TextField {...params} label="Search" />}
    //       />
    //     }
    //   />
    // </TableCell>,
    <TableCell className={`employee_notes`} style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Employee Notes"
        filter={
          <InputSearchFilter
            name="user_notes_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell className={`completion_date`} style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Completion Date"
        isSort
        handleSort={() => handleSort('date_of_training')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['date_of_training_eq']}
            fieldName={'date_of_training_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell className={`expiry_date`}>
      <HeaderItem
        title="Expiration Date"
        isSort
        handleSort={() => handleSort('expiry_date')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['expiry_date_eq']}
            fieldName={'expiry_date_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('status')}
        title="Status"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              statusFilterData.find(
                (e) => e.value === filterData['status_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'status_eq')}
            options={statusFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      topic_name,
      user_first_name,
      user_last_name,
      user_employee_id,
      job_profile = '',
      user_notes,
      completion_date,
      expiry_date,
      training_status = '',
      linked_topics = [],
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell className={`left`}>
          <div className="flex" style={{ alignItems: 'center' }}>
            <div className="flex flex-c">
              <div
                style={{
                  color: '#080808',
                  fontWeight: '700',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {topic_name}
              </div>
              <span>
                {linked_topics && linked_topics.length > 0 && (
                  <LinkTraining data={linked_topics} showInMenu users />
                )}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell className={`first_name`}>
          <span className={`first_name`}>{user_first_name}</span>
        </TableCell>
        <TableCell className={`last_name`}>{user_last_name}</TableCell>
        <TableCell className={`employee_id`}>
          <span className={`employee_id`}>{user_employee_id}</span>
        </TableCell>
        {/* <TableCell className={`job_profile`} style={{ minWidth: '275px' }}>
          {job_profile}
        </TableCell> */}
        <TableCell className={`employee_notes `} style={{ minWidth: '275px' }}>
          {user_notes}
        </TableCell>
        <TableCell className={`completion_date`} style={{ minWidth: '275px' }}>
          {completion_date}
        </TableCell>
        <TableCell className={`expiry_date `}>{expiry_date}</TableCell>
        <TableCell>
          <span
            className={`action_status ${
              training_status && training_status.toLowerCase()
            }`}
          >
            {training_status}
          </span>
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              if (allow_restore) {
                menuItems.push(
                  <MenuItem onClick={() => handleRestore(ele, setClose)}>
                    Restore
                  </MenuItem>
                )
              }
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Typography variant="h6">List View</Typography>
        {/* <Stack direction="row" alignItems="center">
          <div style={{ cursor: 'not-allowed' }}>
            {actionIcons.listViewSelected}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => setView('board')}>
            {actionIcons.boardView}
          </div>
        </Stack> */}
      </Box>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Restore Training"
        description="Are You sure you want to restore this training?"
        okText="Restore"
      />
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          renderActiveFilter={
            <>
              <ActiveFilters
                filterData={filterState}
                update={setFilterState}
                keyName="record_of_training"
              />
            </>
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveTrainingList)
