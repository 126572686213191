import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from '@mui/material';
import Select from "react-select";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";

export default function StartInspection(props) {
    const { show, closepopup, details } = props;
    const [ templatesList, setTemplatesList ] = useState(null);
    const [ groupsList, setGroupsList ] = useState(null);
    const [ groupId, setGroupId ] = useState(null);
    const [ templateId, setTemplateId ] = useState(null);

    useEffect(() => {
        // getGroupDetails()
        getTemplateDetails();
    }, []);

    const getGroupDetails = async (template_id) => {
        const groupDetails = await axios.get(`${Application.api_url}/api/v2/form_templates/document_templates?response_data=false`);
        if (groupDetails.status == 200 && groupDetails.data.success === true) {
            var id = result.data.ids == template_id
            var result = groupDetails.data;
            setGroupsList(result.data);
        }
    };

    const getTemplateDetails = async () => {
        const templateDetails = await axios.get(`${Application.api_url}/api/v2/form_templates/document_templates?response_data=false`);
        if (templateDetails.status == 200 && templateDetails.data.success === true) {
            var result = templateDetails.data;
            setTemplatesList(result.data);
        }
    };

    const onhandleStatusClose = () => {
        closepopup();
    };

    const startInspection = async () => {
        let result = await axios.post(`${Application.api_url}/api/v2/forms`, {
            template_id: templateId,
            group_template_id: groupId,
            doc_id: details.slug
        });

        if (result.data && result.status === 200) {
            onhandleStatusClose()
            window.location.href = `/audits/${result.data.audit_data.id}`
        }
    }

    return (
        <Modal
            show={show}
            onHide={(e) => {
                handleClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">{"Start Inspection From Document"} </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"row"}>
                    <div className="col-md-12">
                        <label className={"start-new-form-alert-box"}>
                            Please select a template.
                            <text style={{color: "red", fontSize: "16px"}}>*</text>
                        </label>
                        <Select
                            options={templatesList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(event, value) => {
                                setTemplateId(event.id)
                                setGroupsList(event.groups)
                            }}
                        />
                    </div>
                    <div className="col-md-12">
                        <label className={"start-new-form-alert-box"}>
                            Please select a group, this will create the form under the assigned group.
                            <text style={{color: "red", fontSize: "16px"}}>*</text>
                        </label>
                        <Select
                            options={groupsList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.group_template_id}
                            onChange={(event, value) => {
                                setGroupId(event.group_template_id)
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => {
                        onhandleStatusClose()
                    }}>Close</Button>
                    <Button variant="contained" onClick={(e) => {
                        startInspection()
                    }}>
                        Start
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
