import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Pagination,
  MenuItem,
  FormControl,
  Select,
  Divider,
  Link,
  Breadcrumbs,
} from "@mui/material";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Folder from "../../../assets/fonts/Folder.svg";
import List from "../../../assets/fonts/List.png";
import Card from "../../../assets/fonts/Card.png";
import ListView from "../CompanyDocs/view/list";
import CardView from "../CompanyDocs/view/card";
import CreateFolder from "../CompanyDocs/popups/create";
import loader from "../../../assets/images/newloader.gif";

const DeletedDoc = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [paginationStatus, setPaginationStatus] = useState(false);
  const [name, setSearchByName] = useState("");
  const [searchByType, setSearchByType] = useState("");
  const [searchByCreatedBy, setSearchByCreatedBy] = useState("");
  const [searchByUpdatedAt, setSearchByUpdatedAt] = useState("");
  const [viewStyle, setViewStyle] = useState("list");
  const [openFolder, setOpenFolder] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [sorting, setSorting] = useState(false);
  const [sortedBy, setSortedBy] = useState("file_name");
  const firstUpdate = useRef(false);

  // The breadcrumb state is managed entirely in React.
  // It starts with the default "Home" entry.
  const [breadcrumb, setBreadcrumb] = useState([
    { name: "Home", id: "" },
  ]);
  const [parentId, setParentId] = useState(null);

  // Use the current breadcrumb to determine which directory to load.
  const handleBreadcrumb = () => {
    if (breadcrumb.length > 0) {
      const current = breadcrumb[breadcrumb.length - 1];
      // If an "int_id" property exists, use it; otherwise, default to null.
      setParentId(current.int_id || null);
      getPageDetails(current.id);
    } else {
      const defaultCrumb = [{ name: "Home", id: "" }];
      setBreadcrumb(defaultCrumb);
      setParentId(null);
      getPageDetails("");
    }
  };

  // Re-run whenever pagination, sorting, or the breadcrumb changes.
  useEffect(() => {
    handleBreadcrumb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sorting, sortedBy]);

  // Example of listening for a tab switch (if applicable).
  useEffect(() => {
    const handleTabSwitch = () => {
      handleBreadcrumb();
    };

    const tabTrigger = document.querySelector('a[href="#deleted-docs"]');
    if (tabTrigger) {
      tabTrigger.addEventListener("click", handleTabSwitch);
    }
    return () => {
      if (tabTrigger) {
        tabTrigger.removeEventListener("click", handleTabSwitch);
      }
    };
  }, []);

  // Debounce search filters so that the list is not refreshed on every keystroke.
  useEffect(() => {
    if (firstUpdate.current) {
      const delayDebounceFn = setTimeout(() => {
        setPaginationStatus(false);
        const currentId = breadcrumb[breadcrumb.length - 1].id;
        getPageDetails(currentId, 1);
      }, 2000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      firstUpdate.current = true;
    }
  }, [name, searchByType, searchByUpdatedAt, searchByCreatedBy, breadcrumb]);

  const getPageDetails = async (id, pageNo) => {
    try {
      window.scrollTo(0, 0);
      setPaginationStatus(false);
      let url = `${Application.api_url}/api/v2/company_documents?deleted_at_null=false`;
      if (id) {
        url = `${Application.api_url}/api/v2/company_documents/${id}?deleted_at_null=false`;
      }
      const resultObj = await axios.get(url, {
        params: {
          page: pageNo || page,
          limit: limit,
          search_text: name,
          doc_type_cont: searchByType,
          user_full_name_cont: searchByCreatedBy,
          updated_at_eq: searchByUpdatedAt,
          sort: `${sortedBy} ${sorting ? "asc" : "desc"}`,
        },
      });

      console.log("Deleted Documents Details:", resultObj.data);

      if (resultObj.status === 200) {
        const details = resultObj.data;
        if (id) {
          setPageDetails(details ? details.data.children : []);
        } else {
          setPageDetails(details ? details.data : []);
        }
        setPageCount(details.metadata.pages || 0);
        setPaginationStatus(true);
      }
    } catch (err) {
      console.error("error==>", err);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const recoverDocument = async (id) => {
    try {
      const resultObj = await axios.patch(
        `${Application.api_url}/api/v2/company_documents/${id}/restore`
      );
      if (resultObj.data.success) {
        toast.success("Recovered Successfully");
        const currentId = breadcrumb[breadcrumb.length - 1].id;
        getPageDetails(currentId);
      }
    } catch (err) {
      console.error("error==>", err);
    }
  };

  // When changing directories, append a new breadcrumb.
  const changeRoot = async (data) => {
    setPage(1);
    setPaginationStatus(false);
    const newBreadcrumb = { name: data.name, id: data.slug, int_id: data.int_id || null };
    setBreadcrumb((prev) => [...prev, newBreadcrumb]);
    getPageDetails(data.slug);
  };

  // Clicking on a breadcrumb will slice the breadcrumb array back to that point.
  const handleBreadcrumbClick = (breadcrumbId) => {
    setPaginationStatus(false);
    const index = breadcrumb.findIndex((item) => item.id === breadcrumbId);
    if (index !== -1) {
      const newBreadcrumbs = breadcrumb.slice(0, index + 1);
      setBreadcrumb(newBreadcrumbs);
      const lastCrumb = newBreadcrumbs[newBreadcrumbs.length - 1];
      setParentId(lastCrumb.int_id || null);
      getPageDetails(lastCrumb.id);
    }
  };

  const editRecord = (data) => {
    setOpenFolder(true);
    setSelectedData(data);
  };

  const handleSorting = (sort, field) => {
    setSorting(sort);
    setSortedBy(field);
  };

  const handleSearch = (field, value) => {
    switch (field) {
      case "name":
        setSearchByName(value);
        break;
      case "type":
        setSearchByType(value);
        break;
      case "created_by":
        setSearchByCreatedBy(value);
        break;
      case "updated_at":
        setSearchByUpdatedAt(value);
        break;
      default:
        break;
    }
  };

  const clearFilter = () => {
    setSearchByName("");
    setSearchByType("");
    setSearchByCreatedBy("");
    setSearchByUpdatedAt("");
  };

  const handleClosePopup = (data) => {
    setOpenFolder(false);
    if (data) {
      const currentId = breadcrumb[breadcrumb.length - 1].id;
      getPageDetails(currentId);
    }
  };

  const findAsset = (isAsset) => {
    console.log("Asset found: ", isAsset);
  };

  return (
    <>
      <div className="card asset-tab-landing">
        <div className="card-body">
          <div className="tab-content">
            <div
              id="active-risk-assessment"
              className="tab-pane fade in active active-risk-assessment company-docs"
            >
              <div className="nestedTable pf-table">
                <div className="fields-setting">
                  <div className="asset-active-filters">
                    <h3>Deleted Documents List</h3>
                  </div>
                </div>
                <Divider />
                <div className="fields-setting">
                  <div className="asset-active-filters">
                    <Breadcrumbs separator={">"} aria-label="breadcrumb">
                      {breadcrumb.map((val, index) => (
                        <Link
                          underline="hover"
                          key={index}
                          color="inherit"
                          onClick={() => handleBreadcrumbClick(val.id)}
                          className="breadcrumbs-link"
                        >
                          <img src={Folder} alt="Folder" className="folder-icon" />
                          {val.name}
                        </Link>
                      ))}
                    </Breadcrumbs>
                  </div>
                  <div className="bulk-action">
                    <div className="view-change-switch">
                      <div>
                        <img
                          src={List}
                          alt="List View"
                          className={viewStyle === "list" ? "active" : "cursor-pointer"}
                          onClick={() => setViewStyle("list")}
                        />
                      </div>
                      <div>
                        <img
                          src={Card}
                          alt="Card View"
                          className={viewStyle === "card" ? "active" : "cursor-pointer"}
                          onClick={() => setViewStyle("card")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {paginationStatus ? (
                  <>
                    {viewStyle === "list" ? (
                      <>
                        <div className="fields-setting">
                          <div className="asset-active-filters">
                            Active Filters :
                            <span className="active-filters">
                              <span>Name :</span>
                              <span className="selected">{name || "All"}</span>
                              {name && (
                                <span className="clr-selected" onClick={() => setSearchByName("")}>
                                  X
                                </span>
                              )}
                            </span>
                            <span className="active-filters">
                              <span>Type :</span>
                              <span className="selected">{searchByType || "All"}</span>
                              {searchByType && (
                                <span className="clr-selected" onClick={() => setSearchByType("")}>
                                  X
                                </span>
                              )}
                            </span>
                            <span className="active-filters">
                              <span>Created By :</span>
                              <span className="selected">{searchByCreatedBy || "All"}</span>
                              {searchByCreatedBy && (
                                <span className="clr-selected" onClick={() => setSearchByCreatedBy("")}>
                                  X
                                </span>
                              )}
                            </span>
                            <span className="active-filters">
                              <span>Updated At :</span>
                              <span className="selected">{searchByUpdatedAt || "All"}</span>
                              {searchByUpdatedAt && (
                                <span className="clr-selected" onClick={() => setSearchByUpdatedAt("")}>
                                  X
                                </span>
                              )}
                            </span>
                            <span className="active-filters-clear" onClick={clearFilter}>
                              Clear All
                            </span>
                          </div>
                        </div>
                        <ListView
                          isDeleted={true}
                          pageDetails={pageDetails}
                          recoverDocument={recoverDocument}
                          changeDirectory={changeRoot}
                          edit={editRecord}
                          changeSorting={handleSorting}
                          searching={handleSearch}
                          searchByName={name}
                          searchByType={searchByType}
                          searchByCreatedBy={searchByCreatedBy}
                          searchByUpdatedAt={searchByUpdatedAt}
                          findAsset={findAsset}
                        />
                      </>
                    ) : (
                      <CardView
                        pageDetails={pageDetails}
                        recoverDocument={recoverDocument}
                        changeDirectory={changeRoot}
                        edit={editRecord}
                        findAsset={findAsset}
                      />
                    )}
                  </>
                ) : (
                  <div className="new-loader">
                    <img src={loader} alt="Loading..." />
                  </div>
                )}
                <div className="page-limits">
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={limit}
                      onChange={(e) => {
                        setPage(1);
                        setPaginationStatus(false);
                        setLimit(e.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={35}>35</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  {paginationStatus && (
                    <Pagination
                      count={pageCount}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChangePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openFolder && (
        <CreateFolder show={openFolder} details={selectedData} closepopup={handleClosePopup} />
      )}
    </>
  );
};

export default DeletedDoc;