import React from 'react'
import { connect } from 'react-redux'
import SubTabs from '../../../shared/components/subTabs'
import useFetch from '../../../shared/components/userFetch'
import TrainingTopicsArchiveList from './TrainingTopicsArchiveList'
import TrainingTopicsList from './TrainingTopicsList'

const subTabs = {
  config: [
    {
      label: 'Training Topics',
      value: 't_topics',
    },
    {
      label: 'Archived Topics',
      value: 'a_topics',
    },
  ],
}

const ConfigWrapper = ({ active, title, rotCount = {} }) => {
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch(
    '/api/v2/rot_management/training_topics/base_data?create_topic=true'
  )
  return (
    <SubTabs
      tabList={subTabs[active] || []}
      withTitle
      title={title}
      pageCount={rotCount.config}
    >
      {(activeKey) => (
        <>
          {activeKey === 't_topics' && (
            <TrainingTopicsList baseData={baseData} />
          )}
          {activeKey === 'a_topics' && (
            <TrainingTopicsArchiveList baseData={baseData} />
          )}
        </>
      )}
    </SubTabs>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}

export default connect(mapStateToProps)(ConfigWrapper)
