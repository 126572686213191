import {
  Autocomplete,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AlertDialog from '../../../shared/components/alretDialog'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { rotIcons } from '../../rotIcons'

const TableWrapper = styled('div')`
  .custom_table_container {
    max-height: calc(100vh - 131.7px) !important;
  }
`
const TopicList = ({ setSelectedTopic, selectedTopic, topics }) => {
  return (
    <Box style={{ height: 'calc(100vh - 70px)', overflowY: 'auto' }}>
      <Box style={{ background: '#E0F0F9', padding: 8 }}>
        <Autocomplete
          options={topics}
          disableClearable
          value={selectedTopic}
          size="small"
          getOptionLabel={(option) => option.key}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              style={{ background: '#fff', border: 0 }}
            />
          )}
          onChange={(event, newValue) =>
            setSelectedTopic({ selectedTopic: newValue, page: 1, limit: 20 })
          }
        />
      </Box>
      <List>
        {topics.map((topic, index) => (
          <ListItem
            button
            key={index}
            onClick={() =>
              setSelectedTopic({ selectedTopic: topic, page: 1, limit: 20 })
            }
            style={{
              background:
                selectedTopic.value === topic.value ? '#ECF1F5' : '#fff',
              borderBottom: '1px solid #ECECEC',
            }}
          >
            <ListItemText primary={topic.key} />
            <ListItemIcon style={{ minWidth: 'auto' }}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10.6665L5.66667 5.99984L1 1.33317"
                  stroke="#909090"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const EmployeeList = ({
  selectedTopic,
  setEdit,
  pagination,
  setPagination,
}) => {
  const [deleteItem, setDeleteItem] = useState({ isDelete: false })
  const [alertOpen, setAlertOpen] = useState(false)
  const {
    data: responseData = {},
    isLoading,
    isFetching,
    refetch,
  } = useFetch(
    `/api/v2/rot_management/record_of_trainings?training_topic_name_cont=${selectedTopic.key}&page=${pagination.page}&limit=${pagination.limit}`
  )
  const { mutate, isLoading: isDeleting } = usePatch()

  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose, isDelete: true })
    setAlertOpen(true)
  }

  const handleOk = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully archived.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/archive`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  const handleDeleteConfirm = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully deleted.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/soft_delete`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  if (isLoading || isFetching) {
    return <Loader />
  }
  const { data = [], metadata = {} } = responseData || {}

  if (!data.length) {
    return (
      <Box
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        {rotIcons.no_data}
      </Box>
    )
  }
  const headers = [
    <TableCell>
      <HeaderItem title="First Name" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Last Name" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Employee ID" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Date Completed" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Expiration Date" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Status" />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Action" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      user_first_name = '',
      user_last_name = '',
      user_employee_id = '',
      completion_date = '',
      expiry_date = '',
      training_status = '',
    } = ele || {}
    return (
      <TableRow
        key={index}
        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
      >
        <TableCell>{user_first_name}</TableCell>
        <TableCell>{user_last_name}</TableCell>
        <TableCell>{user_employee_id}</TableCell>
        <TableCell>{completion_date}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          <div
            style={{
              background: training_status === 'Active' ? '#E5F8E9' : '#FFEAEA',
              color: training_status === 'Active' ? '#00BA25' : '#D92929',
              padding: 5,
              borderRadius: 25,
              textAlign: 'center',
              maxWidth: '70px',
            }}
          >
            {training_status}
          </div>
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              menuItems.push(
                <MenuItem>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault() // Prevents default navigation
                      window.open(ele.pdf_link, '_blank', 'noopener,noreferrer')
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.pdf}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        View PDF
                      </span>
                    </Box>
                  </Link>
                </MenuItem>
              )
              menuItems.push(
                <MenuItem onClick={() => handleEdit(ele, setClose)}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.edit}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      Edit
                    </span>
                  </Box>
                </MenuItem>
              )
              menuItems.push(
                <MenuItem onClick={() => handleRestore(ele, setClose)}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.archive}{' '}
                    <span style={{ paddingLeft: 5, color: '#D92929' }}>
                      Archive
                    </span>
                  </Box>
                </MenuItem>
              )
              menuItems.push(
                <MenuItem onClick={() => handleDelete(ele, setClose)}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.delete}{' '}
                    <span style={{ paddingLeft: 5, color: '#D92929' }}>
                      Delete
                    </span>
                  </Box>
                </MenuItem>
              )
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })
  return (
    <TableWrapper>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={deleteItem.isDelete ? handleDeleteConfirm : handleOk}
        title={`${deleteItem.isDelete ? 'Delete' : 'Archive'} Participant(s)`}
        description={`Are You sure you want to ${
          deleteItem.isDelete ? 'Delete' : 'Archive'
        } this Participant(s)?`}
        okText={`${deleteItem.isDelete ? 'Delete' : 'Archive'}`}
      />
      <CustomTable
        headers={headers}
        rows={rows}
        renderActiveFilter={false}
        isSliderScroll={false}
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={pagination.page}
          handlePageChange={(_, nextPage) => {
            setPagination((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={pagination.limit}
          handleRowPerPageChange={(targetValue) => {
            setPagination((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
    </TableWrapper>
  )
}

const ByTopicsList = ({ initialSelectedTopic, topics, setEdit }) => {
  const [listState, setListState] = useState({
    selectedTopic: initialSelectedTopic,
    page: 1,
    limit: 20,
  })
  return (
    <div
      style={{ padding: 8, border: '1px solid #D3D3D3', background: '#fff' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TopicList
            topics={topics}
            selectedTopic={listState.selectedTopic}
            setSelectedTopic={setListState}
          />
        </Grid>
        <Grid item xs={9}>
          <EmployeeList
            pagination={{ page: listState.page, limit: listState.limit }}
            setPagination={setListState}
            selectedTopic={listState.selectedTopic}
            setEdit={setEdit}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const ByTopicsListWrapper = ({ setEdit }) => {
  const { data: responseData = {}, isLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/load_company_topics'
  )
  if (isLoading) {
    return <Loader />
  }
  const { topics = [] } = responseData || {}
  const initialSelectedTopic = topics[0] || {}
  if (!initialSelectedTopic.value) {
    return <>No Data</>
  }
  return (
    <ByTopicsList
      topics={topics}
      initialSelectedTopic={initialSelectedTopic}
      setEdit={setEdit}
    />
  )
}
export default ByTopicsListWrapper
