import { Box } from '@mui/material'
import React from 'react'
import ActionArchieveList from './ActionArchieveList'

const ActionsNoticesArchiveWrapper = ({
  handleOnFilterChange,
  filterData,
  filterState,
  setFilterState,
  handleSort,
  initialState,
  view,
  actionsCount,
  setView,
}) => {
  return (
    <Box style={{ border: '1px solid #D3D3D3', background: '#fff' }}>
      <ActionArchieveList
        handleOnFilterChange={handleOnFilterChange}
        filterState={filterState}
        filterData={filterData}
        setFilterState={setFilterState}
        handleSort={handleSort}
        initialState={initialState}
        view={view}
        setView={setView}
        actionsCount={actionsCount}
      />
    </Box>
  )
}

export default ActionsNoticesArchiveWrapper
