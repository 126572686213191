import { object, ref, string } from "yup"
import { useForm } from "react-hook-form"
import { useYupValidationResolver } from './hooks/useYupValidationResolver'
import React, { useEffect, useState } from "react"
import CheckoutCollapse from "./CheckoutCollapse"
import CheckoutEInput from "./CheckoutEInput"
import CheckoutEButton from "./CheckoutEButton"
import CheckoutArrowRightLineIcon from "./CheckoutArrowRightLineIcon"
import axios from "axios"
import Application from "../../config/ApplicationWrapper"
import _ from "lodash"
import { withRouter } from 'react-router-dom'
import HCaptcha from '@hcaptcha/react-hcaptcha';


const AccountInfoSchema = (subscriptionPlanId) => {
  return object({
    first_name: string().required("First Name is required"),
    last_name: string().required("Last Name is required"),
    username: string().required("Username is required"),
    organization: string().required("Company name is required"),
    email: string().email("Must be a valid email").required("Email is required"),
    password: string()
      .required("Password is required")
      .min(6, "Must be at least 6 characters")
      .max(128, "Must be at most 128 characters"),
    password_confirmation: string()
      .required("Password Confirmation is required")
      .oneOf([ref("password")], "Password Confirmation does not match"),
    
    // Conditionally required fields based on subscriptionPlanId
    billing_first_name: subscriptionPlanId === 8 
      ? string().required("Billing First Name is required")
      : string().notRequired(),
    
    billing_last_name: subscriptionPlanId === 8 
      ? string().required("Billing Last Name is required")
      : string().notRequired(),
    
    card_number: subscriptionPlanId === 8
      ? string()
          .required("Credit Card Number is required")
          .matches(/^[0-9]{16}$/, "Must be a valid 16-digit credit card number")
      : string().notRequired(),
    
    card_expiration: subscriptionPlanId === 8 
      ? string().required("Expiration Date is required")
      : string().notRequired(),
    
    card_cvv: subscriptionPlanId === 8
      ? string()
          .required("CVV is required")
          .matches(/^[0-9]{3,4}$/, "Must be a valid CVV")
      : string().notRequired(),
  });
};




const CheckoutAccountInfo = ({
  openAccountInfo,
  isDone,
  isOpen,
  history
}) => {

  const [subscriptionPlan, setSubscriptionPlan] = useState();

  useEffect(() => {
    const fetchSubscriptionPlan = async () => {
      try {
        const result = await axios.get(`${Application.api_url}/api/v2/subscription_plans`)

        if (result && result.data && result.data.data) {
          const subscriptionPlans = result.data.data
          const subscriptionPlanId = parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"))
          const foundPlan = subscriptionPlans.find((plan) => plan.id === subscriptionPlanId)
          if (foundPlan) {
            setSubscriptionPlan(foundPlan)
          } else {
            console.error('Invalid subscription_plan_id') // TODO: handle subscription_plan_id error in a better way
          }
        } else {
          console.error('Can not fetch subscription plans')
        }
      } catch (err) {
        console.error(err)
      }
    }

    fetchSubscriptionPlan()
  }, [])


  const {
    control,
    handleSubmit,
    // getValues,
    setError
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    resolver: useYupValidationResolver(AccountInfoSchema(parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"))))
  })
  // console.log("=> Account Info", getValues())

  const [captchaToken, setCaptchaToken] = useState(null);

  const onSubmit = async (data) => {
    if (!captchaToken) {
      alert("Please solve the captcha");
      return;
    } else {
      $(".loading").show();
      try {
        
        const subscriptionPlanId = parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"));
        const result = await axios.post(`${Application.api_url}/api/v2/subscriptions`, {
          ...data,
          subscription_plan_id: subscriptionPlanId,
          billing: {
            first_name: data.billing_first_name,
            last_name: data.billing_last_name,
          },
          credit_card: {
            number: data.card_number,
            expiration: data.card_expiration,
            cvv: data.card_cvv
          }
        });
  
        if (result && result.data && result.data.success) {
          history.push('/login?from=checkout');
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.message) {
          const { message: errorMessages } = err.response.data;
          
          Object.entries(errorMessages).forEach(([field, messages]) => {
            setError(field, {
              type: 'server',
              message: `${_.startCase(field)} ${messages.join(', ')}`
            });
          });
        } else {
          console.error('Cannot submit account information with error', err);
        }
      } finally {
        $(".loading").hide();
      }
    }
  };

  const handleExpirationChange = (event) => {
    const { keyCode } = event; // Capture the key code
    let value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
  
    if (keyCode === 8) { // 8 is the key code for backspace
      // Allow backspace to remove characters without re-adding the slash
      event.target.value = value;
      return;
    }
  
    if (value.length >= 2) {
      value = value.slice(0, 2) + '/' + value.slice(2, 4);
    }
  
    event.target.value = value;
  };
  
  return (
    <div>
      {/* <CheckoutCollapse
        onClickCollapse={openAccountInfo}
        isOpen={isOpen}
        showSuffix={isDone}
        title="Account Information"
      > */}
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="checkoutRow">
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  
                  <p>
                    First Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter First Name"
                type="text"
                rules={{ required: true }}
                name="first_name"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Last Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Last Name"
                type="text"
                rules={{ required: true }}
                name="last_name"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  <p>
                     Username &nbsp;
                     <span className="text-danger">*</span>
                  
                  </p>
                }
                placeholder="Enter Username"
                type="text"
                rules={{ required: true }}
                name="username"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Email &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Email"
                type="email"
                rules={{ required: true }}
                name="email"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Password &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Password"
                type="password"
                rules={{ required: true }}
                name="password"
              />
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Password Confirmation &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Password Confirmation"
                type="password"
                rules={{ required: true }}
                name="password_confirmation"
              />
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  
                  <p>
                    Company Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Company Name"
                type="text"
                rules={{ required: true }}
                name="organization"
              />
              
            </div>
          </div>
          
          {
            subscriptionPlan && subscriptionPlan.name === 'Safety Starter' ?
              <div className="checkoutRow">
                <div className="col col-2">
                  <CheckoutEInput
                    control={control}
                    title={
                      <p>
                        Billing First Name &nbsp;
                        <span className="text-danger">*</span>
                      </p>
                    }
                    placeholder="Enter Billing First Name"
                    type="text"
                    name="billing_first_name"
                  />
                </div>
                <div className="col col-2">
                  <CheckoutEInput
                    control={control}
                    title={
                      <p>
                        Billing Last Name &nbsp;
                        <span className="text-danger">*</span>
                      </p>
                    }
                    placeholder="Enter Billing Last Name"
                    type="text"
                    name="billing_last_name"
                  />
                </div>
                <div className="col col-2">
                  <CheckoutEInput
                    control={control}
                    title={
                      <p>
                        Credit Card Number &nbsp;
                        <span className="text-danger">*</span>
                      </p>
                    }
                    placeholder="Enter Credit Card Number"
                    type="text"
                    name="card_number"
                  />
                </div>
                <div className="col col-2">
                  <CheckoutEInput
                    control={control}
                    title={
                      <p>
                        Expiration Date (MM/YY) &nbsp;
                        <span className="text-danger">*</span>
                      </p>
                    }
                    placeholder="MM/YY"
                    type="text"
                    name="card_expiration"
                    onChange={handleExpirationChange}  // Add this line
                    onKeyDown={handleExpirationChange} // Add this line
                  />
                </div>
                <div className="col col-2">
                  <CheckoutEInput
                    control={control}
                    title={
                      <p>
                        CVV &nbsp;
                        <span className="text-danger">*</span>
                      </p>
                    }
                    placeholder="Enter CVV"
                    type="text"
                    name="card_cvv"
                  />
                </div>
              </div>
            :
             <p></p>       
          }

          <div style={{ display: "flex", justifyContent: "" }}>

            <HCaptcha
              sitekey="806d128e-7999-4a5e-ad89-74a73e6ab9f0" // Replace with your hCaptcha site key
              onVerify={(token) => setCaptchaToken(token)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>

            
            
            <CheckoutEButton type="submit" variant="solid" style={{ width: 800 }}>
              Create account &nbsp;
              
            </CheckoutEButton>
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            By creating an account you agree to SafetyLoop's &nbsp;
            <a href="/terms">Terms & Conditions</a> &nbsp;
            and &nbsp;
            <a href="/privacy">Privacy Policy</a>
          </div>
        </form>
      {/* </CheckoutCollapse> */}
      {/*{isSubmit && <p style={{ marginBottom: "16px" }}>{getValues().email}</p>}*/}
    </div>
  )
}

export default withRouter(CheckoutAccountInfo)
