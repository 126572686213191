import {FormControl, Select, MenuItem, Pagination} from "@mui/material";
import React, {useEffect, useReducer, useRef, useState} from "react";
import SearchIcon from "../../../assets/images/searchbar.svg";
import Sorting from "../../../assets/images/Filter.png";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Loader from "../../../components/NewLoader";
import { toast } from "react-toastify";
import Signature from "./popups/Signature";
import {withRouter} from "react-router-dom";

const initialState = {
    date: "",
    status_eq: "",
    total_hours_eq: "",
    cost_code_cont: "",
    work_entries_group_name_cont: "",
    work_entries_cost_code_cost_code_cont: "",
    created_by_full_name_cont: "",
    approved_by_full_name_cont: ""
};

const searchReducer = (state, action) => {
    switch (action.type) {
        case "HANDLE INPUT CHANGE":
            return {...state, [action.field]: action.payload};
        case "HANDLE INPUT REMOVE":
            return {...state, [action.field]: ""};
        case "UPDATE":
            return {...state, ...action.payload};
        case "RESET":
            return initialState;
        default:
            return state;
    }
};

const Active = ({history, refresh, searching, showSettings}) => {
    const mounted = useRef(false);
    const [search, dispatch] = useReducer(searchReducer, initialState);
    const [timeCardId, setTimeCardId] = useState();
    const [status, setStatus] = useState();
    const [signaturePopup, setSignaturePopup] = React.useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [sortedBy, setSortedBy] = useState("date");
    const [sort, setSort] = useState("desc");
    const [showDetails, setShowDetails] = useState();
    const [updateTTStatus, setUpdateTTStatus] = useState(0);


    useEffect(() => {
        getPageDetails();
    }, [page, limit, refresh, updateTTStatus]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (mounted.current) {
                getPageDetails();
                searching(search);
            }
            mounted.current = true;
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [search, sortedBy, sort]);

    const getPageDetails = async () => {
        try {
            setLoading(true);
            await axios
                .get(`${Application.api_url}/api/v2/time_cards`, {
                    params: {
                        page: page,
                        limit: limit,
                        archived_eq: false,
                        sort: sortedBy + " " + sort,
                        ...search,
                    },
                })
                .then((res) => {
                    let details = res.data;
                    setPageDetails(details ? details.data : []);
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                    setLoading(false);
                    setUpdateTTStatus(0);
                })
                .catch((err) => console.log("err=>", err));
        } catch (err) {
            console.log("err===>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleTextChanges = (e) => {
        dispatch({
            type: "HANDLE INPUT CHANGE",
            field: e.target.name,
            payload: e.target.value,
        });
    };

    const handleTextRemove = (name) => {
        dispatch({
            type: "HANDLE INPUT REMOVE",
            field: name,
            payload: "",
        });
    };

    const formatStatusClass = (status) => {
        switch (status) {
            case "on_going":
                return "on-going";
            case "pending":
                return "pending";
            case "declined":
                return "declined";
            case "approved":
                return "approved";
            default:
                return "";
        }
    };

    const close = () => {
        setShowDetails("")
    };

    const goTo = async (path) => {
        history.push(path);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleSort = (name) => {
        if (sortedBy == name) {
            setSort(sort == "asc" ? "desc" : "asc");
        } else {
            setSortedBy(name);
            setSort("asc");
        }
        // Ensure getPageDetails runs with the new sorting
        getPageDetails();
    };

    const updateStatus = async (id, status) => {
        try {
            if(status === "decline") {
                const result = await axios.patch(`${Application.api_url}/api/v2/time_cards/${id}/decline`);
                result.status === 200 && toast.success('Declined Successfully')
                setUpdateTTStatus(1);
            } else {
                setTimeCardId(id);
                setStatus(status);
                setSignaturePopup(true);
            }

        } catch (err) {
            console.log("err===>", err);
        }
     finally {
        setLoading(false); // Hide loader after API call completes
    }
    };

    const handleDeleteTT = async (id) => {
        try {
            await axios
                .delete(`${Application.api_url}/api/v2/time_cards/${id}`)
                .then((res) => {
                    // setLoading(false);
                    if(res.status === 200) {
                        toast.success('Deleted Successfully');
                        setUpdateTTStatus(1);
                    }
                    return true
                })
                .catch((err) => {
                    if (err.response.status === 422) {
                        toast.error(err.response.data.message[0]);
                    }
                    console.log("err=>", err)
                });
        } catch (err) {
            console.log("err===>", err);
        }
    }

    const closeStatus = (data) => {
        setStatus();
        setTimeCardId();
        setSignaturePopup(false);
    };

    return (
        <>
            <div className="inventory-type">
                <div className="fields-setting">
                    <div className="asset-active-filters">
                        Active Filters :
                        <span className="active-filters">
                        <span>Date :</span>
                        <span className="selected">
                            {search.date ? search.date : "All"}
                        </span>
                        <span className={search.date ? "clr-selected" : "clr-selected hidden"}
                              onClick={(e) => { handleTextRemove("date");
                              }}
                        >
                            X
                        </span>
                    </span>
                        <span className="active-filters">
                        <span>Status :</span>
                        <span className="selected">
                            {search.status_eq ? search.status_eq : "All"}
                        </span>
                        <span
                            className={search.status_eq ? "clr-selected" : "clr-selected hidden"}
                            onClick={(e) => {
                                handleTextRemove("status_eq");
                            }}
                        >
                            X
                        </span>
                    </span>
                        {/*<span className="active-filters">*/}
                        {/*    <span>Total Hours :</span>*/}
                        {/*    <span className="selected">*/}
                        {/*        {search.total_hours_eq ? search.total_hours_eq : "All"}*/}
                        {/*    </span>*/}
                        {/*    <span*/}
                        {/*        className={search.total_hours_eq ? "clr-selected" : "clr-selected hidden"}*/}
                        {/*        onClick={(e) => {*/}
                        {/*            handleTextRemove("total_hours_eq");*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        X*/}
                        {/*    </span>*/}
                        {/*</span>*/}
                        {/*<span className="active-filters">*/}
                        {/*    <span>No. of Users :</span>*/}
                        {/*    <span className="selected">*/}
                        {/*        /!*{search.inventory_type_name_cont ? search.inventory_type_name_cont : "All"}*!/*/}
                        {/*    </span>*/}
                        {/*    <span*/}
                        {/*        // className={search.inventory_type_name_cont ? "clr-selected" : "clr-selected hidden"}*/}
                        {/*        onClick={(e) => {*/}
                        {/*            handleTextRemove("inventory_type_name_cont");*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        X*/}
                        {/*    </span>*/}
                        {/*</span>*/}
                        <span className="active-filters">
                        <span>Cost Code :</span>
                        <span className="selected">
                            {search.work_entries_cost_code_cost_code_cont ? search.work_entries_cost_code_cost_code_cont : "All"}
                        </span>
                        <span
                            className={search.work_entries_cost_code_cost_code_cont ? "clr-selected" : "clr-selected hidden"}
                            onClick={(e) => {
                                handleTextRemove("work_entries_cost_code_cost_code_cont");
                            }}
                        >
                            X
                        </span>
                    </span>
                        <span className="active-filters">
                        <span>Assigned Groups :</span>
                        <span className="selected">
                            {search.work_entries_group_name_cont ? search.work_entries_group_name_cont : "All"}
                        </span>
                        <span
                            className={search.work_entries_group_name_cont ? "clr-selected" : "clr-selected hidden"}
                            onClick={(e) => {
                                handleTextRemove("work_entries_group_name_cont");
                            }}
                        >
                            X
                        </span>
                    </span>
                        <span className="active-filters">
                        <span>Created By :</span>
                        <span className="selected">
                            {search.created_by_full_name_cont ? search.created_by_full_name_cont : "All"}
                        </span>
                        <span
                            className={search.created_by_full_name_cont ? "clr-selected" : "clr-selected hidden"}
                            onClick={(e) => {
                                handleTextRemove("created_by_full_name_cont");
                            }}
                        >
                            X
                        </span>
                    </span>
                        <span className="active-filters">
                        <span>Approved By :</span>
                        <span className="selected">
                            {search.approved_by_full_name_cont ? search.approved_by_full_name_cont : "All"}
                        </span>
                        <span
                            className={search.approved_by_full_name_cont ? "clr-selected" : "clr-selected hidden"}
                            onClick={(e) => {
                                handleTextRemove("approved_by_full_name_cont");
                            }}
                        >
                            X
                        </span>
                    </span>
                        <span className="active-filters-clear"
                              onClick={(e) => {
                                  dispatch({
                                      type: "RESET",
                                  });
                              }}
                        >
                        Clear All
                    </span>
                    </div>
                </div>
                <div className="page-content">
                    <table>
                        <thead>
                        <tr>
                            <th className="divider tt-name">
                                <span className="col-name sorting-col">
                                    Date
                                    <img src={Sorting} alt=""
                                         className={`${
                                             sortedBy == "date" && sort == "asc" ? "order-desc" : ""
                                         }`}
                                         onClick={() => {
                                             handleSort("date");
                                         }}
                                    />
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col tt-date">
                                        <input
                                            type="date"
                                            name="date"
                                            id="tt-date"
                                            className="search-btn"
                                            value={search.date}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        />
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Status
                                    <img src={Sorting} alt=""
                                         className={`${
                                             sortedBy == "status" && sort == "asc" ? "order-desc" : ""
                                         }`}
                                         onClick={() => {
                                             handleSort("status");
                                         }}
                                    />
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col">
                                        <select
                                            type="string"
                                            name="status_eq"
                                            id="status_eq"
                                            value={search.status_eq}
                                            className="search-btn"
                                            placeholder="All"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        >
                                            <option value={""}>All</option>
                                            <option value={"pending"}>Pending</option>
                                            <option value={"approved"}>Approved</option>
                                            <option value={"declined"}>Declined</option>
                                            <option value={"on_going"}>On Going</option>
                                        </select>
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Total hours
                                    {/*<img src={Sorting} alt=""*/}
                                    {/*     className={`${*/}
                                    {/*         sortedBy == "name" && sort == "asc" ? "order-desc" : ""*/}
                                    {/*     }`}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         handleSort("name");*/}
                                    {/*     }}*/}
                                    {/*/>*/}
                                </span>
                                {/*{pageDetails.length > 0 && (*/}
                                {/*    <div className="search-col inventory-name">*/}
                                {/*        <input*/}
                                {/*            type="text"*/}
                                {/*            name="name_cont"*/}
                                {/*            id="name_cont"*/}
                                {/*            className="search-btn"*/}
                                {/*            // value={search.name_cont}*/}
                                {/*            placeholder="Search"*/}
                                {/*            onChange={(e) => {*/}
                                {/*                handleTextChanges(e);*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*        <img*/}
                                {/*            src={SearchIcon}*/}
                                {/*            alt="Search Icon"*/}
                                {/*            className="search-icon"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    No. of Users
                                    {/*<img src={Sorting} alt=""*/}
                                    {/*     className={`${*/}
                                    {/*         sortedBy == "name" && sort == "asc" ? "order-desc" : ""*/}
                                    {/*     }`}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         handleSort("name");*/}
                                    {/*     }}*/}
                                    {/*/>*/}
                                </span>
                                {/*{pageDetails.length > 0 && (*/}
                                {/*    <div className="search-col inventory-name">*/}
                                {/*        <input*/}
                                {/*            type="text"*/}
                                {/*            name="name_cont"*/}
                                {/*            id="name_cont"*/}
                                {/*            className="search-btn"*/}
                                {/*            // value={search.name_cont}*/}
                                {/*            placeholder="Search"*/}
                                {/*            onChange={(e) => {*/}
                                {/*                handleTextChanges(e);*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*        <img*/}
                                {/*            src={SearchIcon}*/}
                                {/*            alt="Search Icon"*/}
                                {/*            className="search-icon"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Cost Code
                                    {/*<img src={Sorting} alt=""*/}
                                    {/*     className={`${*/}
                                    {/*         sortedBy == "name" && sort == "asc" ? "order-desc" : ""*/}
                                    {/*     }`}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         handleSort("name");*/}
                                    {/*     }}*/}
                                    {/*/>*/}
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col tt-const_code">
                                        <input
                                            type="text"
                                            name="work_entries_cost_code_cost_code_cont"
                                            id="work_entries_cost_code_cost_code_cont"
                                            className="search-btn"
                                            // value={search.name_cont}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="Search Icon"
                                            className="search-icon"
                                        />
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Assigned Groups
                                    {/*<img src={Sorting} alt=""*/}
                                    {/*     className={`${*/}
                                    {/*         sortedBy == "name" && sort == "asc" ? "order-desc" : ""*/}
                                    {/*     }`}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         handleSort("name");*/}
                                    {/*     }}*/}
                                    {/*/>*/}
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col tt-group_name">
                                        <input
                                            type="text"
                                            name="work_entries_group_name_cont"
                                            id="work_entries_group_name_cont"
                                            className="search-btn"
                                            // value={search.name_cont}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="Search Icon"
                                            className="search-icon"
                                        />
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Created By
                                    <img src={Sorting} alt=""
                                         className={`${
                                             sortedBy == "created_by_full_name" && sort == "asc" ? "order-desc" : ""
                                         }`}
                                         onClick={() => {
                                             handleSort("created_by_full_name");
                                         }}
                                    />
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col tt-created_by">
                                        <input
                                            type="text"
                                            name="created_by_full_name_cont"
                                            id="created_by_full_name_cont"
                                            className="search-btn"
                                            // value={search.name_cont}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="Search Icon"
                                            className="search-icon"
                                        />
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name sorting-col">
                                    Approved By
                                    <img src={Sorting} alt=""
                                         className={`${
                                             sortedBy == "approved_by_full_name" && sort == "asc" ? "order-desc" : ""
                                         }`}
                                         onClick={() => {
                                             handleSort("approved_by_full_name");
                                         }}
                                    />
                                </span>
                                {pageDetails.length > 0 && (
                                    <div className="search-col tt-approved_by">
                                        <input
                                            type="text"
                                            name="approved_by_full_name_cont"
                                            id="approved_by_full_name_cont"
                                            className="search-btn"
                                            // value={search.name_cont}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                handleTextChanges(e);
                                            }}
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="Search Icon"
                                            className="search-icon"
                                        />
                                    </div>
                                )}
                            </th>
                            <th className="divider inventory-name-col">
                                <span className="col-name">
                                    Flags
                                </span>
                            </th>
                            <th className="action-sticky-col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!loading &&
                        pageDetails.length > 0 &&
                        pageDetails.map((val, index) => {
                            const isItemSelected = isSelected(val.id);
                            return (
                                <tr>
                                    <td className="sticky-col-1">
                                        <span>
                                            {val.date}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                             <span className={`status ${formatStatusClass(val.status)}`}>
                                                 {val.status.replace("_", " ").toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
                                             </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {val.total_hours}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {val.no_of_users}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {val.cost_codes.length > 0 &&
                                            val.cost_codes.map((cost_code, index) => {
                                                return (
                                                    <span className="tt-labels">
                                                            {cost_code}
                                                        </span>
                                                )
                                            })
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                             {val.assigned_groups.length > 0 &&
                                             val.assigned_groups.map((group, index) => {
                                                 return (
                                                     <span className="tt-labels">
                                                            {group}
                                                         </span>
                                                 )
                                             })
                                             }
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {val.created_by ? val.created_by : '-'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                        {val.approved_by && val.approved_by.approved_by || '-'}
                                        </span>
                                    </td>
                                    <td>
                                        {val.has_work_entries_outside_geofence ? <span>Entered outside Geofence</span> : <span></span>}
                                    </td>
                                    <td className="sticky-col-3 template-options">
                                        <a
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                            className="btn dropdown-toggle"
                                            data-toggle="dropdown"
                                            id="dropdownMenuLink"
                                            role="button"
                                        >
                                            <i className="fa fa-ellipsis-v"></i>
                                        </a>
                                        <ul
                                            aria-labelledby="dropdownMenuLink"
                                            className="dropdown-menu"
                                        >
                                            <li>
                                                <a className="archive_group_invites" data-toggle="tooltip" title="View"
                                                   onClick={(e) => {goTo(`/time_tracking/${val.id}/view-work-entries`)}}
                                                >
                                                    <div className="row d-flex">
                                                        <div className="col-sm-3">
                                                            <i className="fa fa-eye" style={{paddingTop: "5px", fontSize: "16px", cursor: "pointer"}}></i>
                                                        </div>
                                                        <div className="col-sm-9" style={{paddingTop: "5px", cursor: "pointer"}}>
                                                            View
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="archive_group_invites" data-toggle="tooltip" title="Edit"
                                                   onClick={(e) => {goTo(`/time_tracking/${val.id}/edit-work-entries`)}}
                                                >
                                                    <div className="row d-flex">
                                                        <div className="col-sm-3">
                                                            <i className="fa fa-pencil-square-o" style={{paddingTop: "5px", fontSize: "16px", cursor: "pointer"}}></i>
                                                        </div>
                                                        <div className="col-sm-9" style={{paddingTop: "5px", cursor: "pointer"}}>
                                                            Edit
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="archive_group_invites" data-toggle="tooltip" title="Edit"
                                                   onClick={(e) => { updateStatus(val.id, 'approve')}}
                                                >
                                                    <div className="row d-flex">
                                                        <div className="col-sm-3">
                                                            <i className="fa fa-check" style={{color: 'green', paddingTop: "5px", fontSize: "16px", cursor: "pointer"}}></i>
                                                        </div>
                                                        <div className="col-sm-9" style={{color: 'green', paddingTop: "5px", cursor: "pointer"}}>
                                                            Approve
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="archive_group_invites" data-toggle="tooltip" title="Edit"
                                                   onClick={(e) => {updateStatus(val.id,'decline')}}
                                                >
                                                    <div className="row d-flex">
                                                        <div className="col-sm-3">
                                                            <i className="fa fa-close" style={{color: 'red', paddingTop: "5px", fontSize: "16px", cursor: "pointer"}}></i>
                                                        </div>
                                                        <div className="col-sm-9" style={{color: 'red', paddingTop: "5px", cursor: "pointer"}}>
                                                            Decline
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="archive_group_invites" data-toggle="tooltip" title="Delete"
                                                   onClick={(e) => {handleDeleteTT(val.id)}}
                                                >
                                                    <div className="row d-flex">
                                                        <div className="col-sm-3">
                                                            <i className="fa fa-trash-o" style={{color: 'red', paddingTop: "5px", fontSize: "16px", cursor: "pointer"}}></i>
                                                        </div>
                                                        <div className="col-sm-9" style={{paddingTop: "5px", cursor: "pointer", color: 'red'}}>
                                                            Delete
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            );
                        })}
                        <Loader pageDetails={pageDetails} loading={loading} colSpan={14}/>
                        </tbody>
                    </table>
                </div>
                <div className="page-limits">
                    <FormControl sx={{m: 1, minWidth: 120}} size="small">
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            onChange={(e) => {
                                setPage(1);
                                setLoading(true);
                                setLimit(e.target.value);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl>
                    <Pagination
                        count={pageCount || 1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChangePage}
                    />
                </div>
            </div>
            <Signature
                signature={signaturePopup}
                closepopup={closeStatus}
                status={status}
                timeCardId={timeCardId}
            />
        </>
    );
};

export default withRouter(Active);
