import { Box, Chip, Tooltip } from '@mui/material'
import React, { useState } from 'react'

export const ChipList = ({ chips = [] }) => {
  const limit = 1 // Limit of visible chips
  const [expanded, setExpanded] = useState(false) // State to manage expanded view
  const visibleChips = chips.slice(0, limit) // Chips to display
  const remainingChips = chips.slice(limit) // Chips to hide
  const remainingChipsCount = chips.length - limit // Number of hidden chips

  return (
    <Box display="flex" alignItems="center" gap={1} sx={{ flexWrap: 'wrap' }}>
      {visibleChips.map((chip, index) => (
        <Chip key={index} label={chip} />
      ))}
      {expanded &&
        remainingChips.map((chip, index) => (
          <Chip key={index + limit} label={chip} />
        ))}
      {remainingChipsCount > 0 && (
        <Tooltip
          title={expanded ? '' : chips.slice(limit).join(', ')} // Show tooltip only in initial state
          arrow
          disableHoverListener={expanded}
        >
          {expanded ? (
            <a href="#" onClick={() => setExpanded(false)}>
              Hide
            </a>
          ) : (
            <Chip
              label={expanded ? 'Hide' : `+${remainingChipsCount}`}
              onClick={() => setExpanded(!expanded)} // Toggle expanded state
              clickable // Make the chip clickable
            />
          )}
        </Tooltip>
      )}
    </Box>
  )
}
