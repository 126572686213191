import React from 'react'
import { connect } from 'react-redux'
import useFetch from '../../../shared/components/userFetch'
import ScheduleExportList from './ScheduleExportList'

const ScheduleWrapper = ({ active, title, rotCount = {} }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch('/api/v2/rot_management/training_scheduled_exports/base_data')
  return <ScheduleExportList baseData={baseData} />
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}

export default connect(mapStateToProps)(ScheduleWrapper)
