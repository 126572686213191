import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Loader from '../../../shared/components/loader'
import MultiSelectWithGrouping from '../../../shared/components/multiselectFilter'
import { showToast } from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import useUpdate from '../../../shared/components/useUpdate'

const expirationData = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
]

const ScheduleForm = ({
  initialValues = null,
  mutate,
  onClose = () => false,
  refetch,
  setFilterState,
  isMutating,
  baseData = null,
  baseDataLoading,
}) => {
  const { groups = [], job_profiles = [], users = [] } = baseData || {}
  const isEdit = !!initialValues
  const groupValues =
    isEdit && initialValues.group_ids.length > 0
      ? groups.filter((item) =>
          initialValues.group_ids.includes(item.value.toString())
        )
      : []
  function splitCountryCode(phoneNumber) {
    const match = phoneNumber.match(/^\+1(\d{10})$/)
    if (match) {
      return match[1]
    }
    return phoneNumber
  }
  const defaultValues = isEdit
    ? {
        ...initialValues,
        phone_number: splitCountryCode(initialValues.phone_number),
      }
    : {
        name: '',
        email: '',
        phone_number: '',
        groups: [],
        job_profile_ids: [],
        user_ids: [],
        interval: '',
        expiry_after: '',
      }
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  useEffect(() => {
    if (
      isEdit &&
      initialValues?.group_ids?.length > 0 &&
      groupValues.length > 0
    ) {
      setValue('groups', groupValues)
    }
  }, [isEdit, initialValues, groupValues, setValue])

  const onSubmit = (data) => {
    const payload = {
      training_export: {
        ...data,
        phone_number: `+1${data.phone_number}`,
        group_ids: data.groups.map(({ value }) => value),
        group_names: data.groups.map(({ label }) => label),
      },
    }

    if (isEdit) {
      payload.id = initialValues.id
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({
          message: isEdit
            ? 'Schedule Export Updated Successfully'
            : 'Schedule Export Created Successfully',
        })
        onClose()
        if (refetch && isEdit) {
          refetch()
        }
        if (!isEdit) {
          setFilterState({ page: 1, limit: 20 })
        }
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Schedule Name <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              placeholder="Enter Schedule Name"
              size="small"
              {...register('name', { required: 'Schedule name is required' })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Email
              </Typography>
            </Box>
            <TextField
              placeholder="Enter the Email"
              size="small"
              type="email"
              {...register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Enter a valid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Mobile No <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              type="tel"
              placeholder="Enter 10 Digit Mobile Number"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              {...register('phone_number', {
                required: 'Mobile Number is required',
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: 'Enter a valid 10-digit mobile number',
                },
              })}
              error={!!errors.phone_number}
              helperText={
                errors.phone_number ? errors.phone_number.message : ''
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Group{' '}
              </Typography>
            </Box>
            <Controller
              name="groups"
              control={control}
              defaultValue={[]}
              render={({ field }) =>
                !baseDataLoading && (
                  <MultiSelectWithGrouping
                    handleFilterChange={(v) => field.onChange(v)}
                    groups={groups}
                    styleOverride={{ width: '100%' }}
                    label="Select Group"
                    selectedValue={field.value.length > 0 ? field.value : []}
                  />
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Job Profile{' '}
              </Typography>
            </Box>
            <Controller
              name="job_profile_ids"
              control={control}
              defaultValue={[]} // Ensure it starts as an empty array
              render={({ field }) => (
                <Autocomplete
                  multiple
                  size="small"
                  options={job_profiles}
                  getOptionLabel={(option) => option.key}
                  value={job_profiles.filter((job) =>
                    field.value.includes(job.value)
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((job) => job.value))
                  }}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Job Profile"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.key} />
                    </li>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip
                        key={option.value}
                        label={option.key}
                        {...getTagProps({ index })}
                        onDelete={() =>
                          field.onChange(
                            field.value.filter((val) => val !== option.value)
                          )
                        }
                      />
                    ))
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Users{' '}
              </Typography>
            </Box>
            <Controller
              name="user_ids"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  size="small"
                  options={users}
                  getOptionLabel={(option) => option.key}
                  value={users.filter((user) =>
                    field.value.includes(user.value)
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((user) => user.value))
                  }}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Users"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.key} />
                    </li>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip
                        key={option.value}
                        label={option.key}
                        {...getTagProps({ index })}
                        onDelete={() =>
                          field.onChange(
                            field.value.filter((val) => val !== option.value)
                          )
                        }
                      />
                    ))
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Schedule Internal <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Box>
          <Controller
            name="interval"
            control={control}
            rules={{ required: 'Schedule Internal is required' }}
            render={({ field }) => (
              <FormControl error={!!errors.interval}>
                <RadioGroup row {...field}>
                  {expirationData.map((role) => (
                    <FormControlLabel
                      key={role.value} // Always add a unique key
                      value={role.value}
                      control={<Radio />}
                      label={role.label}
                    />
                  ))}
                </RadioGroup>
                {errors.interval && (
                  <FormHelperText>{errors.interval.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Expire Link After(Days)
              </Typography>
            </Box>
            <TextField
              placeholder="Enter the Days"
              type="number"
              size="small"
              {...register('expiry_after')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
      >
        <div>
          <Button
            style={{
              textTransform: 'none',
              backgroundColor: '#ECECEC',
              color: '#5C5C5C',
              marginRight: '10px',
              outline: 'none',
              height: '48px',
              padding: '8px 16px',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              isMutating ? <CircularProgress size={16} color="inherit" /> : null
            }
            style={{
              textTransform: 'none',
              backgroundColor: '#407797',
              color: '#fff',
              outline: 'none',
              height: '48px',
              padding: '8px 16px',
            }}
            disabled={isMutating}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </div>
      </Box>
    </form>
  )
}

export const ScheduleCreate = ({ refetch, setFilterState, onClose }) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/rot_management/training_scheduled_exports')
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch(
    '/api/v2/rot_management/training_scheduled_exports/base_data?create_training_export=true'
  )
  return (
    <ScheduleForm
      refetch={refetch}
      setFilterState={setFilterState}
      isMutating={isMutating}
      mutate={mutate}
      onClose={onClose}
      baseData={baseData}
    />
  )
}

export const ScheduleEdit = ({ id, setFilterState, refetch, onClose }) => {
  const { data: actionData, isLoading } = useFetch(
    `/api/v2/rot_management/training_scheduled_exports/${id}`
  )
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/rot_management/training_scheduled_exports')
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch(
    '/api/v2/rot_management/training_scheduled_exports/base_data?create_training_export=true'
  )
  const { data = {} } = actionData || {}
  if (isLoading) {
    return <Loader />
  }
  return (
    <ScheduleForm
      initialValues={data}
      refetch={refetch}
      setFilterState={setFilterState}
      isMutating={isMutating}
      onClose={onClose}
      mutate={mutate}
      baseData={baseData}
      baseDataLoading={baseDataLoading}
    />
  )
}
