import React, { useState } from "react";
import Active from "./Active";
import Archive from "./Archive";
import { NavLink } from "react-router-dom";
import TodoForm from "./Popups/TodoForm";

const TodoList = () => {
  const [active, setActive] = useState(0);
  const [archive, setArchive] = useState(0);
  const [updateActive, setUpdateActive] = useState();
  const [updateArchive, setUpdateArchive] = useState();
  const [isShowTodoForm, setShowTodoForm] = useState(false);
  const [formMode, setFormMode] = useState("create");
  const [selectedTodoId, setSelectedTodoId] = useState(null);

  const updateActiveCount = (count) => {
    setActive(count)
  }

  const updateArchiveCount = (count) => {
    setArchive(count)
  }

  const openForm = (mode) => {
    setFormMode(mode);
    setShowTodoForm(true);
  };

  const handleClose = (data) => {
    setShowTodoForm(false);
    data && setUpdateActive(Date.now())
}

  return (
    <>
      <div class="card asset-tab-landing">
        <div class="card-body">
          <div className="d-flex align-item-center justify-content-between header-badge">
            <div className="d-flex align-item-center">
              <h3>TO DO List </h3>
            </div>
            <div className="btn-style">
              <button type="button" class="btn btn-primary" onClick={(e) => {
                openForm("create");
              }}>
                <i class="fa fa-plus" aria-hidden="true"></i>
                New To Do
              </button>
            </div>
          </div>
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#active-risk-assessment">
                Active
                <span class="badge badge-light">
                  {active}
                </span>
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#archived-risk-assessment">
                Archived
                <span class="badge badge-light">
                  {archive}
                </span>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="active-risk-assessment"
              class="tab-pane fade in active active-risk-assessment"
            >
              <Active
                updateActiveCount={updateActiveCount}
                updateActiveRecord={updateActive}
                updateChanges={(e) => { setUpdateArchive(Date.now()) }}
              />
            </div>
            <div
              id="archived-risk-assessment"
              class="tab-pane fade in active-risk-assessment"
            >
              <Archive
                updateActiveCount={updateArchiveCount}
                updateArchiveRecord={updateArchive}
                updateChanges={(e) => { setUpdateActive(Date.now()) }}
              />
            </div>
          </div>
        </div>
        <TodoForm
          show={isShowTodoForm}
          mode={formMode}
          selectedTodoId={selectedTodoId}
          closepopup={handleClose}
        />
      </div>
    </>
  );
}

export default TodoList;
