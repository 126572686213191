import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'

export default function HomepageDemoSection({ mergeTop = true }) {
    const [showDemoModal, setShowDemoModal] = useState(false);
    const handleOpenDemoModal = () => setShowDemoModal(true);
    const handleCloseDemoModal = () => setShowDemoModal(false);

    const demoUrl = "https://meetings.hubspot.com/bernie-borgmann";
    return (
        <div className="Product-Page">
            <div className="personal-demo-section" >
                <div className="home-container" style={{
                    maxWidth: "1050px",
                    margin: `${mergeTop ? "-300px" : "230px"} auto 230px`
                }}>
                    <div className="d-flex personal-demo-content feature-page-demo-section" style={{
                        background: "linear-gradient(90deg, #11b2f9, #63c7f9)",
                        padding: "100px 50px",
                        borderRadius: "20px"
                    }}>
                        <div>
                            <h3 style={{
                                color: "white",
                                textTransform: "none",
                                marginBottom: "40px",
                                fontSize: "46px",
                                lineHeight: "1.2em",
                            }}>
                                <b>Experience SafetyLoop in Action</b>
                            </h3>
                           
                                <Button className="book-demo-btn2" onClick={handleOpenDemoModal}>
                                                        Book Demo
                                                    </Button>
                          
                            <p style={{
                                color: "white"
                            }}>
                                Request a demo today and discover the power of streamlined safety.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for the Book Demo iframe */}
            <Modal show={showDemoModal} onHide={handleCloseDemoModal} size="xl" style={{ width: "100%", height: "81vh" }} >
                <Modal.Header closeButton>
                    <Modal.Title>Book Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe 
                        src={demoUrl}
                        title="Book a Demo"
                        style={{ width: "100%", height: "80vh", border: "none" }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}
