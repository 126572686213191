import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Stack
} from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'
import { useFetchInfiniteForKanban } from '../../../shared/components/userFetch'
import { TagRender } from '../../../shared/components/table'
import { ResendNotification } from './DeclinedList'
import CustomModal from '../../../shared/components/CustomModal'

const statuses = [
    {
        key: 'Waiting',
        value: 'pending',
        icon: (
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5.93395" cy="6.86791" r="5.93419" fill="#D69B25" />
            </svg>

        ),
        color: '#D69B25',
    },
    {
        key: 'Approved',
        value: 'approved',
        icon: (
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5.93395" cy="6.86791" r="5.93419" fill="#009E1F" />
            </svg>

        ),
        color: '#009E1F',
    },
    {
        key: 'Declined',
        value: 'declined',
        icon: (
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5.93395" cy="6.86791" r="5.93419" fill="#D92929" />
            </svg>

        ),
        color: '#D92929',
    }
]

const Task = ({ task }) => {
    const { setNodeRef, attributes, listeners, isDragging, transform } =
        useDraggable({
            id: task.training_id.toString(),
        })
    const [resendId, setResendState] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleOnMouseOver = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleMenuItemClick = (action) => {
        handleClose()
        if (action === 'resend') {
            setResendState(task.training_id)
            return;
        }
        if (action === 'pdf' && task.pdf_link) {
            window.open(task.pdf_link, '_blank', 'noopener,noreferrer')
        }
    }

    return (
        <>
            <div
                ref={setNodeRef}
                {...attributes}
                onClick={(e) => e.stopPropagation()}
                style={{
                    ...styles.task,
                    opacity: isDragging ? 0.5 : 1,
                    cursor: isDragging ? 'move' : 'pointer',
                    transform: CSS.Translate.toString(transform),
                }}
            >
                <Stack spacing={1}>
                    <Stack
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                    >
                        <div>
                            {task.training_topics.join(', ')}
                        </div>
                        <div>
                            <IconButton aria-label="settings" onClick={handleOnMouseOver}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_11066_4348)">
                                        <rect
                                            x="0.00683594"
                                            y="23.7871"
                                            width="23.7368"
                                            height="23.7368"
                                            rx="1.97806"
                                            transform="rotate(-90 0.00683594 23.7871)"
                                            fill="#ECECEC"
                                        />
                                        <path
                                            d="M12.8638 11.9182C12.8638 11.372 12.421 10.9292 11.8748 10.9292C11.3285 10.9292 10.8857 11.372 10.8857 11.9182C10.8857 12.4644 11.3285 12.9072 11.8748 12.9072C12.421 12.9072 12.8638 12.4644 12.8638 11.9182Z"
                                            fill="#5C5C5C"
                                            stroke="#5C5C5C"
                                            stroke-width="1.48355"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M19.7867 11.9182C19.7867 11.372 19.3439 10.9292 18.7976 10.9292C18.2514 10.9292 17.8086 11.372 17.8086 11.9182C17.8086 12.4644 18.2514 12.9072 18.7976 12.9072C19.3439 12.9072 19.7867 12.4644 19.7867 11.9182Z"
                                            fill="#5C5C5C"
                                            stroke="#5C5C5C"
                                            stroke-width="1.48355"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M5.94095 11.9182C5.94095 11.372 5.49815 10.9292 4.95192 10.9292C4.4057 10.9292 3.96289 11.372 3.96289 11.9182C3.96289 12.4644 4.4057 12.9072 4.95192 12.9072C5.49815 12.9072 5.94095 12.4644 5.94095 11.9182Z"
                                            fill="#5C5C5C"
                                            stroke="#5C5C5C"
                                            stroke-width="1.48355"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_11066_4348">
                                            <rect
                                                x="0.00683594"
                                                y="23.7871"
                                                width="23.7368"
                                                height="23.7368"
                                                rx="1.97806"
                                                transform="rotate(-90 0.00683594 23.7871)"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                variant="menu"
                            >
                                <MenuItem onClick={() => handleMenuItemClick('pdf')}>
                                    View PDF
                                </MenuItem>
                                {task.approval_status === 'declined' && <MenuItem onClick={() => handleMenuItemClick('resend')}>
                                    Resend Notification
                                </MenuItem>}
                            </Menu>
                        </div>
                    </Stack>
                    <Stack spacing={1} {...listeners}>
                        <Stack justifyContent="space-between" direction="row">
                            <Stack>
                                <div style={{ color: '#909090', fontSize: 12 }}>
                                    Date of Training
                                </div>
                                <div style={{ color: '#080808', fontSize: 14 }}>
                                    {task.completion_date || 'N/A'}
                                </div>
                            </Stack>
                            <Stack>
                                <div style={{ color: '#909090', fontSize: 12 }}>
                                    Expiration of Training
                                </div>
                                <div style={{ color: '#080808', fontSize: 14 }}>
                                    {task.expiry_date || 'N/A'}
                                </div>
                            </Stack>
                        </Stack>
                        <div style={{ color: '#909090', fontSize: 12 }}>
                            Participants
                        </div>
                        <TagRender data={task.training_participants} customLimit={4} showInMenu />
                    </Stack>
                </Stack>
            </div>
            {!!resendId && <CustomModal
                title="Resend Notification"
                width="30%"
                height="auto"
                handleClose={() => setResendState(null)}
                open={!!resendId}
            >
                <ResendNotification
                    resendId={resendId}
                    onCancel={() => setResendState(null)}
                />
            </CustomModal>}
        </>
    )
}

const Column = ({
    status,
    tasks,
    isFetching,
    page
}) => {
    const { setNodeRef } = useDroppable({ id: status.value })
    const filteredTask = useMemo(() => {
        return tasks.filter((task) => task.approval_status === status.value)
    }, [tasks])

    return (
        <div ref={setNodeRef} style={styles.column}>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Stack gap="10px" direction="row" alignItems="center">
                    {status.icon}
                    <h4 style={{ color: status.color, margin: 0 }}>{status.key}({filteredTask.length})</h4>
                </Stack>
            </Box>
            {isFetching && page === 1 ? null : filteredTask.map((task) => (
                <Task
                    task={task}
                />
            ))}
            {isFetching && (
                <Skeleton
                    variant="text"
                    width="100%"
                    height={200}
                />
            )}
        </div>
    )
}

const KanbanView = ({ setBoardState, boardState }) => {
    const { page, searchText } = boardState
    const {
        data: boardResponse = {},
        isLoading,
        isFetching,
        setData,
    } = useFetchInfiniteForKanban(`/api/v2/rot_management/training_schedules/kanban_board?page=${page}&limit=20${searchText ? `&training_topics_name_cont=${searchText}` : ''
        }`)
    const { mutate, isLoading: isMutating } = usePatch()
    const loadMoreObserver = useRef(null)
    const { metadata: { next = null } = {}, data = [] } = boardResponse || {}

    const handleDragEnd = (event) => {
        const { active, over } = event
        let isChanged = false
        if (over && !isMutating) {
            const updatedTasks = data.map((task) => {
                if (task.approval_status === 'approved') {
                    return task;
                }
                if (task.approval_status === 'declined' && over.id !== 'approved') {
                    return task;
                }
                if (task.training_id.toString() == active.id && over.id !== task.approval_status) {
                    isChanged = true
                    return { ...task, approval_status: over.id }
                }
                return task
            })
            if (isChanged) {
                const oldData = [...data]
                setData((prev) => ({ ...prev, data: updatedTasks }))
                const payload = {
                    training: {
                        approval_status: over.id
                    }
                }
                mutate(
                    payload,
                    {
                        onSuccess: () => {
                            showToast({ message: 'Updated Successfully' })
                        },
                        onError: (e) => {
                            showToast({
                                type: 'error',
                                message: typeof e === 'string' ? e : e.errors.toString() || '',
                            })
                            setData((prev) => ({ ...prev, data: oldData }))
                        },
                    },
                    `/api/v2/rot_management/trainings/${active.id}/update_approval_status`
                )
            }
        }
    }
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !isFetching) {
                setBoardState((prev) => ({ ...prev, page: prev.page + 1 }))
            }
        })

        if (loadMoreObserver.current) {
            observer.observe(loadMoreObserver.current)
        }

        return () => {
            if (loadMoreObserver.current) {
                observer.unobserve(loadMoreObserver.current)
            }
        }
    }, [isFetching, setBoardState])
    if (isLoading) {
        return <Loader />
    }
    return (
        <>
            <DndContext onDragEnd={handleDragEnd}>
                <div style={styles.board}>
                    {statuses.map((status) => (
                        <Column
                            key={status.value}
                            status={status}
                            tasks={data}
                            isFetching={isFetching}
                            page={page}
                        />
                    ))}
                </div>
            </DndContext>
            {!!next && <div className='scroll-pagination-ref' ref={loadMoreObserver} />}
        </>
    )
}

const styles = {
    board: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px',
    },
    column: {
        width: '32%',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    task: {
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    },
}

export default KanbanView
