import { Chip, Grid } from '@mui/material'
import React from 'react'

const allKeys = {
  jp: {
    id_eq: 'Job Profile Name',
    jp_topics_topics_for_training_id_eq: 'Training',
    jp_form_templates_id_eq: 'Shared Forms',
    jp_users_user_id_eq: 'Users',
  },
  cg: {
    id_eq: 'Group Name',
    admin_id_eq: 'Admin',
    type_eq: 'Type',
  },
  agp: {
    id_eq: 'Assigned Global Projects Name',
    company_id_eq: 'Parent Company',
  },
  ulist: {
    first_name_cont: 'First Name',
    last_name_cont: 'Last Name',
    employee_id_cont: 'Employee Id',
    username_cont: 'User Name',
    groups_name_cont: 'Group / Project site',
  },
  action_notices: {
    label_cont: 'Action Name',
    issue_cont: 'Action Name',
    asset_id_eq: 'Asset Name',
    priority_eq: 'Priorty',
    sub_category_type_id_eq: 'Subcategory Type',
    status_eq: 'Status',
    action_type_id_eq: 'Action Type',
    group_id_eq: 'Groups',
    created_by_eq: 'Created By',
    audit_action_users_user_id_eq: 'Assignees',
    archived_eq: 'Due',
  },
  record_of_training: {
    topic_id: 'Training Topic',
    user_first_name_eq: 'First Name',
    user_last_name_eq: 'Last Name',
    user_employee_id_eq: 'Employee ID',
    user_notes_cont: 'Employee notes',
    training_schedule_date_of_training: 'Completion Date',
    training_schedule_expiry_date: 'Expiration Date',
    status: 'Status',
    groups_ids: 'Group/Project Sites',
    job_profile_ids: 'Job Profile',
  },
  trainingMatrix: {
    ids: 'Users',
    group_ids: 'Groups',
    job_profile_ids: 'Job Profiles',
    training_topic_names: 'Training Topics'
  }
}
const ActiveFilters = ({ filterData = {}, update, keyName }) => {
  const keys = Object.keys(filterData)
  const filterKeys = keys.filter(
    (e) =>
      !!filterData[e] &&
      e !== 'page' &&
      e !== 'limit' &&
      e !== 'activeFilters' &&
      e !== 'sort' &&
      e !== 'bucket_eq'
  )
  const isEmpty = !filterKeys.some((key) => !!filterData[key])
  // if (isEmpty) {
  //   return <div style={{ flex: 1 }} />;
  // }
  const handleChipClick = (key) => {
    const newData = { ...filterData }
    delete newData[key]
    delete newData.activeFilters[key]
    if (update) {
      update({ ...newData, page: 1, limit: 20 })
    }
  }
  return (
    <Grid
      container
      rowSpacing={1}
      sx={{
        alignItems: 'center',
      }}
      style={{
        margin: 0,
        alignItems: 'flex-start',
        flex: 1,
        maxWidth: '75%',
        flexWrap: 'nowrap',
      }}
    >
      <Grid
        style={{
          display: 'flex',
          padding: '12px 8px',
          borderRadius: '4px',
          background: '#f6f6f6',
          marginRight: 7,
          whiteSpace: 'nowrap',
        }}
        rowSpacing={1}
        sx={{
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_9034_91013)">
            <path
              d="M3.21062 2.6875H16.7905C16.941 2.74026 17.0773 2.82676 17.1892 2.94036C17.301 3.05397 17.3854 3.19166 17.4358 3.34291C17.4862 3.49415 17.5014 3.65493 17.48 3.81293C17.4587 3.97092 17.4015 4.12194 17.3128 4.25441L12.0898 9.99976V17.312L7.91135 14.1782V9.99976L2.68831 4.25441C2.59962 4.12194 2.54242 3.97092 2.5211 3.81293C2.49978 3.65493 2.51491 3.49415 2.56532 3.34291C2.61574 3.19166 2.7001 3.05397 2.81195 2.94036C2.9238 2.82676 3.06017 2.74026 3.21062 2.6875Z"
              stroke="#5C5C5C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_9034_91013">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>{' '}
        Active Filters: {` `}
      </Grid>
      {!isEmpty && (
        <div
          style={{
            display: 'flex',
            padding: 6,
            alignItems: 'center',
            border: '1px dashed #D3D3D3',
          }}
        >
          <Grid
            style={{
              display: 'flex',
              padding: '0px 8px',
              borderRadius: '24px',
              flexWrap: 'wrap',
            }}
            rowSpacing={1}
            sx={{
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {filterKeys.map((key) => {
              return (
                <>
                {filterData?.activeFilters?.[key] && <Chip
                  style={{
                    textTransform: 'capitalize',
                    backgroundColor: '#f6f6f6',
                  }}
                  key={key}
                  deleteIcon={
                    <svg
                      style={{ margin: '0 10px 0 -6px' }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M9 1L1 9M1 1L9 9L1 1Z"
                        stroke="#FF3030"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  label={
                    <p style={{ margin: 0, display: 'inline' }}>
                      {allKeys?.[keyName]?.[key] ||
                        key.replace(/_cont|_eq/g, '').replace(/_/g, ' ')}
                      :{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {(filterData.activeFilters &&
                          filterData.activeFilters[key]) ||
                          ''}
                      </span>
                    </p>
                  }
                  onDelete={(e) => {
                    e.preventDefault()
                    handleChipClick(key)
                  }}
                />}
                </>
              )
            })}
            {filterKeys.length > 1 && (
              <div
                style={{
                  display: 'inline',
                  color: '#FF3030',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: 12,
                }}
                onClick={() =>
                  update && update({ page: 1, activeFilters: {}, limit: 20 })
                }
              >
                Clear All
              </div>
            )}
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default ActiveFilters
