import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import MultiExport from '../../../shared/components/MultiExport'
import ROTCARD from '../../../shared/components/ROTCARD'
import SubTabs from '../../../shared/components/subTabs'
import { ButtonCreate } from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import useFetch from '../../../shared/components/userFetch'
import ArchiveTrainingList from './ArchiveTrainingList'
import ByEmployeeList from './ByEmployeeList'
import ByJobProfileListWrapper from './ByJobProfile'
import ByTopicsListWrapper from './ByTopicList'
import CreateTraining from './CreateTrainingRecord'
import EditTrainingRecord from './EditTrainingRecord'
import RecordsOfTrainingList from './RecordsOfTrainingList'

const subTabs = {
  rot: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'By Employee',
      value: 'by_employee',
    },
    {
      label: 'By Topic',
      value: 'by_topic',
    },
    {
      label: 'By Job Profile',
      value: 'by_job_profile',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
  ],
}
const ROTWrapper = ({ title, active, rotCount = {} }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch('/api/v2/rot_management/record_of_trainings/base_data')
  const {
    data: cardsData,
    isLoading: cardDataLoading,
    refetch: refetchCardData,
  } = useFetch('/api/v2/rot_management/record_of_trainings/summary_data')

  const { data = {} } = cardsData || {}
  const {
    total_trainings = 0,
    bucket_30 = 0,
    bucket_60 = 0,
    bucket_90 = 0,
    expired = 0,
  } = data

  const {
    permissions: { allow_create = true, allow_to_export_actions = true } = {},
  } = baseData || {}
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState)
  const {
    handleOnFilterChange: handleOnFilterChangeArchive,
    filterState: filterStateArchive,
    filterData: filterDataArchive,
    setFilterState: setFilterStateArchive,
    handleSort: handleSortArchive,
  } = useFilterChange(initialState)
  const refetch = () => {
    setFilterState({ ...filterState })
  }

  const [create, setCreate] = useState(false)
  const [edit, setEdit] = useState('')
  const [selectedCard, setSelectedCard] = React.useState(null)

  const filters = {
    'Total Records': '',
    'Expiring in 30 days': 30,
    'Expiring in 60 days': 60,
    'Expiring in 90 days': 90,
    'Expired Trainings': 'expired',
  }

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      setSelectedCard(null)
      const newData = { ...filterData }
      delete newData['bucket_eq']
      newData.activeFilters && delete newData.activeFilters['bucket_eq']
      // setFilterState({ ...newData, page: 1, limit: 20 })
      console.log(filterState)
      setFilterState((prev) => ({
        bucket_eq: '',
        page: 1,
        limit: 20,
        activeFilters: {},
      }))
    } else {
      setSelectedCard(index)
      const keyValue = filters[index]
      handleOnFilterChange(filters[index] || '', 'bucket_eq')
      setFilterState((prev) => ({
        ...prev,
        bucket_eq: keyValue || 0,
        page: 1,
        limit: 20,
        activeFilters: {
          ...prev.activeFilters,
          bucket_eq: keyValue || 0,
        },
      }))
    }
  }

  return (
    <>
      <Box
        component="section"
        sx={{
          p: 2,
          background: '#fff',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={15}>
          {[
            { label: 'Total Records', value: total_trainings },
            { label: 'Expired Trainings', value: expired },
            { label: 'Expiring in 30 days', value: bucket_30 },
            { label: 'Expiring in 60 days', value: bucket_60 },
            { label: 'Expiring in 90 days', value: bucket_90 },
          ].map((card, index) => (
            <ROTCARD
              key={index}
              label={card.label}
              value={card.value}
              isBold={card.isBold || false}
              handleCardClick={handleCardClick}
              selectedCard={selectedCard}
            />
          ))}
        </Grid>
      </Box>
      <SubTabs
        tabList={subTabs[active] || []}
        withTitle
        title={title}
        pageCount={rotCount.rot}
        extras={[
          <div style={{ display: 'flex', gap: '10px' }}>
            {allow_to_export_actions && <MultiExport filterData={filterData} />}
            {allow_create && (
              <ButtonCreate label="Create" onClick={() => setCreate(true)} />
            )}
          </div>,
        ]}
      >
        {(activeKey) => (
          <>
            {activeKey === 'all' && (
              <>
                <RecordsOfTrainingList
                  handleOnFilterChange={handleOnFilterChange}
                  filterData={filterData}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  handleSort={handleSort}
                  initialState={initialState}
                  setEdit={setEdit}
                  baseData={baseData}
                  rotCount={rotCount}
                  refetchCardData={refetchCardData}
                />
              </>
            )}
            {activeKey === 'archived' && (
              <>
                <ArchiveTrainingList
                  handleOnFilterChange={handleOnFilterChangeArchive}
                  filterData={filterDataArchive}
                  filterState={filterStateArchive}
                  setFilterState={setFilterStateArchive}
                  handleSort={handleSortArchive}
                  initialState={initialState}
                  rotCount={rotCount}
                  baseData={baseData}
                />
              </>
            )}
            {activeKey === 'by_employee' && (
              <ByEmployeeList setEdit={setEdit} />
            )}
            {activeKey === 'by_topic' && (
              <ByTopicsListWrapper setEdit={setEdit} />
            )}
            {activeKey === 'by_job_profile' && (
              <ByJobProfileListWrapper setEdit={setEdit} />
            )}
          </>
        )}
      </SubTabs>

      {create && (
        <CreateTraining
          open={create}
          setOpen={setCreate}
          setFilterState={setFilterState}
          refetch={refetchBaseData}
        />
      )}
      {!!edit && (
        <EditTrainingRecord edit={edit} setEdit={setEdit} refetch={refetch} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}

export default connect(mapStateToProps)(ROTWrapper)
