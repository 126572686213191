import React from "react";
import Arrow from "../../assets/images/Arrow.svg";
import Location from "../../assets/images/Location.svg";
import Post from "../../assets/images/Posts.svg";
import { Button } from "react-bootstrap";

export default function Careers() {
  return (
    <div className="Career-page">
      <div className="banner-section">
        <div className="home-container">
          <div className="banner-content">
            <div className="banner-inside-content">
              <h1>
                <b>Open Positions</b>
              </h1>
              <p>
                We understand that the best candidates aren’t necessarily the
                ones who ‘check all the boxes.’ As you view our openings,
                remember that not knowing something isn’t as important as your
                ability and willingness to learn it.
              </p>

              <a href="https://meetings.hubspot.com/bernie-borgmann" target="blank">
                <Button bsStyle="warning" className="demo-btn" >Request a Demo</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Engineering-section">
        <div className="home-container">
          <h1>
            <b>Engineering</b>
          </h1>

          <p style={{marginLeft: "20px"}}>
            Sorry, we have no open positions at the moment.
          </p>
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>Integration Engineer</b>
              </h4>
              <h3>Principal Systems Engineering & Integration Engineer</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                {" "}
                <img src={Post} alt="" /> S213
              </p>
              <div className="d-flex location">
                <img src={Location} alt="" />
                <p>Remote - Anywhere</p>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              
            </div>
          </div> */}
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>QA Automation Engineer</b>
              </h4>
              <h3>QA Safety</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                <img src={Post} alt="" /> S212
              </p>
              <div className="d-flex remote ">
                <img src={Location} alt="" />
                <div>
                  <p>Remote Work Friendly </p>
                  <p>Anywhere</p>
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              
            </div>
          </div> */}
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>Sr. Full Stack Developer</b>
              </h4>
              <h3>Project Engineer Sr – Risk Management</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                <img src={Post} alt="" /> S209
              </p>
              <div className="d-flex location">
                <img src={Location} alt="" />
                <p>Anywhere - Remote</p>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              
            </div>
             
          </div>
          */}
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>IT</b>
              </h4>
              <h3>IT Support Supervisor</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                <img src={Post} alt="" /> S210
              </p>
              <div className="d-flex location">
                <img src={Location} alt="" />
                <p>Phoenix, AZ</p>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              Learn More <img src={Arrow} alt="" />{" "}
            </div>
          </div> */}
          <h1>
            <b>Sales</b>
          </h1>
          <p style={{marginLeft: "20px"}}>
            Sorry, we have no open positions at the moment.
          </p>

          <h1>
            <b>Account Management</b>
          </h1>
          <p style={{marginLeft: "20px"}}>
            Sorry, we have no open positions at the moment.
          </p>
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>Sales</b>
              </h4>
              <h3>Account Executive, Mid Market</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                <img src={Post} alt="" /> S211
              </p>
              <div className="d-flex remote ">
                <img src={Location} alt="" />
                <div>
                  <p>Remote Work Friendly</p>
                  <p>Toronto, ON</p>
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              
            </div>
          </div> */}
          {/* <div className="d-flex integration-engineer">
            <div className="col-xs-12 col-sm-4 col-md-7 col-xl-7">
              <h4 style={{ color: "#407797" }}>
                <b>Sales</b>
              </h4>
              <h3>Renewals Manager</h3>
              <p>Full Time</p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-3 col-xl-3">
              <p>
                <img src={Post} alt="" /> S211
              </p>
              <div className="d-flex location">
                <img src={Location} alt="" />
                <p>San Diego, CA</p>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-2 col-xl-2"
              style={{ color: "#407797" }}
            >
              Learn More <img src={Arrow} alt="" />{" "}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}