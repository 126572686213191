import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '../../../assets/fonts/Search.svg'
import { UserGroupIcons } from './userGroupIcons'

const InputSearchFilter = ({
  filterData,
  name,
  handleOnFilterChange,
  filterState = {},
  style = {},
}) => {
  const { activeFilters = {} } = filterState
  const inputRef = useRef()
  const [inputValue, setInputValue] = useState(filterData[name] || '')
  useEffect(() => {
    if (!activeFilters[name]) {
      inputRef.current.value = ''
    }
  }, [filterState])

  return (
    <TextField
      size="small"
      inputRef={inputRef}
      className="search-input"
      placeholder="Search"
      style={{ ...style }}
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)} // Update local state
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOnFilterChange(inputValue || '', name)
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!activeFilters[name] ? (
              <IconButton
                onClick={() => {
                  const value = inputRef.current.value || ''
                  handleOnFilterChange(value, name)
                }}
                edge="end"
              >
                <img src={SearchIcon} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  handleOnFilterChange('', name)
                  inputRef.current.value = ''
                  setInputValue('')
                }}
                edge="end"
              >
                {UserGroupIcons.clearInput}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputSearchFilter
