import {
    Box,
    Button,
    CircularProgress,
    Modal,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormWrapper from '../../../shared/components/formWrapper';
import { showToast } from '../../../shared/components/showNotification';
import SubTabs from '../../../shared/components/subTabs';
import useFetch from '../../../shared/components/userFetch';
import { GlobalProjectForm, GroupAssignmentForm, NewGroupForm } from './newGroupForm';

const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: '12px',
    p: 4
};

const steps = [{ label: 'Group Information', value: 0 }, { label: 'Group Assignments', value: 1 }, { label: 'Global Project', value: 2 }];

const NewGroup = ({ open, setOpen, initialValues = null, mutate, setFilterState, refetch, isMutating }) => {
    const isEdit = !!initialValues;
    const { data: baseData, isLoading: baseDataLoading } = useFetch('/api/v2/entity_management/groups/base_data?create_group=true');
    const {
        admin_users = [],
        manager_users = [],
        member_users = [],
        companies = [],
        time_zones = [],
        states = [],
        parent_groups = []
    } = baseData || {};
    const defaultValues = isEdit ? {
        ...initialValues,
        group_members: initialValues.member_users || [],
        group_managers: initialValues.manager_users || [],
        is_global_project: initialValues.type === 'PublicGroup',
        address_enabled: !!(initialValues.address || initialValues.province_or_state || initialValues.city || initialValues.postal_code)
    } : {
        admin_id: '',
        group_managers: [],
        group_members: [],
        group_companies: [],
        time_zone: '',
        province_or_state: '',
        parent_id: '',
        group_invites: [],
        group_notifies: [],
        allow_contractor_docs: false,
        share_company_docs: false,
        allow_to_edit_admin_field: true,
        read_only_global_project_toggle: false,
        read_only_fields_for_manager: false
    };

    console.log(initialValues)
    const [activeStep, setActiveStep] = useState(0);
    const { control, watch, register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
        defaultValues
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleNext = async (isActiveStep) => {
        const isValid = await trigger(); // Validate specific fields
        if (isValid) {
            if (typeof isActiveStep === 'number') {
                setActiveStep(isActiveStep)
            } else {
                setActiveStep((prev) => prev + 1);
            }
        }
        return isValid;
        // if (activeStep === 0) {
        //     // Validate EmployeeForm
        //     const isValid = await trigger(); // Validate specific fields
        //     if (isValid) {
        //       setActiveStep((prev) => prev + 1);
        //     }
        //   } else {
        //     setActiveStep((prev) => prev + 1);
        //   }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prev) => prev - 1);
        }
    };

    const onSubmit = (data) => {
        const {
            group_companies = [],
            group_members = [],
            group_managers = [],
            name,
            description,
            parent_id,
            admin_id,
            time_zone,
            job_id,
            address,
            city,
            province_or_state,
            postal_code,
            logo,
            is_global_project,
            group_invites = [],
            group_notifies = [],
            allow_contractor_docs,
            share_company_docs
        } = data;
        const formData = new FormData();
        formData.append('group[description]', description || '');
        formData.append('group[name]', name || '');
        formData.append('group[job_id]', job_id || '');
        formData.append('group[parent_id]', parent_id || '');
        formData.append('group[admin_id]', admin_id || '');
        formData.append('group[address]', address || '');
        formData.append('group[postal_code]', postal_code || '');
        formData.append('group[province_or_state]', province_or_state || '');
        formData.append('group[city]', city || '');
        formData.append('group[time_zone]', time_zone || '');
        formData.append('group[allow_contractor_docs]', allow_contractor_docs || false);
        formData.append('group[share_company_docs]', share_company_docs || false);
        if (is_global_project) {
            formData.append('group[type]', 'PublicGroup');
        } else {
            formData.append('group[type]', 'PrivateGroup');
        }
        if (logo) {
            formData.append(`group[logo]`, logo);
        }
        const appendGroupData = (key, values, email) => {
            if (values && values.length > 0) {
                values.forEach((item) => {
                    formData.append(`group[${key}][]`, email ? item : item.value);
                });
            } else {
                formData.append(`group[${key}][]`, []);
            }
        };
        appendGroupData('group_invites', group_invites, true);
        appendGroupData('group_notifies', group_notifies, true);
        appendGroupData('group_companies', group_companies);
        appendGroupData('group_members', group_members);
        appendGroupData('group_managers', group_managers);
        const payload = formData;
        if (!isEdit) {
            mutate(payload, {
                onSuccess: () => {
                    showToast({ message: 'Created Successfully' });
                    setOpen(false);
                    if (refetch) {
                        refetch();
                    }
                    setFilterState({ page: 1, limit: 20 })
                },
                onError: (e) => {
                    showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });
                }
            });
        } else {
            mutate(payload, {
                onSuccess: () => {
                    showToast({ message: 'Updated Successfully' });
                    setOpen(false);
                    if (refetch) {
                        refetch();
                    }
                },
                onError: (e) => {
                    showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });
                }
            }, `/api/v2/entity_management/groups/${initialValues.id}`);
        }
        // handleClose(); // Close the modal after submission
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <FormWrapper>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isEdit ? 'Edit Group' : 'Add New Group'}
                        </Typography>
                        {isEdit ? <SubTabs tabList={steps} activeCallback={(active) => handleNext(active)} /> :
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map(({ label }) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>}
                        <form onSubmit={handleSubmit(onSubmit)} style={{ height: 'calc(100% - 110px)' }}>
                            <Box sx={{ mt: 2 }} style={{ overflowY: 'auto', height: 'calc(100% - 40px)' }}>
                                {activeStep === 0 && (() => {
                                    // debugger; // Debugger will trigger when activeStep === 0

                                    const filteredParentGroups = initialValues && initialValues.id 
                                        ? parent_groups.filter(group => group.value !== initialValues.id) 
                                        : parent_groups;

                                    return (
                                        <NewGroupForm 
                                            allow_to_edit_admin_field={defaultValues.allow_to_edit_admin_field}
                                            logoDetails={isEdit ? initialValues.logo_details : {}}
                                            isEdit={isEdit}
                                            options={{ 
                                                adminUsers: admin_users, 
                                                timeZones: time_zones, 
                                                parentGroups: filteredParentGroups,  // Use the filtered list
                                                states 
                                            }}
                                            control={control}
                                            errors={errors}
                                            register={register}
                                        />
                                    );
                                })()}
                                {activeStep === 1 && <GroupAssignmentForm watch={watch} managerUsers={manager_users} memberUsers={member_users} control={control} setValue={setValue} />}
                                {activeStep === 2 && <GlobalProjectForm initialValues={defaultValues} watch={watch} companies={companies} control={control} setValue={setValue} />}
                            </Box>
                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <div>
                                    {!isEdit && <Button style={{ textTransform: 'none', backgroundColor: '#D9E4EA', color: '#407797', outline: 'none' }} disabled={activeStep === 0} onClick={handleBack}>
                                        Previous
                                    </Button>}
                                </div>
                                <div>
                                    <Button style={{ textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none' }} onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                    {(isEdit || (activeStep === steps.length - 1)) && (
                                        <Button endIcon={isMutating ? <CircularProgress style={{ color: '#fff' }} size={16} color="inherit" /> : null} style={{ textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none' }} disabled={isMutating} type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    )}
                                    {!isEdit && (activeStep === 0 || activeStep === 1) && (
                                        <Button style={{ textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none' }} variant="contained" htmlType="button" type="button" color="primary" onClick={() => handleNext()}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </form>
                    </Box>
                </FormWrapper>
            </Modal>
        </>
    );
};

export default NewGroup;
