import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { setROTPageCount } from '../../../../reducers/common'
import CustomModal from '../../../shared/components/CustomModal'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import ActiveFilters from '../../../shared/components/activeFilters'
import CustomDateRangeFilter from '../../../shared/components/customDateRangeFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  CustomPagination,
  HeaderItem,
  TagRender,
} from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'

export const ResendNotification = ({ resendId, onCancel }) => {
  const { mutate, isLoading: isMutating } = usePatch('')
  const defaultValues = {
    approval_note: '',
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const onSubmit = (values) => {
    const payload = {
      training: {
        ...values,
        approval_status: 'declined',
        resend_notify: true,
      },
    }
    mutate(
      payload,
      {
        onSuccess: () => {
          onCancel()
          showToast({ message: 'Notification Sent Successfully' })
        },
      },
      `/api/v2/rot_management/trainings/${resendId}/update_approval_status`
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Message <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              placeholder="Enter Message"
              size="small"
              multiline
              rows={4}
              {...register('approval_note', {
                required: 'Message is required',
              })}
              error={!!errors.approval_note}
              helperText={
                errors.approval_note ? errors.approval_note.message : ''
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          width: '100%',
        }}
      >
        <Button
          style={{
            textTransform: 'none',
            backgroundColor: '#ECECEC',
            color: '#5C5C5C',
            marginRight: '10px',
            outline: 'none',
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          endIcon={
            isMutating ? <CircularProgress size={16} color="inherit" /> : null
          }
          style={{
            textTransform: 'none',
            backgroundColor: '#407797',
            color: '#fff',
            outline: 'none',
          }}
          disabled={isMutating}
          type="submit"
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </Box>
    </form>
  )
}

const DeclinedList = ({ rotCount, setROTPageCount }) => {
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange({ page: 1, limit: 20 })
  const {
    data: responseData = {},
    isLoading,
    refetch,
  } = useFetch('/api/v2/rot_management/training_schedules/declined_trainings', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { mutate, isLoading: isMutating } = usePatch('')
  const [resendId, setResendState] = useState(null)
  const { data = [], metadata = {} } = responseData || {}
  useEffect(() => {
    setROTPageCount({
      wap: {
        ...rotCount.wap,
        declined: metadata.count || rotCount.wap.declined,
      },
    })
  }, [metadata.count])
  const headers = [
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('training_topics_name')}
        title="Training Topic"
        filter={
          <InputSearchFilter
            style={{ width: '100%', background: '#fff' }}
            name="training_topics_name_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('date_of_training')}
        title="Date of Training"
        filter={
          <CustomDateRangeFilter
            value={filterData?.['date_of_training_eq']}
            fieldName={'date_of_training_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Expiration of Training"
        isSort
        handleSort={() => handleSort('expiry_date')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['expiry_date_eq']}
            fieldName={'expiry_date_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Participant(s)" />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      training_topics = [],
      completion_date,
      expiry_date,
      training_participants = [],
      pdf_link,
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell>
          <TagRender
            data={training_topics}
            showInMenu
            customLimit={3}
            users
            enableComma
          />
        </TableCell>
        <TableCell>{completion_date || '-'}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          <TagRender
            data={training_participants}
            showInMenu
            customLimit={3}
            users
          />
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <Stack spacing={2} direction="row" style={{ alignItems: 'center' }}>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#009E1F',
                color: '#fff',
                outline: 'none',
              }}
              disabled={isMutating}
              onClick={() => handleStatusChange(ele.training_id, 'approved')}
            >
              Approve
            </Button>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#D9E4EA',
                color: '#407797',
                outline: 'none',
                whiteSpace: 'nowrap',
              }}
              disabled={isMutating}
              onClick={(e) => {
                e.preventDefault() // Prevents default navigation
                window.open(pdf_link, '_blank', 'noopener,noreferrer')
              }}
            >
              View PDF
            </Button>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#ECECEC',
                color: '#5C5C5C',
                outline: 'none',
                whiteSpace: 'nowrap',
              }}
              disabled={isMutating}
              onClick={() => handleStatusChange(ele.training_id, 'resend')}
            >
              Resend Notification
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  })
  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])
  const handleStatusChange = (id, action) => {
    if (action === 'resend') {
      setResendState(id)
      return
    }
    const url = `/api/v2/rot_management/trainings/${id}/update_approval_status`
    const payload = {
      training: {
        approval_status: action,
      },
    }
    mutate(
      payload,
      {
        onSuccess: () => {
          callRefetch()
          showToast({ message: 'Status Updated Successfully' })
        },
      },
      url
    )
  }
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <>
            <ActiveFilters
              filterData={filterState}
              update={setFilterState}
              keyName="wap"
            />
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
      {!!resendId && <CustomModal
        title="Resend Notification"
        width="30%"
        height="auto"
        handleClose={() => setResendState(null)}
        open={!!resendId}
      >
        <ResendNotification
          resendId={resendId}
          onCancel={() => setResendState(null)}
        />
      </CustomModal>}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeclinedList)
