import React, { useState } from "react";
import OutlinePreview from "../../../javascript/assets/images/outline-preview.png";
import Export from "../../../javascript/assets/images/Export-Industry.png";
import InvestorNorthern from "../../../javascript/assets/images/investor-northern.png";
import InvestorAstro from "../../../javascript/assets/images/investor-astro.png";
import InvestorProtech from "../../../javascript/assets/images/investor-protech.png";
import investorBot from "../../../javascript/assets/images/investor-bot.png";

const About = () => {
  return (
    <div className="About-Us">
      <div className="banner-section">
        <div className="home-container">
          <div className="banner-content">
            <div className="banner-inside-content">
              <h1>
                <b>About Us</b>
              </h1>
              <p>
                Our mission is to get everyone home safely everyday on every site, and to help General Contractors and Trades leverage the most advanced
                technology to increase safety on project sites and stay compliant. Our
                cloud-based platform is being used worldwide to simplify safety and make it completely transparent. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="details-section">
        <div className="home-container">
          <div className="details-section-details">
            
            <p>
              SafetyLoop cloud-based software allows users to
              refine safety, health and daily field processes
              into one electronic workflow that makes project sites
              run easy, organized and transparent.
            </p>
          </div>
        </div>
      </div>
      <div className="explore-section">
        <div className="home-container">
          <div className="explore-details">
            <div className="d-flex">
              <div className="col-xs-12 col-md-4 col-lg-6 explore-image">
                <img src={OutlinePreview} alt="" />
              </div>
              <div className="col-xs-12 col-md-8 col-lg-6">
                <div className="connect-everyone-content">
                  <p className="connect-everyone-quotes">
                    With over 2 dozen features in our product suite, SafetyLoop's system is designed
                    to support unique company requirements and ensure that
                    general contractors have a tool to build a safer, smarter Health & Safety Management System.
                  </p>
                  <p className="connect-everyone-quotes">
                    With over 40,000 users worldwide and over 50 million safety
                    questions answered to date, SafetyLoop has integrated with companies
                    that have a combined Annual Construction Volume of $1
                    Billion, and is now the leading field safety platform in
                    construction in the Ontario province.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="industry-export-section">
        <div className="home-container">
          <div className="export-details">
            <h3>Designed & Developed By Safety Industry Experts</h3>
            <p>
              Developed by a team of safety professionals with over 70 years of
              combined safety management experience, and 20 years in Software
              development. We made certain SafetyLoop is easy to use and
              effective for EVERYONE.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="investors">
        <div className="home-container">
          <h2>
            <b>Backed by World-Class Investors</b>
          </h2>
          <div className="explore-details">
            <div className="d-flex">
              <div className="ivestor-logo col-xs-6 col-md-3 col-lg-6">
                <img src={InvestorNorthern} alt="" />
              </div>
              <div className="ivestor-logo col-xs-6 col-md-3 col-lg-6">
                <img src={InvestorAstro} alt="" />
              </div>
              {/* <div className="ivestor-logo col-xs-6 col-md-3 col-lg-3">
                <img src={InvestorProtech} alt="" />
              </div>
              <div className="ivestor-logo col-xs-6 col-md-3 col-lg-3">
                <img src={investorBot} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default About;
