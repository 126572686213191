import React from "react"
import { Link } from "react-router-dom"
import PricingCircleTick from "./PricingCircleTick"
import '../../assets/styles/themes.scss'
import freeTrial  from '../../assets/images/free-trial.png'

const PricingCard = ({
  id,
  name,
  description,
  price,
  currency,
  interval,
  intervalUnit,
  billingCycle,
}) => {
  const hasPrice = price != null
  let type
  let priceText
  let options
  let buttonText
  let buttonLink = `/checkout?subscription_plan_id=${id}`
  const integerPrice = parseFloat(price)

  if (integerPrice === 0) {
    type = 'free'
    priceText = integerPrice.toString()
    options = [
      // { text: '2 User Account Max', isBold: false },
      // { text: 'Up to 25 Action Items monthly', isBold: false },
      // { text: 'Up to 5 Risk Assessments', isBold: false },
      // { text: 'Up to 3 Form Templates', isBold: false },
      // { text: '100mb of Document Storage', isBold: false },
      // { text: 'Up to 2 Emergency Plans', isBold: false },
      // { text: 'Up to 10 Training Records', isBold: false },
      // { text: 'No Access to Eco-system', isBold: false },
      // { text: 'Up to 10 TO DO Lists', isBold: false },
      // { text: 'Up to 10 QR Codes', isBold: false },
      // { text: 'Up to 25 SMS sends per month', isBold: false },
      // { text: 'Access to Mobile App lite', isBold: false }
    ]
    buttonText = 'Free for Life'
  } else {

    
    type = 'enterprise'
    priceText = `Sign-up`
    options = [
      // { text: 'Plans starting at $250/mo (when paid annually). FLAT RATE PLANS to support all levels. Sign up and we will contact you during the free trial. Along with a custom quote', isBold: true },
      // { text: 'Monthly plans', isBold: false },
      // { text: 'Annual savings plans', isBold: false },
      // { text: 'Access to custom analytics*', isBold: false },
      // { text: 'Access to OHS Professionals', isBold: false },
      // { text: 'Annual Support', isBold: false },
      // { text: 'Group or Project Based Billing', isBold: false },
    ]
    buttonText = `Get Started today`
    // buttonLink = '/contact'
  }

  return (
    <div className='plan__card__enterprise'>
      <div className='' style={{fontSize:'12px', marginTop:"0px", marginLeft:"475px"}}>
        First month free*
      </div>
      <div className={`price ${type} ${hasPrice ? '' : 'contact'}` }>
        

        <p className='title' style={{fontSize:'28px', marginLeft:"65px"}}>
          {name}
        </p>
        {

            <div>
              <div className="price-text" style={{fontSize:'14px', textAlign:'center', marginBottom:'75px', marginTop:'15px', marginLeft:'60px'}}>
                For any size businesses who need advanced integrations and dedicated support.  <u>Flat fee</u> pricing monthly or annual.
              </div>
              <div className="col-md-12 price-text" style={{textAlign:'center', marginLeft:'40px', fontSize:'24px'}}>
                Starting at {price} / Month 
                <br/>
                When billed {billingCycle}
              </div>
            </div>
          

        }




      </div>


      <div className='eSeparator--horizontal'></div>
      <div className='description'>
        {description}
      </div>
      <div className={`eButton ${type}`}>
        <Link to={buttonLink} style={{ color: 'inherit', textDecoration: 'inherit'}}>
          {buttonText}
        </Link>
      </div>
      <div className='options'>
        {options && options.map((option) => {
          return (
            <div key={option.text} className="option">
              {!option.isBold && <PricingCircleTick className={`prefix ${type}`} />}
              <p className={`text ${option.isBold && 'isBold'}`}>{option.text}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PricingCard
