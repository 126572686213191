import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { actionIcons } from '../../ActionIcons'
import ActionKanbanBoard from './ActionKanbanBoard'

const ActionKanbanWrapper = ({ setView, baseData, isArchive, create, setCreate }) => {
  const [boardState, setBoardState] = useState({
    group: baseData['groups'][0],
    searchText: undefined,
    page: 1,
    selectedGroup: baseData['groups'][0].value,
  })
  const inputRef = useRef()
  // useEffect(() => {
  //   if (typeof boardState.searchText === 'string' && !boardState.searchText) {
  //     inputRef.current.value = ''
  //   }
  // }, [boardState])

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography variant="h6">Kanban View</Typography>
          <Paper style={{ background: '#F6F6F6', padding: 8 }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_10896_79146)">
                  <path
                    d="M3.21062 2.6875H16.7905C16.941 2.74026 17.0773 2.82676 17.1892 2.94036C17.301 3.05397 17.3854 3.19166 17.4358 3.34291C17.4862 3.49415 17.5014 3.65493 17.48 3.81293C17.4587 3.97092 17.4015 4.12194 17.3128 4.25441L12.0898 9.99976V17.312L7.91135 14.1782V9.99976L2.68831 4.25441C2.59962 4.12194 2.54242 3.97092 2.5211 3.81293C2.49978 3.65493 2.51491 3.49415 2.56532 3.34291C2.61574 3.19166 2.7001 3.05397 2.81195 2.94036C2.9238 2.82676 3.06017 2.74026 3.21062 2.6875Z"
                    stroke="#5C5C5C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10896_79146">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography variant="body_3" style={{ color: '#5C5C5C' }}>
                Filter by group
              </Typography>
              <Autocomplete
                size="small"
                defaultValue="All"
                value={boardState.group}
                disableClearable
                inputProps={{ 'aria-label': 'Without label' }}
                isOptionEqualToValue={(option, value) => option.value === value}
                onChange={(_, v) =>
                  setBoardState((prev) => ({ ...prev, group: v }))
                }
                options={baseData['groups'] || []}
                // popupIcon={<img src={SearchIcon} />}
                sx={{ minWidth: '300px', background: '#fff', border: 'none' }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </Paper>
          <Button
            variant="outlined"
            size="small"
            style={{
              textTransform: 'none',
              backgroundColor: 'transparent',
              borderColor: '#407797',
              color: '#407797',
            }}
            onClick={() => {
              setBoardState((prev) => ({
                ...prev,
                selectedGroup: boardState.group.value,
                searchText: '',
              }))
            }}
          >
            Filter
          </Button>
        </Stack>
        <Stack spacing={2} direction="row">
          <TextField
            size="small"
            inputRef={inputRef}
            className="search-input"
            placeholder="Search"
            value={boardState.searchText || undefined}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setBoardState((prev) => ({
                  ...prev,
                  searchText: event.target.value || '',
                }))
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!boardState.searchText ? (
                    <IconButton
                      onClick={() => {
                        const value = inputRef.current.value || ''
                        setBoardState((prev) => ({
                          ...prev,
                          searchText: value,
                        }))
                      }}
                      edge="end"
                    >
                      <img src={SearchIcon} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setBoardState((prev) => ({ ...prev, searchText: '' }))
                        inputRef.current.value = ''
                      }}
                      edge="end"
                    >
                      {UserGroupIcons.clearInput}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row">
            <div style={{ cursor: 'pointer' }} onClick={() => setView('list')}>
              {actionIcons.listView}
            </div>
            <div style={{ cursor: 'not-allowed' }}>
              {actionIcons.boardViewSelected}
            </div>
          </Stack>
        </Stack>
      </Box>
      <ActionKanbanBoard
        setBoardState={setBoardState}
        boardState={boardState}
        isArchive={isArchive}
        baseData={baseData}
        create={create}
        setCreate={setCreate}
      />
    </>
  )
}

export default ActionKanbanWrapper
