import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Pagination,
  MenuItem,
  FormControl,
  Select,
  Button,
  Divider,
  Link,
  Breadcrumbs,
} from "@mui/material";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Folder from "../../../assets/fonts/Folder.svg";
import List from "../../../assets/fonts/List.png";
import Card from "../../../assets/fonts/Card.png";
import ListView from "./view/list";
import CardView from "./view/card";
import CreateFolder from "./popups/create";
import UploadFiles from "./popups/upload";
import loader from "../../../assets/images/newloader.gif";
import BulkUploadModal from "./popups/BulkUpload";
import { User } from "../../HomePage/Login";
import { subscribeToUploadStatus } from "../../../channels/upload_status_channel";
import TrashIcon from "../../../components/TrashIcon";
import Swal from "sweetalert2";

const CompanyDoc = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [paginationStatus, setPaginationStatus] = useState(false);
  const [name, setSearchByName] = useState();
  const [filteredDocName, setFilteredDocName] = useState();
  const [searchByType, setSearchByType] = useState();
  const [searchByCreatedBy, setSearchByCreatedBy] = useState();
  const [searchByUpdatedAt, setSearchByUpdatedAt] = useState();
  const [searchByGroupAt, setSearchByGroupAt] = useState();
  const [searchByGlobalGroup, setSearchByGlobalGroupAt] = useState();
  const [viewStyle, setViewStyle] = useState("list");
  const [openFolder, setOpenFolder] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [sorting, setSorting] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [sortedBy, setSortedBy] = useState();
  const [parentId, setParentId] = useState();
  const [companyId, setCompanyId] = useState();
  const firstUpdate = useRef(false);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [docsStorageStats, setDocsStorageStats] = useState({
    asset_docs: { files: "0", size: "0" },
    company_docs: { files: "0", size: "0" },
    internal_docs: { files: "0", size: "0" },
    total_docs: { files: "0", size: "0" },
    total_allowed: { size: "50 GB" },
  });
  const [breadcrumb, setBreadcrumb] = useState([
    {
      name: "Home",
      id: "",
      is_asset_doc: false,
      internal_sub_id: "",
      show: false,
      int_id: "",
    },
  ]);
  const [deletedDocs, setDeletedDocs] = useState([]);

  useEffect(() => {
    getPageData();
  }, [page, limit, sorting, sortedBy, lastUpdate]);

  const getPageData = () => {
    let docs_breadcrumbs = localStorage.getItem("docs_breadcrumbs");
    if (docs_breadcrumbs) {
      docs_breadcrumbs = JSON.parse(docs_breadcrumbs);
      setBreadcrumb(docs_breadcrumbs && docs_breadcrumbs);
      setParentId(docs_breadcrumbs[docs_breadcrumbs.length - 1].int_id);
    }
    let breadcrumbDetails = docs_breadcrumbs
        ? docs_breadcrumbs[docs_breadcrumbs.length - 1].id
        : breadcrumb[breadcrumb.length - 1].id;
    getPageDetails(breadcrumbDetails);
  }

  useEffect(() => {
    if (User.currentBulkUploadId)
      subscribeToUploadStatus(User.id, User.currentBulkUploadId);
    if (firstUpdate.current) {
      const delayDebounceFn = setTimeout(() => {
        setPage(1);
        setPaginationStatus(false);
        getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1);
      }, 2000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      firstUpdate.current = true;
    }
  }, [
    name,
    searchByType,
    searchByUpdatedAt,
    searchByCreatedBy,
    searchByGlobalGroup,
    searchByGroupAt,
  ]);

  useEffect(() => {
    const handleTabSwitch = () => {
      getPageDetails(breadcrumb[breadcrumb.length - 1].id);
    };

    const tabTrigger = document.querySelector('a[href="#company-docs"]');

    if (tabTrigger) {
      tabTrigger.addEventListener('click', handleTabSwitch);
    }

    return () => {
      if (tabTrigger) {
        tabTrigger.removeEventListener('click', handleTabSwitch);
      }
    };
  }, [breadcrumb]);

  const getPageDetails = async (
      id,
      pageNo,
      companyId,
      show,
      fetchDeleted = true
  ) => {
    try {
      window.scrollTo(0, 0);

      let url = `${Application.api_url}/api/v2/company_documents`;
      if (companyId && !show) {
        url = `${Application.api_url}/api/v2/company_documents?company_id=${companyId}`;
      } else if (id && !show) {
        url = `${Application.api_url}/api/v2/company_documents/${id}`;
      } else if (companyId && id && show) {
        url = `${Application.api_url}/api/v2/company_documents/${id}?company_id=${companyId}`;
      }

      let resultObj = await axios.get(url, {
        params: {
          page: pageNo ? pageNo : page,
          limit: limit,
          search_text: name,
          doc_type_cont: searchByType,
          user_full_name_cont: searchByCreatedBy,
          updated_at_eq: searchByUpdatedAt,
          shared_doc_details_group_name_cont: searchByGroupAt,
          is_global_project_eq: searchByGlobalGroup,
          sort: sortedBy && sortedBy + " " + `${sorting ? "asc" : "desc"}`,
          sort_by_starred: `${sorting ? "desc" : "asc"}`,
          deleted_at_null: true,
        },
      });
      console.log("Page Details Response:", resultObj.data);

      if (resultObj.data && resultObj.data.success) {
        let details = resultObj.data;
        if ((id && !companyId) || show) {
          setPageDetails(details ? details.data.children : []);
        } else {
          setPageDetails(details ? details.data : []);
        }
        setPageCount(details && details.metadata ? details.metadata.pages : 0);
        setPaginationStatus(true);
      }
      getStorageStats();
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const getStorageStats = async () => {
    try {
      window.scrollTo(0, 0);
      let url = `${Application.api_url}/api/v2/common/company_docs_stats`;

      let resultObj = await axios.get(url);
      if (resultObj.data && resultObj.data.success) {
        setDocsStorageStats(resultObj.data.data);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const handleUploadSuccess = () => {
    getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1);
    setOpenUploadFile(false);
    setBulkUpload(false);

    // toast.success("Files successfully uploaded.");
    $('.drag_folder_upload--close').show();
    toast.success("Bulk upload requests have been processed and sent to the server. Backend processing is ongoing and might take some time",
        {autoClose: 12000});
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const deleteFolder = async (id) => {
    try {
      let resultObj = await axios.patch(
          `${Application.api_url}/api/v2/company_documents/${id}/soft_delete`
      );
      console.log("Delete response:", resultObj.data); // Log the response

      if (resultObj.data.success) {
        toast.success("Deleted Successfully");
        setPageDetails((prevDetails) =>
            prevDetails.filter((item) => item.int_id !== id)
        );
        setLastUpdate(Date.now());
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const changeRoot = async (data) => {
    setPaginationStatus(false);
    setParentId(data.int_id);
    setPage(1);
    let breadcrumbs = [
      ...breadcrumb,
      ...[
        {
          name: data.name,
          id: data.slug,
          is_asset_doc: data.is_asset_doc || data.is_internal_doc,
          int_id: data.int_id,
        },
      ],
    ];
    if (data.internal_sub_id) {
      setCompanyId(data.internal_sub_id);
      getPageDetails(data.slug, "", data.internal_sub_id);
      breadcrumbs = [
        ...breadcrumb,
        ...[
          {
            name: data.name,
            id: data.slug,
            is_asset_doc: data.is_asset_doc || data.is_internal_doc,
            internal_sub_id: data.internal_sub_id,
            int_id: data.int_id,
          },
        ],
      ];
    } else {
      getPageDetails(
          data.slug,
          "",
          companyId ? companyId : "",
          companyId ? true : ""
      );
      breadcrumbs = [
        ...breadcrumb,
        ...[
          {
            name: data.name,
            id: data.slug,
            is_asset_doc: data.is_asset_doc || data.is_internal_doc,
            internal_sub_id: companyId ? companyId : "",
            show: companyId ? true : "",
            int_id: data.int_id,
          },
        ],
      ];
    }

    setBreadcrumb(breadcrumbs);
    localStorage.setItem("docs_breadcrumbs", JSON.stringify(breadcrumbs));
  };

  const handleClick = (id, is_asset_doc, internal_sub_id, show) => {
    if (id) {
      const index = breadcrumb.findIndex((item) => item.id === id);
      const filteredValues = breadcrumb.slice(0, index + 1);
      setBreadcrumb(filteredValues);
      localStorage.setItem("docs_breadcrumbs", JSON.stringify(filteredValues));
      getPageDetails(id, "", internal_sub_id, show);
      setParentId(filteredValues[filteredValues.length - 1].int_id);
    } else {
      // Reset to the Home breadcrumb
      setCompanyId("");
      setParentId("");
      setBreadcrumb([
        {
          name: "Home",
          id: "",
          is_asset_doc: false,
          internal_sub_id: "",
          show: false,
          int_id: "",
        },
      ]);
      getPageDetails(""); // Fetch the root content when Home is clicked
      localStorage.removeItem("docs_breadcrumbs");
    }
    setIsAsset(is_asset_doc);
  };

  const editRecord = (data) => {
    setOpenFolder(true);
    setSelectedData(data);
  };

  const duplicateRecord = async (id) => {
    try {
      let resultObj = await axios.post(
          `${Application.api_url}/api/v2/company_documents/${id}/duplicate`
      );
      if (resultObj.data.success) {
        toast.success(resultObj.data.message);
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const zipDocs = async (id) => {
    try {
      axios
          .get(`${Application.api_url}/api/v2/company_documents/${id}/download_as_zip`, {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/zip" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Archive.zip");
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.log("err==>", error);
          });
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const hanldeShorting = (sort, field) => {
    setSorting(sort);
    setSortedBy(field);
  };

  const hanldeStared = async (stared, id) => {
    try {
      let resultObj = await axios.patch(
          `${Application.api_url}/api/v2/company_documents/${id}/${stared ? "unstar" : "star"}`
      );
      if (resultObj.data.success) {
        toast.success(
            `${stared ? "Removed from favorites" : "Successfully added to favorites"}`
        );
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const handleSearch = (name, value) => {
    switch (name) {
      case "name":
        setSearchByName(value);
        break;
      case "type":
        setSearchByType(value);
        break;
      case "created_by":
        setSearchByCreatedBy(value);
        break;
      case "updated_at":
        setSearchByUpdatedAt(value);
        break;
      case "group":
        setSearchByGroupAt(value);
        setSearchByGlobalGroupAt("");
        break;
      case "globalGroup":
        setSearchByGlobalGroupAt(true);
        setSearchByGroupAt("");
        break;
      case "removeGroup":
        setSearchByGlobalGroupAt("");
        setSearchByGroupAt("");
        break;
      default:
        break;
    }
  };

  const clearFilter = () => {
    setSearchByName("");
    setSearchByType("");
    setSearchByCreatedBy("");
    setSearchByUpdatedAt("");
    setFilteredDocName("");
    setSearchByGroupAt("");
    setSearchByGlobalGroupAt("");
  };

  const handleClosePopup = (data) => {
    setOpenFolder(false);
    setOpenUploadFile(false);
    setBulkUpload(false);
    data && getPageDetails(breadcrumb[breadcrumb.length - 1].id);
  };

  const handleCloseBulkUploadPopup = (_) => {
    setBulkUpload(false);
    _ && getPageData();
  }

  const findAsset = (data) => {
    setIsAsset(data);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    console.log("Dragging over the trash icon");
  };

  const onDrop = async (event, targetId) => {
    const draggedId = event.dataTransfer.getData("id");
    const draggedItem = pageDetails.find((item) => item.int_id == draggedId);

    const formData = new FormData();
    formData.append("doc_detail[parent_id]", targetId);

    if (targetId && targetId == draggedItem.parent_id) {
      return false;
    }

    if (draggedId && !draggedItem.is_asset_doc && !draggedItem.is_internal_doc) {
      const result = await axios({
        method: "PATCH",
        url: `${Application.api_url}/api/v2/company_documents/${draggedItem.slug}`,
        data: formData,
      });
      if (result && result.status == 200) {
        toast.success("Moved Successfully");
        setLastUpdate(Date.now());
      }
    }
  };

  const handleTrashDrop = async (event) => {
    event.preventDefault();

    // Retrieve the ID of the dragged document
    const draggedId = event.dataTransfer.getData("id");
    if (!draggedId) return;

    console.log("Dragged ID:", draggedId);

    // Trigger SweetAlert2 confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      showCancelButton: true,
      confirmButtonColor: "#345F79",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Proceed with deleting the document via API without checking pageDetails
          const response = await axios.delete(
              `${Application.api_url}/api/v2/company_documents/${draggedId}`
          );

          if (response.data.success) {
            // Show a success message
            toast.success("Document moved to trash.");

            // Remove the deleted document from the pageDetails state
            setPageDetails((prevDetails) =>
                prevDetails.filter((item) => item.int_id !== draggedId)
            );

            // Update the last update state to trigger UI updates if needed
            setLastUpdate(Date.now());

            // Show SweetAlert2 success message
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
          } else {
            toast.error("Failed to move document to trash.");
          }
        } catch (error) {
          toast.error("An error occurred while moving the document to trash.");
          console.error("Error:", error);
        }
      }
    });
  };


  return (
      <>
        <div className="card asset-tab-landing">
          <div className="card-body">
            <div className="tab-content">
              <div
                  id="active-risk-assessment"
                  className="tab-pane fade in active active-risk-assessment company-docs"
              >
                <div className="nestedTable pf-table">
                  <div className="fields-setting">
                    <div className="asset-active-filters">
                      <h3>Company Documents List</h3>
                    </div>
                    {User.currentBulkUploadId && (
                        <div
                            style={{
                              display: "flex",
                              width: "320px",
                              paddingTop: "10px",
                              marginRight: "10px",
                            }}
                            className="recent-upload-container"
                        >
                          <div style={{ width: "100px", fontWeight: "bold" }}>
                            Recent Upload:{" "}
                          </div>
                          <div style={{ width: "200px" }}>
                            <div
                                className="progress-bar bulk-upload-progress-bar"
                                id="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${User.currentBulkUploadPercentage}%`,
                                  height: "20px",
                                }}
                                aria-valuenow="0"
                                aria-valuemin={User.currentBulkUploadPercentage}
                                aria-valuemax="100"
                            ></div>
                          </div>
                          <div id="progress-bar-percentage">
                            {User.currentBulkUploadPercentage}%
                          </div>
                        </div>
                    )}
                    {isAsset != true && (
                        <div className="bulk-action">
                          <div className="d-flex justify-end mr-3">
                            <h6 className="font-weight-bold">
                              Total Storage Used: {docsStorageStats.total_docs.size} /{" "}
                              {docsStorageStats.total_allowed.size}
                            </h6>
                          </div>
                          <Button
                              variant="outlined"
                              onClick={(e) => {
                                setSelectedData();
                                setOpenFolder(true);
                              }}
                          >
                            + Create Folder
                          </Button>
                          <Button
                              variant="contained"
                              onClick={(e) => {
                                setOpenUploadFile(true);
                              }}
                              className="upload-btn"
                          >
                            Upload
                          </Button>
                          <Button
                              variant="contained"
                              onClick={(e) => {
                                setBulkUpload(true);
                              }}
                              className="upload-btn"
                          >
                            Bulk Upload
                          </Button>
                        </div>
                    )}
                  </div>
                  <Divider />
                  <div className="fields-setting">
                    <div className="asset-active-filters">
                      <Breadcrumbs separator={">"} aria-label="breadcrumb">
                        {breadcrumb.map((val, index) => (
                            <Link
                                underline="hover"
                                key={index}
                                color="inherit"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, val.int_id)}
                                onClick={(e) => {
                                  handleClick(
                                      val.id,
                                      val.is_asset_doc,
                                      val.internal_sub_id,
                                      val.show
                                  );
                                }}
                                className="breadcrumbs-link"
                            >
                              <img src={Folder} alt="" className="folder-icon" />
                              {val.name}
                            </Link>
                        ))}
                      </Breadcrumbs>
                    </div>
                    <div className="bulk-action">
                      <div className="view-change-switch">
                        <div>
                          <img
                              src={List}
                              alt=""
                              className={
                                viewStyle == "list" ? "active" : "cursor-pointer"
                              }
                              onClick={(e) => {
                                setViewStyle("list");
                              }}
                          />
                        </div>
                        <div>
                          <img
                              src={Card}
                              alt=""
                              className={
                                viewStyle == "card" ? "active" : "cursor-pointer"
                              }
                              onClick={(e) => {
                                setViewStyle("card");
                              }}
                          />
                        </div>
                      </div>
                      <TrashIcon onDragOver={onDragOver} onDrop={handleTrashDrop}/>
                    </div>
                  </div>
                  {paginationStatus ? (
                      <>
                        {viewStyle == "list" && breadcrumb.length > 0 ? (
                            <>
                              <div className="fields-setting">
                                <div className="asset-active-filters">
                                  Active Filters :
                                  <span className="active-filters">
                              <span>Name :</span>
                              <span className="selected">
                                {name
                                    ? name
                                    : filteredDocName
                                        ? filteredDocName
                                        : "All"}
                              </span>
                              <span
                                  className={
                                    name ? "clr-selected" : "clr-selected hidden"
                                  }
                                  onClick={(e) => {
                                    setSearchByName("");
                                    setFilteredDocName("");
                                  }}
                              >
                                X
                              </span>
                            </span>
                                  <span className="active-filters">
                              <span>Type :</span>
                              <span className="selected">
                                {searchByType ? searchByType : "All"}
                              </span>
                              <span
                                  className={
                                    searchByType
                                        ? "clr-selected"
                                        : "clr-selected hidden"
                                  }
                                  onClick={(e) => {
                                    setSearchByType("");
                                  }}
                              >
                                X
                              </span>
                            </span>
                                  <span className="active-filters">
                              <span>Group/Global project :</span>
                              <span className="selected">
                                {searchByGlobalGroup
                                    ? "Global project"
                                    : searchByGroupAt
                                        ? searchByGroupAt
                                        : "All"}
                              </span>
                              <span
                                  className={
                                    searchByGlobalGroup || searchByGroupAt
                                        ? "clr-selected"
                                        : "clr-selected hidden"
                                  }
                                  onClick={(e) => {
                                    handleSearch("removeGroup");
                                  }}
                              >
                                X
                              </span>
                            </span>
                                  <span className="active-filters">
                              <span>Created By :</span>
                              <span className="selected">
                                {searchByCreatedBy ? searchByCreatedBy : "All"}
                              </span>
                              <span
                                  className={
                                    searchByCreatedBy
                                        ? "clr-selected"
                                        : "clr-selected hidden"
                                  }
                                  onClick={(e) => {
                                    setSearchByCreatedBy("");
                                  }}
                              >
                                X
                              </span>
                            </span>
                                  <span className="active-filters">
                              <span>Updated At :</span>
                              <span className="selected">
                                {searchByUpdatedAt
                                    ? searchByUpdatedAt
                                    : "All"}
                              </span>
                              <span
                                  className={
                                    searchByUpdatedAt
                                        ? "clr-selected"
                                        : "clr-selected hidden"
                                  }
                                  onClick={(e) => {
                                    setSearchByUpdatedAt("");
                                  }}
                              >
                                X
                              </span>
                            </span>
                                  <span
                                      className="active-filters-clear"
                                      onClick={(e) => clearFilter()}
                                  >
                              Clear All
                            </span>
                                </div>
                              </div>
                              <ListView
                                  isStarred={false}
                                  pageDetails={pageDetails}
                                  deleteFolder={deleteFolder}
                                  changeDirectory={changeRoot}
                                  edit={editRecord}
                                  duplicate={duplicateRecord}
                                  zip={zipDocs}
                                  changeSorting={hanldeShorting}
                                  stared={hanldeStared}
                                  searching={handleSearch}
                                  searchByName={name}
                                  searchByType={searchByType}
                                  searchByCreatedBy={searchByCreatedBy}
                                  searchByUpdatedAt={searchByUpdatedAt}
                                  searchByGroup={searchByGroupAt}
                                  searchByGlobalGroup={searchByGlobalGroup}
                                  breadcrumb={breadcrumb}
                                  findAsset={findAsset}
                                  update={setLastUpdate}
                                  filteredDocName={filteredDocName}
                                  filteredDocNameChange={(val) =>
                                      setFilteredDocName(val)
                                  }
                              />
                            </>
                        ) : (
                            <CardView
                                pageDetails={pageDetails}
                                deleteFolder={deleteFolder}
                                changeiDirectory={changeRoot}
                                edit={editRecord}
                                stared={hanldeStared}
                                findAsset={findAsset}
                            />
                        )}
                      </>
                  ) : (
                      <div className="new-loader">
                        <img src={loader} alt="" />
                      </div>
                  )}

                  {/*<TrashIcon onDragOver={onDragOver} onDrop={handleTrashDrop} />*/}

                  <div className="page-limits">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            setPaginationStatus(false);
                            setLimit(e.target.value);
                          }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                    {paginationStatus && (
                        <Pagination
                            count={pageCount}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openFolder && (
            <CreateFolder
                show={openFolder}
                details={selectedData}
                parentId={parentId}
                closepopup={handleClosePopup}
            />
        )}
        <UploadFiles
            show={openUploadFile}
            parentId={parentId}
            closepopup={() => handleClosePopup(true)}
            onUploadSuccess={handleUploadSuccess}
        />

        <BulkUploadModal
            show={bulkUpload}
            parentId={parentId}
            closepopup={(e) => handleCloseBulkUploadPopup(e)}
            onUploadSuccess={handleUploadSuccess}
        />
      </>
  );
};

export default CompanyDoc;
