import React from "react";
import SideNav from "../../components/SideNav";
import RiskAssessment from "./RiskAssessment";
import RiskType from "./RiskTypes";
import Application from "../../config/ApplicationWrapper";
import RiskRangeSettings from "./RiskRangeSettings";
import CloseButton from "../../assets/images/CloseMenu.svg";
import { User } from "../../pages/HomePage/Login";

const RiskAssessmentTool = () => {
  return (
    <React.Fragment>
      {/* <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav /> */}

          <div className="col-sm-12 risk-assessment" style={{ marginTop: '75px' }}>
            <div style={{ background: "white", padding: "10px 10px 0 10px" }}>
              <h1 style={{ margin: "0", paddingBottom: "15px" }}>
                <b>
                <i
                  style={{ paddingInlineStart: "5px", paddingRight: "5px"  }}
                  className="fa fa-area-chart"
                ></i>
                  Risk Assessment
                </b>
              </h1>
              <ul class="nav nav-tabs">
                <li class="active">
                  <a data-toggle="tab" href="#risk-assessment">
                    Risk Assessment
                  </a>
                </li>
                {(User.role == "Admin" || User.role == "SuperAdmin") && (
                  <>
                    <li>
                      <a data-toggle="tab" href="#risk-types">
                        Risk Types
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#risk-range-settings">
                        Risk Range Settings
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div>
              <div class="tab-content" style={{ background: "white" }}>
                <div id="risk-assessment" class="tab-pane fade in active">
                  <RiskAssessment />
                </div>
                <div id="risk-types" class="tab-pane fade">
                  <RiskType />
                </div>
                <div id="risk-range-settings" class="tab-pane fade">
                  <RiskRangeSettings />
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default RiskAssessmentTool;
