import Cookies from 'js-cookie'

// userReducer.js
const initialState = {
  loading: false,
  pageData: {
    isToggleEnable: Cookies.get('collapse') === 'true' ? true : false,
    ...Cookies.get('pageData'),
  },
  pageCount: {
    ulist: {
      user_list: null,
      arch: null,
    },
    jp: {
      job_profile: null,
      arch: null,
    },
    cg: {
      comp_group: null,
      group_arch: null,
    },
    agp: {
      assigned_global_project: null,
      invite_pending_accept: null,
      invite_request: null,
    },
  },
  actionsCount: {
    action_notices: {
      actions_list: null,
      actions_archive: null,
    },
    asset_actions: {
      asset_list: null,
      asset_archive: null,
    },
    config: {
      actions_type: null,
      sub_category_types: null,
    },
  },
  rotCount: {
    rot: {
      all: null,
      archived: null,
      by_employee: 0,
      by_topic: null,
      by_job_profile: null,
    },
    config: {
      a_topics: null,
      t_topics: null,
    },
    wap: {
      waiting: null,
      approved: null,
      declined: null,
    },
  },
  error: '',
}

// Action Types
export const SET_VALUE = 'SET_VALUE'
export const SET_PAGE_COUNT = 'SET_PAGE_COUNT'
export const SET_ACTION_PAGE_COUNT = 'SET_ACTION_PAGE_COUNT'
export const SET_ROT_PAGE_COUNT = 'SET_ROT_PAGE_COUNT'

// Action Creators
export const setValue = (value) => ({
  type: SET_VALUE,
  payload: value,
})

export const setPageCount = (value) => ({
  type: SET_PAGE_COUNT,
  payload: value,
})
export const setROTPageCount = (value) => ({
  type: SET_ROT_PAGE_COUNT,
  payload: value,
})

export const setActionPageCount = (value) => ({
  type: SET_ACTION_PAGE_COUNT,
  payload: value,
})

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        pageData: action.payload, // Update the value in the store
      }
    case SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: { ...state.pageCount, ...action.payload }, // Update the value in the store
      }
    case SET_ACTION_PAGE_COUNT:
      return {
        ...state,
        actionsCount: { ...state.actionsCount, ...action.payload }, // Update the value in the store
      }
    case SET_ROT_PAGE_COUNT:
      return {
        ...state,
        rotCount: { ...state.rotCount, ...action.payload }, // Update the value in the store
      }
    default:
      return state
  }
}

export default userReducer
