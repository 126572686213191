import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import ActiveFilters from '../../../shared/components/activeFilters'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  LinkTraining,
} from '../../../shared/components/table'
import useFetch from '../../../shared/components/userFetch'
// import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setROTPageCount } from '../../../../reducers/common'
import BulkArchive from '../../../Actions/Components/ActionsNotices/BulkArchive'
import AlertDialog from '../../../shared/components/alretDialog'
import CustomDateRangeFilter from '../../../shared/components/customDateRangeFilter'
import CustomModal from '../../../shared/components/CustomModal'
import Loader from '../../../shared/components/loader'
import MultiSelectWithGrouping from '../../../shared/components/multiselectFilter'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { rotIcons } from '../../rotIcons'

const TableWrapper = styled('div')`
  .training_topic,
  .first_name,
  .last_name,
  .employee_id,
  .job_profile,
  .employee_notes,
  .completion_date,
  .expiry_date,
  .status
`

const columnSettingsOptions = [
  { value: 'training_topic', label: 'Training Topic' },
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'employee_id', label: 'Employee ID' },
  { value: 'employee_notes', label: 'Employee Notes' },
  { value: 'completion_date', label: 'Completion Date' },
  { value: 'expiry_date', label: 'Expiration Date' },
  { value: 'status', label: 'Status' },
]

const BulkUpdate = ({
  selectedRows,
  setSelectedRows,
  setChangeStatus,
  setBulkArchive,
}) => {
  return (
    <div
      style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}
    >
      <Grid
        style={{
          display: 'flex',
          padding: '12px 8px',
          borderRadius: '4px',
          background: '#f6f6f6',
          marginRight: 7,
          whiteSpace: 'nowrap',
        }}
        rowSpacing={1}
        sx={{
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {rotIcons.minus}
          <span>Selected :</span>
          <span>{selectedRows.length}</span>
        </div>
        <Button
          onClick={() => setBulkArchive(true)}
          size="small"
          variant="outlined"
          style={{
            color: '#407797',
            backgroundColor: '#FFFFFF',
            borderColor: '#407797',
            textTransform: 'none',
          }}
        >
          {UserGroupIcons.archiveBlue} Archive
        </Button>
      </Grid>

      <Button
        size="small"
        variant="text"
        style={{
          textTransform: 'none',
          color: '#FF3030',
          textDecoration: 'underline',
        }}
        onClick={() => setSelectedRows([])}
      >
        Cancel
      </Button>
    </div>
  )
}

const RecordsOfTrainingList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  baseData,
  rotCount,
  setROTPageCount,
  refetchCardData,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/rot_management/record_of_trainings', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { data: groupFilterResponse, isLoading: groupFilterLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/load_groups_with_children'
  )
  const { data: jobProfileResponse, isLoading: jobFilterLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/load_job_profiles'
  )

  const { groups: groupsFilterOptions } = groupFilterResponse || {}
  const [groupFilterState, setGroupFilterState] = useState([])
  const { job_profiles: jobsFilterOptions = [] } = jobProfileResponse || {}
  const [jobFilterState, setJobFilterState] = useState([])
  const columnSetting = localStorage.getItem('trainingListField') || '{}'
  const parsedColumnSetting = JSON.parse(columnSetting)
  let settingsData = {
    training_topic: true,
    first_name: true,
    last_name: true,
    employee_id: true,
    job_profile: true,
    employee_notes: true,
    completion_date: true,
    expiry_date: true,
    status: true,
  }
  if (Object.keys(parsedColumnSetting).length) {
    settingsData = parsedColumnSetting
  } else {
    localStorage.setItem('trainingListField', JSON.stringify(settingsData))
  }
  const { mutate, isLoading: isDeleting } = usePatch()
  const [selectedRows, setSelectedRows] = useState([])
  const [changeStatus, setChangeStatus] = useState(false)
  const [bulkArchive, setBulkArchive] = useState(false)
  const [columnSettings, setColumnSettings] = useState(settingsData)
  const [deleteItem, setDeleteItem] = useState({ isDelete: false })
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    if (filterData && !filterData.groups_ids) {
      setGroupFilterState([])
    }
    if (filterData && !filterData.job_profile_ids) {
      setJobFilterState([])
    }
  }, [filterData])

  useEffect(() => {
    setROTPageCount({
      rot: {
        ...rotCount.rot,
        all: metadata.count,
      },
    })
  }, [metadata.count])

  const {
    employee_ids = [],
    topics = [],
    last_names = [],
    status = [],
    first_names = [],
    permissions: {
      allow_archive = true,
      allow_delete = true,
      allow_edit = true,
      allow_assign_job_profile = true,
      allow_view = true,
    } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]

  const firstNameFilterData = createFilterData(first_names)
  const lastNameFilterData = createFilterData(last_names)
  const statusFilterData = createFilterData(status)
  const employeeIDsFilterData = createFilterData(employee_ids)
  const topicsFilterData = createFilterData(topics)

  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
    if (setClose) {
      setClose()
    }
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose, isDelete: true })
    setAlertOpen(true)
    if (setClose) {
      setClose()
    }
  }

  const handleOk = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participants successfully archived.' })
          callRefetch()
          refetchCardData()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/archive`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  const handleDeleteConfirm = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully deleted.' })
          callRefetch()
          refetchCardData()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/soft_delete`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }

  const handleSelectedRows = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id])
    } else {
      const newValues = selectedRows.filter((e) => e !== id)
      setSelectedRows(newValues)
    }
  }
  const headers = [
    <TableCell
      className={`left training_topic ${
        columnSettings.training_topic ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        isSort
        handleSort={() => handleSort('training_topic_name')}
        title="Training Topic"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              topicsFilterData.find(
                (e) => e.value === filterData['topic_id']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'topic_id')}
            options={topicsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`first_name ${columnSettings.first_name ? 'tc_show' : 'hide'}`}
    >
      <HeaderItem
        title="First Name"
        isSort
        handleSort={() => handleSort('user_first_name')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              firstNameFilterData.find(
                (e) => e.value === filterData['user_first_name_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_first_name_eq')
            }
            options={firstNameFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`last_name ${columnSettings.last_name ? 'tc_show' : 'hide'}`}
    >
      <HeaderItem
        title="Last Name"
        isSort
        handleSort={() => handleSort('user_last_name')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              lastNameFilterData.find(
                (e) => e.value === filterData['user_last_name_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_last_name_eq')
            }
            options={lastNameFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`employee_id ${
        columnSettings.employee_id ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Employee ID"
        isSort
        handleSort={() => handleSort('user_employee_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              employeeIDsFilterData.find(
                (e) => e.value === filterData['user_employee_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'user_employee_id_eq')
            }
            options={employeeIDsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`employee_notes ${
        columnSettings.employee_notes ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Employee Notes"
        filter={
          <InputSearchFilter
            name="user_notes_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`completion_date ${
        columnSettings.completion_date ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Completion Date"
        isSort
        handleSort={() => handleSort('date_of_training')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['date_of_training_eq']}
            fieldName={'date_of_training_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`expiry_date ${
        columnSettings.expiry_date ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Expiration Date"
        isSort
        handleSort={() => handleSort('expiry_date')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['expiry_date_eq']}
            fieldName={'expiry_date_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell className={`${columnSettings.status ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        isSort
        handleSort={() => handleSort('status')}
        title="Status"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              statusFilterData.find(
                (e) => e.value === filterData['status_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'status_eq')}
            options={statusFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Action" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      topic_name,
      user_first_name,
      user_last_name,
      user_employee_id,
      user_email = '-',
      user_notes = '-',
      completion_date = '-',
      expiry_date = '-',
      training_status = '',
      linked_topics = [],
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell
          className={`left training_topic ${
            columnSettings.training_topic ? 'tc_show' : 'hide'
          }`}
        >
          <div className="flex" style={{ alignItems: 'center' }}>
            {allow_assign_job_profile && (
              <Checkbox
                style={{ color: '#407797' }}
                checked={selectedRows.includes(ele.id)}
                onChange={handleSelectedRows(ele.id)}
              />
            )}
            <div className="flex flex-c">
              <div
                style={{
                  color: '#080808',
                  fontWeight: '700',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {topic_name}
              </div>
              <span>
                {linked_topics && linked_topics.length > 0 && (
                  <LinkTraining data={linked_topics} showInMenu users />
                )}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell
          className={`first_name ${
            columnSettings.first_name ? 'tc_show' : 'hide'
          }`}
        >
          <div className="flex flex-c">
            <div>{user_first_name}</div>
            {user_email && (
              <div
                className="desc elips150"
                style={{
                  color: '#909090',
                  textDecoration: 'none',
                  fontSize: '12px',
                  fontWeight: '400',
                }}
                title={user_email}
              >
                {user_email || ''}
              </div>
            )}
          </div>
          {/* <span className={`first_name`}>{user_first_name}</span> */}
        </TableCell>
        <TableCell
          className={`last_name ${
            columnSettings.last_name ? 'tc_show' : 'hide'
          }`}
        >
          {user_last_name}
        </TableCell>
        <TableCell
          className={`employee_id ${
            columnSettings.employee_id ? 'tc_show' : 'hide'
          }`}
        >
          <span className={`employee_id`}>{user_employee_id}</span>
        </TableCell>
        {/* <TableCell
          className={`job_profile ${
            columnSettings.job_profile ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {job_profile}
        </TableCell> */}
        <TableCell
          className={`employee_notes ${
            columnSettings.employee_notes ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {user_notes || '-'}
        </TableCell>
        <TableCell
          className={`completion_date ${
            columnSettings.completion_date ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {completion_date}
        </TableCell>
        <TableCell
          className={`expiry_date ${
            columnSettings.expiry_date ? 'tc_show' : 'hide'
          }`}
        >
          {expiry_date || '-'}
        </TableCell>
        <TableCell className={`${columnSettings.status ? 'tc_show' : 'hide'}`}>
          <span
            className={`action_status ${
              training_status && training_status.toLowerCase()
            }`}
          >
            {training_status}
          </span>
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              if (allow_view) {
                menuItems.push(
                  <MenuItem>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault() // Prevents default navigation
                        window.open(
                          ele.pdf_link,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.pdf}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          View PDF
                        </span>
                      </Box>
                    </Link>
                  </MenuItem>
                )
              }
              if (allow_edit) {
                menuItems.push(
                  <MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.edit}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Edit
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              if (allow_archive) {
                menuItems.push(
                  <MenuItem onClick={() => handleRestore(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.archive}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Archive
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              if (allow_delete) {
                menuItems.push(
                  <MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.delete}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Delete
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }

  const handleClear = () => {
    setJobFilterState([])
    setGroupFilterState([])
    setFilterState((prev) => ({
      ...prev,
      job_profile_ids: '',
      groups_ids: '',
      page: 1,
      activeFilters: {
        ...prev.activeFilters,
        job_profile_ids: '',
        groups_ids: '',
      },
    }))
  }

  return (
    <>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={deleteItem.isDelete ? handleDeleteConfirm : handleOk}
        icon={UserGroupIcons.archive}
        title={`${deleteItem.isDelete ? 'Delete' : 'Archive'} Training Record`}
        description={`Are you sure you want to ${
          deleteItem.isDelete ? 'Delete' : 'Archive'
        } this Training Record?`}
        extraText={
          deleteItem.isDelete
            ? ''
            : 'If ROT is archived, it will appear in the archive list, and you can unarchive it.'
        }
        okText={`${deleteItem.isDelete ? 'Delete' : 'Yes, Archive'}`}
      />
      <TableWrapper>
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '0%',
            padding: 8,
            alignItems: 'center',
            borderBottom: '1px solid #D3D3D3',
            background: '#FFFFFF',
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Paper
              style={{ background: '#F6F6F6', padding: 8, boxShadow: 'none' }}
            >
              <Stack spacing={1} direction="row" alignItems="center">
                {rotIcons.filter}
                <Typography variant="body_3" style={{ color: '#5C5C5C' }}>
                  Group/Project Sites
                </Typography>
                {!groupFilterLoading && (
                  <MultiSelectWithGrouping
                    label="Search Groups"
                    styleOverride={{ width: '350px' }}
                    handleFilterChange={(value) => {
                      setGroupFilterState(value)
                      if (value.length === 0) {
                        setFilterState((prev) => ({
                          ...prev,
                          groups_ids: '',
                          activeFilters: {
                            ...prev.activeFilters,
                            groups_ids: '',
                          },
                        }))
                      }
                    }}
                    groups={groupsFilterOptions}
                    selectedValue={groupFilterState}
                  />
                )}
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <Paper
              style={{ background: '#F6F6F6', padding: 8, boxShadow: 'none' }}
            >
              <Stack spacing={1} direction="row" alignItems="center">
                {/* {rotIcons.filter} */}
                <Typography variant="body_3" style={{ color: '#5C5C5C' }}>
                  Job Profile
                </Typography>
                {!jobFilterLoading && (
                  <Autocomplete
                    multiple
                    limitTags={1}
                    sx={{
                      width: '275px',
                    }}
                    size="small"
                    options={jobsFilterOptions}
                    getOptionLabel={(option) => option.key}
                    value={jobFilterState || []} // Ensure it's an array
                    onChange={(event, newValue) => {
                      setJobFilterState(newValue || [])
                      if (newValue.length === 0) {
                        setFilterState((prev) => ({
                          ...prev,
                          job_profile_ids: '',
                          activeFilters: {
                            ...prev.activeFilters,
                            job_profile_ids: '',
                          },
                        }))
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={option.key} />
                      </li>
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip
                          sx={{ fontSize: '12px' }}
                          key={option.value}
                          label={option.key}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const updatedValues = jobFilterState.filter(
                              (val) => val.value !== option.value
                            )
                            setJobFilterState(updatedValues)
                            const jobLabels = updatedValues.map(
                              (gfs) => gfs.key
                            )
                            const jobValues = updatedValues.map(
                              (gfs) => gfs.value
                            )

                            const updatedFilters = {
                              ...filterState.activeFilters,
                            }
                            if (jobValues.length > 0) {
                              updatedFilters.job_profile_ids =
                                jobLabels.join(',')
                            } else {
                              delete updatedFilters.job_profile_ids // Remove if empty
                            }

                            setFilterState((prev) => ({
                              ...prev,
                              ...(jobValues.length > 0
                                ? { job_profile_ids: jobValues }
                                : { job_profile_ids: '' }),
                              activeFilters: updatedFilters,
                            }))
                          }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          jobFilterState.length === 0
                            ? 'Search Job Profile'
                            : ''
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                )}
              </Stack>
            </Paper>
            <Button
              variant="outlined"
              size="small"
              style={{
                textTransform: 'none',
                backgroundColor: 'transparent',
                borderColor: '#407797',
                color: '#407797',
              }}
              onClick={() => {
                const labels = groupFilterState.map((gfs) => gfs.label)
                const values = groupFilterState.map((gfs) => gfs.value)

                const jobLabels = jobFilterState.map((gfs) => gfs.key)
                const jobValues = jobFilterState.map((gfs) => gfs.value)

                const updatedFilters = { ...filterState.activeFilters }

                if (values.length > 0) {
                  updatedFilters.groups_ids = labels.join(',')
                } else {
                  delete updatedFilters.groups_ids // Remove if empty
                }

                if (jobValues.length > 0) {
                  updatedFilters.job_profile_ids = jobLabels.join(',')
                } else {
                  delete updatedFilters.job_profile_ids // Remove if empty
                }

                setFilterState((prev) => ({
                  ...prev,
                  ...(values.length > 0 ? { groups_ids: values } : {}),
                  ...(jobValues.length > 0
                    ? { job_profile_ids: jobValues }
                    : {}),
                  activeFilters: updatedFilters,
                }))
              }}
            >
              Filter
            </Button>
            <Button
              onClick={handleClear}
              variant="outlined"
              size="small"
              style={{
                textTransform: 'none',
                backgroundColor: 'transparent',
                borderColor: '#407797',
                color: '#407797',
                marginLeft: '10px',
              }}
            >
              Clear
            </Button>
          </Stack>
        </Box>
        <CustomTable
          headers={headers}
          rows={rows}
          columnSettingsHandler={setColumnSettings}
          columnSettingsState={columnSettings}
          columnSettingsOptions={columnSettingsOptions}
          columnSettingsName="trainingListField"
          enableColumnSettings
          renderActiveFilter={
            <>
              {selectedRows.length === 0 ? (
                <ActiveFilters
                  filterData={filterState}
                  update={setFilterState}
                  keyName="record_of_training"
                />
              ) : (
                <BulkUpdate
                  setBulkArchive={setBulkArchive}
                  setChangeStatus={setChangeStatus}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              )}
            </>
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
        <CustomModal
          title="Bulk Archive"
          width="30%"
          height="auto"
          handleClose={() => setBulkArchive(false)}
          open={bulkArchive}
        >
          <BulkArchive
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            onCancel={() => setBulkArchive(false)}
            refetch={callRefetch}
            bulkType={'training_participant'}
            text="Records Of Training(s)"
            url="/api/v2/rot_management/training_participants/bulk_archive"
          />
        </CustomModal>
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordsOfTrainingList)
