import axios from 'axios'
import React from 'react'
import { ButtonExport } from './table'

const ExportCSV = ({ url, params = {}, fileName, label, icon }) => {
  const exportData = async () => {
    try {
      await axios
        .get(url, {
          responseType: 'blob',
          params,
          headers: {
            'Content-Type': 'text/csv',
            Accept: 'text/csv',
          },
        })
        .then((response) => {
          const today = new Date()
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
          const formattedDate = today
            .toLocaleDateString('en-US', options)
            .replace(/\//g, '-')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            fileName
              ? `${fileName}-${formattedDate}.csv`
              : 'users-group-export.csv'
          ) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log('err==>', error)
        })
    } catch (err) {
      console.log('error==>', err)
    }
  }
  return label ? (
    <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        justifyContent: 'space-between',
      }}
      onClick={exportData}
    >
      {icon ? icon : null} <span style={{ paddingLeft: 5 }}>{label}</span>
    </div>
  ) : (
    <ButtonExport label={label || 'Export'} onClick={exportData} />
  )
}

export default ExportCSV
