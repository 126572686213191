export const menuData = [
    {
      parent_name: "Users & Groups",
      id: "users_groups",
      children: [
        {
          id: "ulist",
          name: "Users List",
          link: "/user_group"
        },
        {
          id: "jp",
          name: "Job Profiles",
          link: "/user_group?tab=jp"
        },
        {
          id: "cg",
          name: "Company Groups",
          link: "/user_group?tab=cg"
        },
        {
          id: "agp",
          name: "Assigned Global Projects",
          link: "/user_group?tab=agp"
        },
        {
          id: "division",
          name: "Divisions",
          link: "/users-and-groups?selected_tab=divisions&view_type=divisions"
        }
      ]
    },
    {
      parent_name: "Dashboard",
      id: "dashboard",
      children: [
        {
          id: "main_dashboard",
          name: "Main Dashboard",
          link: "/"
        },
        {
          id: "main_dashboard",
          name: "Main Dashboard",
          link: "/analytics-dashboard"
        },
        {
          id: "stats_dashboard",
          name: "Stats Dashboard",
          link: "/stats"
        },
        {
          id: "reporting_dashboard",
          name: "Reporting Dashboard",
          link: "/reports_dashboards"
        }
      ]
    },
    {
      parent_name: "Training",
      id: "training",
      children: [
        {
          id: "records_training",
          name: "Records of Training",
          link: "/rot_dashboard"
        },
        {
          id: "training_matrix",
          name: "Training Matrix",
          link: "/trainings/employees_trainings"
        }
      ]
    },
    {
      parent_name: "Forms",
      id: "forms",
      children: [
        {
          id: "start_new_form",
          name: "Start New Form",
          link: "/template/new_forms"
        },
        {
          id: "completed_forms",
          name: "Completed Forms",
          link: "/forms"
        },
        {
          id: "completed_forms",
          name: "Completed Forms",
          link: "/forms/archived"
        },
        {
          id: "completed_forms",
          name: "Completed Forms",
          link: "/forms/user_actions"
        },
        {
          id: "completed_forms",
          name: "Edit Completed Forms",
          link: "/audits/audit_"
        },
        {
          id: "template_creator",
          name: "Template Creator",
          link: "/template"
        },
        {
          id: "template_creator",
          name: "Template Creator",
          link: "/template/template_"
        }
      ]
    },
    {
      parent_name: "Documents",
      id: "documents",
      children: [
        {
          id: "company_docs",
          name: "Company Documents",
          link: "/company_documents_beta"
        },
        {
          id: "required_docs",
          name: "Required Documents",
          link: "/company_documents"
        },
        {
          id: "document_matrix",
          name: "Document Matrix",
          link: "/subcontractor_matrix"
        }
      ]
    },
    {
      id: "risk_assessment",
      parent_name: "Risk Assessment",
      link: "/risk-assessments"
    },
    {
      id: "risk_assessment",
      parent_name: "Risk Assessment",
      link: "/create-risk-assessment/"
    },
    {
      id: "risk_assessment",
      parent_name: "Risk Assessment",
      link: "/edit-risk-assessment/"
    },
    {
      id: "risk_assessment",
      parent_name: "Risk Assessment",
      link: "/view-risk-assessment/"
    },
    {
      id: "assets",
      parent_name: "Assets",
      link: "/asset-tracking"
    },
    {
      id: "emergency_plans",
      parent_name: "Emergency Plans",
      link: "/emergency-plans"
    },
    {
      id: "emergency_plans",
      parent_name: "Emergency Plans",
      link: "/create-plan"
    },
    {
      id: "emergency_plans",
      parent_name: "Emergency Plans",
      link: "/edit-plan/edit/"
    },
    {
      id: "emergency_plans",
      parent_name: "Emergency Plans",
      link: "/view-plan/view/"
    },
    {
      id: "subcontractors",
      parent_name: "Subcontractors",
      link: "/subcontractors"
    },
    {
      id: "subcontractors",
      parent_name: "Subcontractors",
      link: "/add-sub-contractors"
    },
    {
      id: "subcontractors",
      parent_name: "Subcontractors",
      link: "/edit-sub-contractors/"
    },
    {
      id: "subcontractors",
      parent_name: "Subcontractors",
      link: "/internal-sub-employee/"
    },
    {
      id: "subcontractors",
      parent_name: "Subcontractors",
      link: "/subcontractor/managesubs"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/inventory?tab=order-management"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/orders/"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/create-inventory"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/edit-inventory"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/view-inventory/"
    },
    {
      id: "inventory",
      parent_name: "Inventory",
      link: "/view-history/"
    },
    {
      id: "time_tracker",
      parent_name: "Time Tracker",
      link: "/time-tracking"
    },
    {
      id: "time_tracker",
      parent_name: "Time Tracker",
      link: "/time_tracking/configuration"
    },
    {
      id: "time_tracker",
      parent_name: "Time Tracker",
      link: "/time_tracking/"
    },
    {
      id: "qr_codes",
      parent_name: "QR Codes",
      link: "/qrcodes"
    },
    {
      id: "actions",
      parent_name: "Actions",
      link: "/actions"
    },
    {
      id: "actions",
      parent_name: "Actions",
      link: "/actions-management"
    },
    {
      id: "actions",
      parent_name: "Actions",
      link: "/actions-notices/view/"
    },
    {
      id: "actions",
      parent_name: "Actions",
      link: "/assets-actions/view/"
    },
    {
      id: "todo_management",
      parent_name: "TO DO List",
      link: "/todo-management"
    },
    {
      id: "release_notes",
      parent_name: "Release Notes",
      link: "/release_notes"
    },
    {
      id: "company_profile",
      parent_name: "Company Profile",
      link: "/company_profile"
    },
    {
      id: "stats_settings",
      parent_name: "Stats Settings",
      link: "/stats-settings"
    },
    {
      id: "user_profile",
      parent_name: "My Profile",
      link: "/user-profile"
    },
    {
      id: "assets",
      parent_name: "Asset Tracking",
      link: "/create-asset-tracking"
    },
    {
      id: "assets",
      parent_name: "Asset Tracking",
      link: "/edit-asset-tracking/edit/"
    },
    {
      id: "assets",
      parent_name: "Asset Tracking",
      link: "/view-asset-tracking/view/"
    },
    {
      id: "knowledge_base",
      parent_name: "Knowledge Base",
      link: "http://knowledge.safetyloop.com"
    }
  ]
  