import React, { useEffect, useState, useRef } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import {
    Grid,
    TextField,
    FormControl,
    Typography,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    Box,
} from '@mui/material';
import DualListbox from '../../../shared/components/dualListBox';
import useFetch from '../../../shared/components/userFetch';





export const NewUserForm = ({ register, errors, control, roles, isEdit }) => {
    const passwordField = {}
    if (!isEdit) {
        passwordField.required = 'Password is required'
    }
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };
    return (
        <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Box>
                    <TextField
                        placeholder="Enter First Name"
                        size="small"
                        {...register('first_name', { required: 'First Name is required' })}
                        error={!!errors.first_name}
                        helperText={errors.first_name ? errors.first_name.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Box>
                    <TextField
                        placeholder="Enter Last Name"
                        size="small"
                        {...register('last_name', { required: 'Last Name is required' })}
                        error={!!errors.last_name}
                        helperText={errors.last_name ? errors.last_name.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Email</Typography>
                    </Box>
                    <TextField
                        placeholder="Enter Email"
                        size="small"
                        type="email"
                        {...register('email', {
                            validate: value => value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email format'
                        })}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* Employee Phone Number */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Employee Phone Number</Typography>
                    </Box>
                    <TextField
                        placeholder="Enter Phone Number"
                        size="small"
                        {...register('phone_number', {
                            validate: value => value === '' || /^\d{10}$|^\d{3}-\d{3}-\d{4}$/.test(value) || 'Invalid phone number'
                        })}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number ? errors.phone_number.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* User Name */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            User Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Box>
                    <TextField
                        size="small"
                        placeholder="Enter Username"
                        {...register('username', { required: 'User Name is required' })}
                        error={!!errors.username}
                        helperText={errors.username ? errors.username.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* Password */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            Password {!isEdit && <span style={{ color: 'red' }}>*</span>}
                        </Typography>
                    </Box>
                    <TextField
                        size="small"
                        placeholder="Enter Password"
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', { ...passwordField })}
                        error={!!errors.password}
                        autoComplete="new-password"
                        helperText={errors.password ? errors.password.message : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleTogglePassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </Grid>

            {/* Row for Employee Id, Hire Date, and Termination Date */}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {/* Employee Id */}
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Box mb={1}>
                                <Typography variant="body1" className='f-label'>
                                    Employee Id <span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </Box>
                            <TextField
                                size="small"
                                placeholder="Enter Employee ID"
                                {...register('employee_id', { required: 'Employee Id is required' })}
                                error={!!errors.employee_id}
                                helperText={errors.employee_id ? errors.employee_id.message : ''}
                            />
                        </FormControl>
                    </Grid>

                    {/* Hire Date */}
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Box mb={1}>
                                <Typography variant="body1" className='f-label'>Hire Date</Typography>
                            </Box>
                            <TextField
                                size="small"
                                type="date"
                                {...register('hire_date')}
                            />
                        </FormControl>
                    </Grid>

                    {/* Termination Date */}
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Box mb={1}>
                                <Typography variant="body1" className='f-label'>Termination Date</Typography>
                            </Box>
                            <TextField
                                size="small"
                                type="date"
                                {...register('termination_date')}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            {/* Employee Notes */}
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Employee Notes</Typography>
                    </Box>
                    <TextField
                        placeholder="Enter Notes"
                        size="small"
                        multiline
                        rows={4}
                        {...register('notes')}
                    />
                </FormControl>
            </Grid>

            {/* Employee Contact Person Name */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Employee Contact Person Name</Typography>
                    </Box>
                    <TextField
                        placeholder="Emergency Contact Person Name"
                        size="small"
                        {...register('emergency_contact')}
                    />
                </FormControl>
            </Grid>

            {/* Employee Contact No */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Employee Contact No</Typography>
                    </Box>
                    <TextField
                        size="small"
                        placeholder="Emergency Contact Number"
                        {...register('emergency_contact_number', {
                            validate: value => value === '' || /^\d{10}$/.test(value) || 'Invalid phone number'
                        })}
                        error={!!errors.emergency_contact_number}
                        helperText={errors.emergency_contact_number ? errors.emergency_contact_number.message : ''}
                    />
                </FormControl>
            </Grid>

            {/* User Role */}
            <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" fullWidth error={!!errors.userRole}>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label d-label'>
                            User Role <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Box>
                    <Controller
                        name="role_id"
                        control={control}
                        rules={{ required: 'User Role is required' }}
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                {roles.map(role => (
                                    <FormControlLabel value={role.value} control={<Radio />} label={role.key} />
                                ))}
                            </RadioGroup>
                        )}
                    />
                    {errors.role_id && (
                        <Typography color="error">{errors.role_id.message}</Typography>
                    )}
                </FormControl>
            </Grid>

            {/* Approver */}
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label d-label'>Approver</Typography>
                    </Box>
                    <Controller
                        name="approver"
                        control={control}
                        defaultValue="" // Set a default value if needed
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                        )}
                    />
                </FormControl>
            </Grid>

            {/* App Permission */}
            <Grid item xs={12} sm={6} sx={{minWidth: '100%'}}>
                <div className='grey-box'>
                    <Typography variant="h6" gutterBottom className='f-label'>
                        App Permission
                    </Typography>
                    <Grid>
                        <FormControl>
                            <Controller
                                name="inventory_enabled"
                                control={control}
                                defaultValue={false} // Set default to false
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                inputRef={ref}
                                            />
                                        }
                                        label="Inventory"
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl>
                            <Controller
                                name="time_tracker_enabled"
                                control={control}
                                defaultValue={false} // Set default to false
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                inputRef={ref}
                                            />
                                        }
                                        label="Time Tracker"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

const TemplateNames = ({control, setValue, userId, selectedGroups}) => {
    let url = '/api/v2/entity_management/users/load_templates_by_groups'
    if (selectedGroups.length) {
        selectedGroups.forEach((id, i) => {
            url += `${i === 0 ? '?' : '&'}selected_group_ids[]=${id}`
        });
    }
    if (userId) {
        url += `&user_id=${userId}`;
    }
    const {data: templateData, isFetching} = useFetch(url);
    const {data = []} = templateData || {};
    if (isFetching) {
        
        return <Controller
        name="audit_templates"
        key={`audit_templates_${isFetching}`}
        control={control}
            
            render={({ field: { value } }) => (

                <DualListbox 
                    title="Assign Forms"
                    control={control} 
                    setValue={setValue} 
                    name="audit_templates" 
                    leftData={data.filter(d => value.every(val => val.value !== d.value))}
                    rightData={value}
                />
            )}
        />;
    }
    return <Controller
    name="audit_templates"
    control={control}
    key={`audit_templates_${isFetching}`}
    render={({ field: { value } }) => (
        <DualListbox 
            title="Assign Forms"
            control={control} 
            setValue={setValue} 
            name="audit_templates" 
            leftData={data.filter(d => value.every(val => val.value !== d.value))}
            rightData={value}
        />
    )}
/>
}

export const UserAssignmentForm = ({ setValue, control, groups, jobProfiles, userId}) => {
    const elementRef = useRef(null);
    const watchGroups = useWatch({control: control, name: 'groups', defaultValue: []});
    const selectedGroups = watchGroups.map(({value}) => value);
    useEffect(() => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView();
        }
    }, [])
    return (
        <>
            <div ref={elementRef} />
            <Controller
                name="groups"
                control={control}
                render={({ field: { value = [] } }) => (
                    <DualListbox 
                        title="Assign Groups / Projects Site"
                        control={control} 
                        setValue={setValue} 
                        name="groups" 
                        leftData={groups.filter(group => value.every(val => val.value !== group.value))}
                        rightData={value}
                    />
                )}
            />
            <Controller
                name="job_profiles"
                control={control}
                render={({ field: { value } }) => (
                    <DualListbox 
                        title="Assign Job Profile"
                        control={control} 
                        setValue={setValue} 
                        name="job_profiles"
                        leftData={jobProfiles.filter(profile => value.every(val => val.value !== profile.value))}
                        rightData={value}
                    />
                )}
            />
            <TemplateNames control={control} setValue={setValue} userId={userId} selectedGroups={selectedGroups} />
            
        </>
    )
}
