import React from 'react'
import { showToast } from '../../../shared/components/showNotification'
import useDelete from '../../../shared/components/useDelete'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import NewTrainingRecord from './NewTrainingRecord'

const EditTrainingRecord = ({ edit, setEdit, refetch, optimisticUpdate }) => {
  const {
    data,
    isLoading,
    refetch: callRefetch,
  } = useFetch(`/api/v2/rot_management/training_participants/${edit}`)
  //`/api/v2/rot_management/record_of_trainings/${edit}?view_type=form`
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/rot_management/training_participants')
  const { mutate: deleteMutate, isLoading: isDeleting } = useDelete(
    `/api/v2/rot_management/training_participants/${edit}/files`
  )
  if (isLoading) {
    return null
  }
  const handleDeleteFile = (file) => {
    const payload = {
      id: file.id,
    }
    deleteMutate(payload, {
      onSuccess: () => {
        showToast({ message: 'File deleted Successfully' })
        callRefetch()
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
  }
  return (
    <NewTrainingRecord
      mutate={mutate}
      open={!!edit}
      setOpen={setEdit}
      initialValues={data?.data || {}}
      refetch={refetch}
      isMutating={isMutating}
      optimisticUpdate={optimisticUpdate}
      handleDeleteFile={handleDeleteFile}
    />
  )
}

export default EditTrainingRecord
