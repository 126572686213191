import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import ActiveFilters from '../../../shared/components/activeFilters'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  TagRender,
} from '../../../shared/components/table'
import useDelete from '../../../shared/components/useDelete'
import useFetch from '../../../shared/components/userFetch'
// import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setActionPageCount } from '../../../../reducers/common'
import AlertDialog from '../../../shared/components/alretDialog'
import CustomModal from '../../../shared/components/CustomModal'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { actionIcons } from '../../ActionIcons'
import BulkArchive from './BulkArchive'
import StatusChange from './StatusChange'

const TableWrapper = styled('div')`
  .action_name,
  .action_priority_name,
  .action_sub_category,
  .action_notices_status,
  .action_types,
  .action_group,
  .action_assignees,
  .action_created_by,
  .action_due_date,
  .action_created_at
`

const columnSettingsOptions = [
  { value: 'action_name', label: 'Action Name' },
  { value: 'action_priority_name', label: 'Priority' },
  { value: 'action_sub_category', label: 'Sub Category' },
  { value: 'action_notices_status', label: 'Status' },
  { value: 'action_types', label: 'Action Type' },
  { value: 'action_group', label: 'Groups' },
  { value: 'action_assignees', label: 'Assignees' },
  { value: 'action_created_by', label: 'Created By' },
  { value: 'action_created_at', label: 'Created On' },
  { value: 'action_due_date', label: 'Due' },
]

const BulkUpdate = ({
  selectedRows,
  setSelectedRows,
  setChangeStatus,
  setBulkArchive,
}) => {
  return (
    <div
      style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}
    >
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M3.75 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5Z"
            fill="white"
          />
          <path
            d="M16.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V16.6667C2 17.0203 2.14048 17.3594 2.39052 17.6095C2.64057 17.8595 2.97971 18 3.33333 18H16.6667C17.0203 18 17.3594 17.8595 17.6095 17.6095C17.8595 17.3594 18 17.0203 18 16.6667V3.33333C18 2.97971 17.8595 2.64057 17.6095 2.39052C17.3594 2.14048 17.0203 2 16.6667 2ZM14 11.064H6V8.9058H14V11.064Z"
            fill="#407797"
          />
        </svg>
        <span>Selected Rows</span>
        <span>{selectedRows.length}</span>
      </div>
      <Button
        onClick={() => setBulkArchive(true)}
        size="small"
        variant="outlined"
        style={{
          color: '#407797',
          backgroundColor: '#FFFFFF',
          borderColor: '#407797',
          textTransform: 'none',
        }}
      >
        Archive
      </Button>
      <Button
        onClick={() => setChangeStatus(true)}
        size="small"
        variant="outlined"
        style={{
          color: '#407797',
          backgroundColor: '#FFFFFF',
          borderColor: '#407797',
          textTransform: 'none',
        }}
      >
        Status Change
      </Button>
      <Button
        size="small"
        variant="text"
        style={{
          textTransform: 'none',
          color: '#FF3030',
          textDecoration: 'underline',
        }}
        onClick={() => setSelectedRows([])}
      >
        Cancel
      </Button>
    </div>
  )
}

const ActionsList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  baseData,
  actionsCount,
  setActionPageCount,
  setView,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/actions_management/actions?view_type=list_view', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const columnSetting = localStorage.getItem('actionListField') || '{}'
  const parsedColumnSetting = JSON.parse(columnSetting)
  let settingsData = {
    action_name: true,
    action_priority_name: true,
    action_sub_category: true,
    action_notices_status: true,
    action_types: true,
    action_group: true,
    action_created_by: true,
    action_due_date: true,
    action_due: true,
    action_assignees: true,
    action_created_at: true,
  }
  if (Object.keys(parsedColumnSetting).length) {
    settingsData = parsedColumnSetting
  } else {
    localStorage.setItem('actionListField', JSON.stringify(settingsData))
  }
  const { mutate, isLoading: isDeleting } = useDelete(
    '/api/v2/actions_management/actions'
  )
  const [selectedRows, setSelectedRows] = useState([])
  const [changeStatus, setChangeStatus] = useState(false)
  const [bulkArchive, setBulkArchive] = useState(false)
  const [columnSettings, setColumnSettings] = useState(settingsData)
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    metadata.count &&
      setActionPageCount({
        action_notices: {
          actions_list:
            metadata.count || actionsCount.action_notices.actions_list,
          actions_archive: actionsCount.action_notices.actions_archive,
        },
      })
  }, [metadata.count])

  const {
    action_types = [],
    priorities = [],
    sub_categories = [],
    statuses = [],
    groups = [],
    company_users = [],
    permissions: {
      allow_delete = true,
      allow_edit = true,
      allow_assign_job_profile = true,
      allow_view = true,
    } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]

  const groupsFilterData = createFilterData(groups)
  const subCategoryFilterData = createFilterData(sub_categories)
  const statusFilterData = createFilterData(statuses)
  const actionTypeFilterData = createFilterData(action_types)
  const assigneesFilterData = createFilterData(company_users)

  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleOk = (row, setClose) => {
    const payload = {
      id: deleteItem.id,
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: 'Action Deactivated Successfully' })
        callRefetch()
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const handleSelectedRows = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id])
    } else {
      const newValues = selectedRows.filter((e) => e !== id)
      setSelectedRows(newValues)
    }
  }

  const headers = [
    <TableCell
      className={`left action_name ${
        columnSettings.action_name ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        isSort
        handleSort={(e) => handleSort('label', e)}
        title="Action Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_priority_name ${
        columnSettings.action_priority_name ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Priority"
        isSort
        handleSort={(e) => handleSort('priority', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              priorities.find((e) => e.value === filterData['priority_eq']) ||
              'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'priority_eq')}
            options={[
              { label: 'All', value: 'All' },
              ...priorities.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_sub_category ${
        columnSettings.action_sub_category ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Sub Category"
        isSort
        handleSort={(e) => handleSort('sub_category_type_label', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              subCategoryFilterData.find(
                (e) => e.value === filterData['sub_category_type_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'sub_category_type_id_eq')
            }
            options={subCategoryFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_notices_status ${
        columnSettings.action_notices_status ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Status"
        isSort
        handleSort={(e) => handleSort('status', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              statusFilterData.find(
                (e) => e.value === filterData['status_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'status_eq')}
            options={statusFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_types ${
        columnSettings.action_types ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Action Types"
        isSort
        handleSort={(e) => handleSort('action_type_id', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              actionTypeFilterData.find(
                (e) => e.value === filterData['action_type_id_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'action_type_id_eq')
            }
            options={actionTypeFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_assignees ${
        columnSettings.action_assignees ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Assignees"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              assigneesFilterData.find(
                (e) => e.value === filterData['audit_action_users_user_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'audit_action_users_user_id_eq')
            }
            options={assigneesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_group ${
        columnSettings.action_group ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Groups / Project Sites"
        isSort
        handleSort={(e) => handleSort('group_id', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              groupsFilterData.find(
                (e) => e.value === filterData['group_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'group_id_eq')}
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_created_by ${
        columnSettings.action_created_by ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Created by"
        isSort
        handleSort={(e) => handleSort('created_by', e)}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              assigneesFilterData.find(
                (e) => e.value === filterData['created_by_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'created_by_eq')
            }
            options={assigneesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`action_created_at ${
        columnSettings.action_created_at ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        isSort
        handleSort={(e) => handleSort('created_at', e)}
        title="Created On"
      />
    </TableCell>,
    <TableCell
      className={`action_due_date ${
        columnSettings.action_due_date ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Due"
        isSort
        handleSort={(e) => handleSort('archived_eq', e)}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      label,
      priority,
      sub_category_type,
      status,
      status_label,
      action_type,
      action_type_name,
      group_name,
      created_by,
      created_at,
      due_date = '-',
      assigned_user_names = [],
      updated_at,
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell
          className={`left action_name ${
            columnSettings.action_name ? 'tc_show' : 'hide'
          }`}
        >
          <div className="flex" style={{ alignItems: 'center' }}>
            {allow_assign_job_profile && (
              <Checkbox
                style={{ color: '#407797' }}
                checked={selectedRows.includes(ele.id)}
                onChange={handleSelectedRows(ele.id)}
              />
            )}
            <span style={{ paddingRight: '5px' }}>
              {ele.is_action_overdue ? actionIcons.forced : <>&nbsp;</>}
            </span>
            <div className="flex flex-c">
              <Link to={allow_view ? `/actions-notices/view/${ele.id}` : '#'}>
                <div
                  style={{
                    color: '#080808',
                    fontWeight: '700',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {label}
                </div>
              </Link>
              {action_type_name && (
                <div
                  className="desc elips150"
                  style={{
                    color: '#909090',
                    textDecoration: 'none',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                  title={action_type_name}
                >
                  {action_type_name || ''}
                </div>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell
          className={`action_priority_name ${
            columnSettings.action_priority_name ? 'tc_show' : 'hide'
          }`}
        >
          <span className={`action_priority ${priority}`}>
            {actionIcons[priority]} {priority}
          </span>
        </TableCell>
        <TableCell
          className={`action_sub_category ${
            columnSettings.action_sub_category ? 'tc_show' : 'hide'
          }`}
        >
          {sub_category_type}
        </TableCell>
        <TableCell
          className={`action_notices_status ${
            columnSettings.action_notices_status ? 'tc_show' : 'hide'
          }`}
        >
          <span className={`action_status ${status}`}>{status_label}</span>
        </TableCell>
        <TableCell
          className={`action_types ${
            columnSettings.action_types ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {action_type}
        </TableCell>
        <TableCell
          className={`action_assignees ${
            columnSettings.action_assignees ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          <TagRender data={assigned_user_names} users />
        </TableCell>
        <TableCell
          className={`action_group ${
            columnSettings.action_group ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {group_name}
        </TableCell>
        <TableCell
          className={`action_created_by ${
            columnSettings.action_created_by ? 'tc_show' : 'hide'
          }`}
        >
          {created_by}
        </TableCell>
        <TableCell
          className={`action_created_at ${
            columnSettings.action_created_at ? 'tc_show' : 'hide'
          }`}
        >
          {created_at}
        </TableCell>
        <TableCell
          className={`action_due_date ${
            columnSettings.action_due_date ? 'tc_show' : 'hide'
          }`}
        >
          {due_date}
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              if (allow_view) {
                menuItems.push(
                  <MenuItem>
                    <Link to={`/actions-notices/view/${ele.id}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.view}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          View
                        </span>
                      </Box>
                    </Link>
                  </MenuItem>
                )
              }
              if (allow_edit) {
                menuItems.push(
                  <MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.edit}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Edit
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              if (allow_delete) {
                menuItems.push(
                  <MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.archive}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Archive
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Typography variant="h6">List View</Typography>
        <Stack direction="row" alignItems="center">
          <div style={{ cursor: 'not-allowed' }}>
            {actionIcons.listViewSelected}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => setView('board')}>
            {actionIcons.boardView}
          </div>
        </Stack>
      </Box>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Archive Action"
        description="Are You sure you want to archive this action?"
        okText="Confirm"
      />
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          columnSettingsHandler={setColumnSettings}
          columnSettingsState={columnSettings}
          columnSettingsOptions={columnSettingsOptions}
          columnSettingsName="actionListField"
          enableColumnSettings
          renderActiveFilter={
            <>
              {selectedRows.length === 0 ? (
                <ActiveFilters
                  filterData={filterState}
                  update={setFilterState}
                  keyName="action_notices"
                />
              ) : (
                <BulkUpdate
                  setBulkArchive={setBulkArchive}
                  setChangeStatus={setChangeStatus}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              )}
            </>
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
        <CustomModal
          title="Status Change"
          width="30%"
          height="auto"
          handleClose={() => setChangeStatus(false)}
          open={changeStatus}
        >
          <StatusChange
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            onCancel={() => setChangeStatus(false)}
            refetch={callRefetch}
            baseData={baseData}
          />
        </CustomModal>
        <CustomModal
          title="Bulk Archive"
          width="30%"
          height="auto"
          handleClose={() => setBulkArchive(false)}
          open={bulkArchive}
        >
          <BulkArchive
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            onCancel={() => setBulkArchive(false)}
            refetch={callRefetch}
          />
        </CustomModal>
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setActionPageCount: (value) => dispatch(setActionPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionsList)
