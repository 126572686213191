// import React from 'react'
// import { Link } from "react-router-dom"
// import '../../assets/styles/themes.scss'
// import PricingHeaderLogo from './PricingHeaderLogo.svg'
// import PricingHeaderLogoPL from './pl-logo2.svg'

// const PricingHeader = () => {
//   return (
//     <div className="header">
//       <a href="/">
//         <img src={PricingHeaderLogo} alt='' />
//       </a>
//       <span className="header-title" style={{fontSize:'36px', marginLeft:'20px', lineHeight:'60px', paddingTop:'20px'}}>+</span>
//       <a href="https://www.projectloop.com/?from=sl-pricing">
//         <img src={PricingHeaderLogoPL} alt='' style={{height: '33px', marginLeft: '20px'}} />
//       </a>
//     </div>
//   )
// }

// export default PricingHeader



import React, { useState, useEffect, useRef } from "react";
import { Nav, Navbar, Button, NavItem, NavDropdown } from "react-bootstrap";
import { NavLink, Link, Redirect } from "react-router-dom";

import Logo from "../../../javascript/assets/images/navbar-logo.png";
import PhoneIcon from "../../../javascript/assets/fonts/Phone.svg";

const Layout = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const goLogin = () => {
    return <Redirect to='/login' />;
  };

  const handleLinkClick = () => {
    window.open('https://cor-academy.com', '_blank');
  };

  const handlePLLink = () => {
    window.open("https://projectloop.com?from=SL", "_blank");
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
      <div className="Header">
        <Navbar inverse collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img src={Logo} alt="..." style={{ width: "200px" }} />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav className="menuitems">
              <NavDropdown
                  title="Features"
                  id="safetytloop-features"
                  className="features-dropdown"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown();
                  }}
                  show={showDropdown}
                  ref={dropdownRef}
              >
                {showDropdown && (
                    <div className="dropdown-grid">
                      <div className="feature-item">
                        <a href="/features/asset_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/excavator.png" alt="Asset Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Asset Management</strong>
                              <p>Monitor and manage your assets efficiently.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/form_creation">
                          <div className="feature-content">
                            <img src="/main-images/hp/copy.png" alt="Form Creation Icon" className="feature-icon"/>
                            <div>
                              <strong>Form Creation</strong>
                              <p>Create and manage forms with ease.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/form_scheduling">
                          <div className="feature-content">
                            <img src="/main-images/hp/timer.png" alt="Form Scheduling Icon" className="feature-icon"/>
                            <div>
                              <strong>Form Scheduling</strong>
                              <p>Schedule forms to ensure timely submissions.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/subcontractor_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/contractors.png" alt="Subcontractor Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Subcontractor Management</strong>
                              <p>Manage and monitor subcontractors on site.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/risk_assessment_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/risk-management.png" alt="Risk Assessment Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Risk Assessment Management</strong>
                              <p>Identify and assess risks proactively.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/emergency_plan_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/emergency.png" alt="Emergency Plan Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Emergency Plan Management</strong>
                              <p>Prepare and manage emergency plans.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/training_certificate">
                          <div className="feature-content">
                            <img src="/main-images/hp/quality-control.png" alt="Training Certificate Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Training Certificate Management</strong>
                              <p>Manage employee training certifications.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/company_document_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/copy.png" alt="Company Document Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Company Document Management</strong>
                              <p>Organize and manage company documents.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/actions">
                          <div className="feature-content">
                            <img src="/main-images/hp/action.png" alt="Actions Icon" className="feature-icon"/>
                            <div>
                              <strong>Actions</strong>
                              <p>Streamline and track actions and tasks.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/user_management">
                          <div className="feature-content">
                            <img src="/main-images/hp/user-management.png" alt="User Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>User Management</strong>
                              <p>Manage users and permissions effectively.</p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="feature-item">
                        <a href="/features/ContractorManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/contractors.png" alt="Contractor Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Contractor Management</strong>
                              <p>Manage contractor information and compliance.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/DocumentManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/copy.png" alt="Document Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Document Management</strong>
                              <p>Organize and store project-related documents.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/IncidentManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/risk-management.png" alt="Incident Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Incident Management</strong>
                              <p>Track, report, and analyze safety incidents.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/InspectionManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/corrective.png" alt="Inspection Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Inspection Management</strong>
                              <p>Conduct and manage inspections and reports.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/TrainingRecordManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/quality-control.png" alt="Training Record Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Training Record Management</strong>
                              <p>Maintain detailed employee training records.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/HazardManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/identification.png" alt="Hazard Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Hazard Management</strong>
                              <p>Identify and control workplace hazards.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/CorrectiveActions">
                          <div className="feature-content">
                            <img src="/main-images/hp/action.png" alt="Corrective Actions Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Corrective Actions</strong>
                              <p>Implement measures for compliance and safety.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/OnboardingOrientation">
                          <div className="feature-content">
                            <img src="/main-images/hp/compliant.png" alt="Onboarding Orientation Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Onboarding Orientation</strong>
                              <p>Manage new employee onboarding processes.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/EmergencyManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/emergency.png" alt="Emergency Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Emergency Management</strong>
                              <p>Prepare and respond to emergency situations.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/QRCodeManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/qr-code.png" alt="QR Code Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>QR Code Management</strong>
                              <p>Streamline access using QR codes.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/InventoryManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/inventory.png" alt="Inventory Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Inventory Management</strong>
                              <p>Track and manage inventory supplies.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/TaskManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/execute.png" alt="Task Management Icon" className="feature-icon"/>
                            <div>
                              <strong>Task Management</strong>
                              <p>Create, assign, and track tasks efficiently.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/TimeTracking">
                          <div className="feature-content">
                            <img src="/main-images/hp/timer.png" alt="Time Tracking Icon" className="feature-icon"/>
                            <div>
                              <strong>Time Tracking</strong>
                              <p>Monitor and track employee work hours.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/Analytics">
                          <div className="feature-content">
                            <img src="/main-images/hp/data-analytics.png" alt="Analytics Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Analytics</strong>
                              <p>Generate insights with comprehensive analytics.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/ComplianceManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/file.png" alt="Compliance Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Compliance Management</strong>
                              <p>Ensure adherence to legal standards.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/DataIntegrations">
                          <div className="feature-content">
                            <img src="/main-images/hp/connection.png" alt="Data Integrations Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Data & Integrations</strong>
                              <p>Integrate data for better visibility.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/FlaggedUserManagement">
                          <div className="feature-content">
                            <img src="/main-images/hp/restriction.png" alt="Flagged User Management Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Flagged User Management</strong>
                              <p>Manage flagged users for compliance.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/PrivateForms">
                          <div className="feature-content">
                            <img src="/main-images/hp/secret-file.png" alt="Private Forms Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Private Forms</strong>
                              <p>Collect data securely using private forms.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/Reporting">
                          <div className="feature-content">
                            <img src="/main-images/hp/report.png" alt="Reporting Icon" className="feature-icon"/>
                            <div>
                              <strong>Reporting</strong>
                              <p>Generate and view custom reports.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/AutoNotifications">
                          <div className="feature-content">
                            <img src="/main-images/hp/notification.png" alt="Auto Notifications Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Auto Notifications</strong>
                              <p>Set up automated reminders and alerts.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="feature-item">
                        <a href="/features/WearableIntegrations">
                          <div className="feature-content">
                            <img src="/main-images/hp/watch.png" alt="Wearable Integrations Icon"
                                 className="feature-icon"/>
                            <div>
                              <strong>Wearable Integrations</strong>
                              <p>Integrate data from wearables for safety.</p>
                            </div>
                          </div>
                        </a>
                      </div>

                    </div>
                )}
              </NavDropdown>

              <NavItem className="navvy">
                <NavLink to="/about" activeClassName="active">About Us</NavLink>
              </NavItem>

              <NavItem className="navvy">
                <NavLink to="/pricing" activeClassName="active">Pricing</NavLink>
              </NavItem>

              <NavItem className="navvy">
                <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
              </NavItem>

              <NavItem className="navvy">
              <span onClick={handleLinkClick} activeClassName="active" style={{color: "#000"}}>
                COR Academy
                <img src="/main-images/COR-Academy-Badge.png" style={{width: '50px', marginLeft: '5px'}}/>
              </span>
              </NavItem>

              <NavItem className="navvy">
              <span onClick={handlePLLink} style={{color: "#000"}}>
                <a href="https://projectloop.com?from=SL" target="_blank" style={{color: "#000"}}>
                  <img src="/main-images/pl-logo2.svg" style={{width: '130px', marginLeft: '0px'}}/>
                  <i className="fa fa-external-link" style={{marginLeft: '5px', fontSize: '12px'}}></i>
                </a>
              </span>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
          <div className="contact-dlts">
            <p className="phone-icon">
              <img src={PhoneIcon} alt=""/>
            </p>
            <span className="phone-number">833-444-9876</span>
            <Link to="/login">
              <Button onClick={goLogin} style={{
                height: '45px',
                fontSize: '16px',
                backgroundColor: 'rgb(64, 119, 151)',
                borderColor: 'rgb(64, 119, 151)'
              }}>Login</Button>
            </Link>
          </div>
        </Navbar>
      </div>
  );
};

export default Layout;
