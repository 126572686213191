import React, { useEffect, useReducer, useState } from "react";
import SideNav from "../../components/SideNav"; 
import AssetTracking from "./AssetTracking";
import AssetType from "./AssetType"; 
import { withRouter } from "react-router-dom"; 

const initialTabStatus = {
  assetType: false,
  inventory: false,
  config: false
};

const reducer = (state, action) => {

  switch (action.type) {
    case "UPDATE":
      state[action.tab] = true;
      return { ...state };
    default:
      return state;
  }
};

const AssetTrackingTool = ({ history, location }) => {
  const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
  const [queryParams, setQueryParams] = useState("");
  
  const handleUpdateTab = (tab) => {
    dispatch({ type: "UPDATE", tab: tab });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('model');
    setQueryParams(paramValue);
  }, [])

  // debugger;
  
  return (
    <React.Fragment>
      {/* <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav /> */}
          <div className="content-wrap asset-tracking-wrap risk-assessment mt-75">
            <div className="title-tab-wrap">
              <h1>
                <i
                  style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                  className="fa fa-truck"
                ></i>
                <b>Asset Tracking</b>
              </h1>
              <ul className="nav nav-tabs">
                <li className={queryParams ? "" : "active"}>
                  <a data-toggle="tab" href="#asset-tracking">
                    Asset Tracking
                  </a>
                </li>
                <li onClick={(e) => { handleUpdateTab('assetType') }}>
                  <a data-toggle="tab" href="#asset-type">
                    Asset Types
                  </a>
                </li> 
              </ul>
            </div>

            <div className="tab-content">
              <div id="asset-tracking" className={!queryParams ? "tab-pane fade in active" : "tab-pane fade in"}>
                <AssetTracking />
              </div>
              <div id="asset-type" className="tab-pane fade">
                {tabs.assetType && <AssetType />}
              </div> 
            </div>
          </div>
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default withRouter(AssetTrackingTool);
