import React from "react";


export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{ textAlign: "center"}} className="print-qr-body">
        <p
            style={{ fontWeight: "500", fontSize: "37px", color: "#080808" }}
          >
            {localStorage.getItem('token')}
          </p>
        <img alt="" src={props.pageData.qr_url ? props.pageData.qr_url : ""} style={{ width: "100%"}}/>
          <span  style={{ fontWeight: "500", fontSize: "20px", color: "#909090" }}>QR Code Link: - {`${window.location.origin}/company/${props.pageData.company_id}/qr_code/${props.pageData.id}`}</span>
          <p  style={{ fontWeight: "500", fontSize: "20px", color: "#080808" }}>Scanning this will add you to this .</p>
      </div>
    );
  });