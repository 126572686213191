import { Button, Stack, TableCell, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setROTPageCount } from '../../../../reducers/common'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import ActiveFilters from '../../../shared/components/activeFilters'
import CustomDateRangeFilter from '../../../shared/components/customDateRangeFilter'
import Loader from '../../../shared/components/loader'
import CustomTable, {
  CustomPagination,
  HeaderItem,
  TagRender,
} from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import useFetch from '../../../shared/components/userFetch'

const ApprovalList = ({ rotCount, setROTPageCount }) => {
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange({ page: 1, limit: 20 })
  const {
    data: responseData = {},
    isLoading,
    refetch,
  } = useFetch('/api/v2/rot_management/training_schedules/approved_trainings', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    setROTPageCount({
      wap: {
        ...rotCount.wap,
        approved: metadata.count || rotCount.wap.approved,
      },
    })
  }, [metadata.count])
  const headers = [
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('training_topics_name')}
        title="Training Topic"
        filter={
          <InputSearchFilter
            style={{ width: '100%', background: '#fff' }}
            name="training_topics_name_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('date_of_training')}
        title="Date of Training"
        filter={
          <CustomDateRangeFilter
            value={filterData?.['date_of_training_eq']}
            fieldName={'date_of_training_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Expiration of Training"
        isSort
        handleSort={() => handleSort('expiry_date')}
        filter={
          <CustomDateRangeFilter
            value={filterData?.['expiry_date_eq']}
            fieldName={'expiry_date_eq'}
            handleFiledChange={handleOnFilterChange}
          />
        }
      />
    </TableCell>,
    ,
    <TableCell>
      <HeaderItem title="Participant(s)" />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      training_topics = [],
      completion_date,
      expiry_date,
      training_participants = [],
      pdf_link,
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell>
          <TagRender
            data={training_topics}
            showInMenu
            customLimit={3}
            users
            enableComma
          />
        </TableCell>
        <TableCell>{completion_date || '-'}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          <TagRender
            data={training_participants}
            showInMenu
            customLimit={3}
            users
          />
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <Stack spacing={2} direction="row" style={{ alignItems: 'center' }}>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#D9E4EA',
                color: '#407797',
                outline: 'none',
                whiteSpace: 'nowrap',
              }}
              onClick={(e) => {
                e.preventDefault() // Prevents default navigation
                window.open(pdf_link, '_blank', 'noopener,noreferrer')
              }}
            >
              View PDF
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    )
  })
  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <>
            <ActiveFilters
              filterData={filterState}
              update={setFilterState}
              keyName="wap"
            />
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalList)
