import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'

const BulkArchive = ({
  selectedRows = [],
  onCancel,
  refetch,
  setSelectedRows,
  text = '',
  url = '',
  bulkType = '',
}) => {
  const { mutate, isLoading: isMutating } = usePatch()

  const getPayload = () => {
    if (bulkType === 'training_participant') {
      return {
        training_participant: { training_participant_ids: selectedRows },
      }
    } else {
      return {
        audit_action: { action_ids: selectedRows },
      }
    }
  }

  const onSubmit = () => {
    const payload = getPayload()
    mutate(
      payload,
      {
        onSuccess: () => {
          onCancel()
          showToast({ message: `${text || 'Actions'} archived successfully` })
          refetch()
          setSelectedRows([])
        },
      },
      url || '/api/v2/actions_management/actions/bulk_archive'
    )
  }

  return (
    <>
      <div style={{ background: '#F6F6F6', padding: '8px' }}>
        <Typography variant="body3">
          Selected Actions:{' '}
          <span style={{ fontWeight: 700 }}>{selectedRows.length}</span>
        </Typography>
      </div>
      <p style={{ color: '#5C5C5C' }}>
        Are you sure want to archive this{' '}
        <span style={{ fontWeight: 700 }}>
          {`Selected ${text || 'Actions'}?`}.
        </span>{' '}
        This will move all to the Archived.
      </p>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
      >
        <Button
          style={{
            textTransform: 'none',
            backgroundColor: '#ECECEC',
            color: '#5C5C5C',
            marginRight: '10px',
            outline: 'none',
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          endIcon={
            isMutating ? <CircularProgress size={16} color="inherit" /> : null
          }
          style={{
            textTransform: 'none',
            backgroundColor: '#D92929',
            color: '#fff',
            outline: 'none',
          }}
          disabled={isMutating}
          variant="contained"
          color="primary"
        >
          Archive
        </Button>
      </Box>
    </>
  )
}
export default BulkArchive
