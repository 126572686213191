import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import Loader from '../../../shared/components/loader'
import CustomTable, {
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'

import AlertDialog from '../../../shared/components/alretDialog'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { rotIcons } from '../../rotIcons'

const TableWrapper = styled('div')`
  .custom_table_container {
    max-height: 350px !important;
    border-radius: 10px !important;
  }
`
const ProfileList = ({ setSelectedUser, selectedUser, profiles }) => {
  return (
    <>
      <Box style={{ background: '#E0F0F9', padding: 8 }}>
        <Autocomplete
          options={profiles}
          disableClearable
          value={selectedUser}
          size="small"
          getOptionLabel={(option) => option.key}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              style={{ background: '#fff', border: 0 }}
            />
          )}
          onChange={(event, newValue) =>
            setSelectedUser({ selectedUser: newValue, page: 1, limit: 20 })
          }
        />
      </Box>
      <Box
        style={{
          height: 'calc(100vh - 70px)',
          overflowY: 'auto',
        }}
      >
        <List>
          {profiles.map((profile, index) => (
            <ListItem
              button
              key={index}
              onClick={() =>
                setSelectedUser({ selectedUser: profile, page: 1, limit: 20 })
              }
              style={{
                background:
                  selectedUser.value === profile.value ? '#ECF1F5' : '#fff',
                borderBottom: '1px solid #ECECEC',
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              <ListItemText primary={profile.key} />
              <ListItemIcon style={{ minWidth: 'auto' }}>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 10.6665L5.66667 5.99984L1 1.33317"
                    stroke="#909090"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

const RenderLabelValue = ({ label, value }) => {
  return (
    <Box
      style={{
        display: 'flex',
        gap: '5px',
        background: '#fff',
        padding: '8px 13px',
        borderRadius: '25px',
      }}
    >
      <Typography style={{ color: '#5c5c5c' }} variant="body_2">
        {label}:
      </Typography>
      <Typography variant="body_2">{value}</Typography>
    </Box>
  )
}

const TrainingList = ({
  trainings = [],
  user = {},
  handleDelete,
  handleEdit,
  handleRestore,
}) => {
  const headers = [
    <TableCell>
      <HeaderItem title="Training Name" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Date Completed" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Expiration Date" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Status" />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = trainings.map((ele, index) => {
    const {
      topic_name = '',
      completion_date = '',
      expiry_date = '',
      training_status = '',
    } = ele || {}
    return (
      <TableRow
        key={index}
        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
      >
        <TableCell style={{ color: !ele?.id ? '#D92929' : '#080808' }}>
          {topic_name}
        </TableCell>
        <TableCell>{completion_date}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          {training_status && ele?.id && (
            <div
              style={{
                background:
                  training_status === 'Active' ? '#E5F8E9' : '#FFEAEA',
                color: training_status === 'Active' ? '#00BA25' : '#D92929',
                padding: 5,
                borderRadius: 25,
                textAlign: 'center',
                maxWidth: '70px'
              }}
            >
              {training_status}
            </div>
          )}
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          {/* <>
            {' '}
            <ActionMenu>
              {(setClose) => {
                const menuItems = []
                menuItems.push(
                  <MenuItem>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault() // Prevents default navigation
                        window.open(
                          ele.pdf_link,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.view}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          View PDF
                        </span>
                      </Box>
                    </Link>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.edit}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Edit
                      </span>
                    </Box>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleRestore(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.archive}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Archive
                      </span>
                    </Box>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.delete}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Delete
                      </span>
                    </Box>
                  </MenuItem>
                )
                return menuItems
              }}
            </ActionMenu>
          </> */}
          <Button
            style={{
              textTransform: 'none',
              backgroundColor: '#D9E4EA',
              color: '#407797',
              outline: 'none',
              whiteSpace: 'nowrap',
              visibility: ele.id ? 'visible' : 'hidden',
            }}
            onClick={(e) => {
              e.preventDefault() // Prevents default navigation
              if (ele?.pdf_link) {
                window.open(ele?.pdf_link, '_blank', 'noopener,noreferrer')
              }
            }}
          >
            View PDF
          </Button>
        </TableCell>
      </TableRow>
    )
  })
  return (
    <>
      <Box
        style={{
          paddingBlock: '8px',
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: '700', fontSize: '16px' }}
          variant="body_2"
        >
          {user.full_name || user.username}
        </Typography>
        <RenderLabelValue label="Emp ID" value={user.employee_id || ''} />
        <RenderLabelValue label="Email" value={user.email || ''} />
        <RenderLabelValue label="Username" value={user.username || ''} />
      </Box>
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          renderActiveFilter={false}
          isSliderScroll={false}
        />
      </TableWrapper>
    </>
  )
}

const TrainingListWrapper = ({
  selectedUser,
  setEdit,
  pagination,
  setPagination,
}) => {
  const [deleteItem, setDeleteItem] = useState({ isDelete: false })
  const [alertOpen, setAlertOpen] = useState(false)
  const {
    data: responseData = {},
    isLoading,
    isFetching,
    refetch,
  } = useFetch(
    `/api/v2/rot_management/record_of_trainings/load_job_profile_trainings/${selectedUser.value}?page=${pagination.page}&limit=${pagination.limit}`
  )
  const { mutate, isLoading: isDeleting } = usePatch()

  const handleEdit = (row, setClose) => {
    setEdit(row.training_schedule_id)
    if (setClose) {
      setClose()
    }
  }
  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose, isDelete: true })
    setAlertOpen(true)
  }

  const handleOk = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully archived.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/archive`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  const handleDeleteConfirm = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully deleted.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/soft_delete`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }

  if (isLoading || isFetching) {
    return <Loader />
  }
  const { data = [], metadata = {} } = responseData || {}
  if (!data?.length) {
    return (
      <Box
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        {rotIcons.no_data}
      </Box>
    )
  }
  return (
    <>
      <Box
        style={{
          display: 'flex',
          gap: '10px',
          padding: '16px',
          background: '#E0F0F9',
          borderRadius: '5px',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        }}
      >
        <Typography
          style={{ fontWeight: '600', fontSize: '14px' }}
          variant="body_2"
        >
          {selectedUser.key}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#407797',
            padding: '2px',
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {metadata?.count || 0}
        </div>
      </Box>
      <Box
        style={{
          background: '#F6F6F6',
          paddingInline: '16px',
          height: '100%',
          overflowY: 'auto',
          paddingBlock: 0,
          marginBottom: '70px',
          position: 'relative',
        }}
      >
        {data.map((d) => (
          <TrainingList
            user={d.user}
            trainings={d.trainings}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleRestore={handleRestore}
          />
        ))}
        <AlertDialog
          alertOpen={alertOpen}
          handleClose={() => setAlertOpen(false)}
          handleOk={deleteItem.isDelete ? handleDeleteConfirm : handleOk}
          title={`${deleteItem.isDelete ? 'Delete' : 'Archive'} Participant(s)`}
          description={`Are You sure you want to ${
            deleteItem.isDelete ? 'Delete' : 'Archive'
          } this Participant(s)?`}
          okText={`${deleteItem.isDelete ? 'Delete' : 'Archive'}`}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '28px',
            left: 0,
            right: 0,
            background: '#fff',
          }}
        >
          {!!metadata.count && (
            <CustomPagination
              totalPage={metadata.last}
              lastPage={metadata.last}
              currentPage={pagination.page}
              handlePageChange={(_, nextPage) => {
                setPagination((prev) => ({ ...prev, page: nextPage }))
              }}
              rowOptions={[20, 50, 100]}
              rowPerPage={pagination.limit}
              handleRowPerPageChange={(targetValue) => {
                setPagination((prev) => ({
                  ...prev,
                  limit: parseInt(targetValue, 10),
                  page: 1,
                }))
              }}
            />
          )}
        </div>
      </Box>
    </>
  )
}

const ByJobProfileList = ({ initialSelectedTopic, profiles, setEdit }) => {
  const [listState, setListState] = useState({
    selectedUser: initialSelectedTopic,
    page: 1,
    limit: 20,
  })
  return (
    <div
      style={{ padding: 8, border: '1px solid #D3D3D3', background: '#fff' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ProfileList
            profiles={profiles}
            selectedUser={listState.selectedUser}
            setSelectedUser={setListState}
          />
        </Grid>
        <Grid item xs={9}>
          <TrainingListWrapper
            pagination={{ page: listState.page, limit: listState.limit }}
            setPagination={setListState}
            selectedUser={listState.selectedUser}
            setEdit={setEdit}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const ByJobProfileListWrapper = ({ setEdit }) => {
  const { data: responseData = {}, isLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/load_job_profiles'
  )
  if (isLoading) {
    return <Loader />
  }
  const { job_profiles = [] } = responseData || {}
  const initialSelectedTopic = job_profiles[0] || {}
  if (!initialSelectedTopic.value) {
    return <>No Data</>
  }
  return (
    <ByJobProfileList
      profiles={job_profiles}
      initialSelectedTopic={initialSelectedTopic}
      setEdit={setEdit}
    />
  )
}
export default ByJobProfileListWrapper
