import React, { useEffect, useReducer, useState } from "react";
import SideNav from "../../components/SideNav";
import Paywall from "./TimeTrackingList/Paywall";
import * as PropTypes from "prop-types";

import TimeTrackingIcon from "../../assets/images/time_tracking_icon.svg"
import Settings from "../../assets/images/Settings-new.svg";
import TimeTrackingList from "./TimeTrackingList";
import { User } from "../HomePage/Login";
import {withRouter} from "react-router-dom";
import Stack from "../../assets/images/Stack.svg";
import {Button} from "@mui/material";


const initialTabStatus = {
    time_tracker: true,
    config: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

TimeTrackingList.propTypes = {openUpdateModalHandler: PropTypes.any};
const TimeTrackingTool = ({ history, location }) => {
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
    const [queryParams, setQueryParams] = useState("");
    const [isTimeTrackerPaywall, setIsTimeTrackerPaywall] = useState(false);

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    useEffect(() => {
        setIsTimeTrackerPaywall(User.showTimeTrackerPaywall());
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get("model");
        setQueryParams(paramValue);
        const divElement = document.querySelector('.dashboard-container');
        if (divElement) {
          divElement.classList.add('msmcontainer');
        }
    }, []);

    return (
        <React.Fragment>
            {/* <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav /> */}
                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <div className="d-flex" style={{justifyContent: "space-between"}}>
                                <div className="paywall-exit-btn">
                                    <div>
                                        <h1>
                                            <img src={TimeTrackingIcon} alt={'Time Tracking'} style={{ paddingInlineStart: "5px", paddingRight: "5px" }}/>
                                            <b>Time Tracking</b>
                                        </h1>
                                    </div>
                                    <div>
                                        {isTimeTrackerPaywall &&
                                            <Button className="btn-create" variant="contained"
                                                onClick={() => setIsTimeTrackerPaywall(false)}
                                            >
                                            <span>
                                                <img src={Stack} alt="Stack"/>
                                            </span>
                                                Exit
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div style={{padding: 10}}>
                                    
                                    {/*<button*/}
                                    {/*    type="button"*/}
                                    {/*    className="btn btn-outline-primary"*/}
                                    {/*    onClick={(e) => {*/}
                                    {/*        window.location.href = '/time-tracking/configuration';*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <i className="fa fa-cog" style={{ paddingInlineStart: "5px", paddingRight: "5px" }} aria-hidden="true"></i>*/}
                                    {/*    Configuration*/}
                                    {/*</button>*/}
                                </div>
                            </div>

                            {isTimeTrackerPaywall ? (
                                <Paywall />
                            ) : (
                                <>
                                    {/*<ul className="nav nav-tabs">*/}
                                    {/*    <li*/}
                                    {/*        className={queryParams ? "" : "active"}*/}
                                    {/*        onClick={(e) => {*/}
                                    {/*            handleUpdateTab("time-tracking");*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <a data-toggle="tab" href="#time-tracking">*/}
                                    {/*            Time Tracking*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </>
                            )}
                        </div>
                        { !isTimeTrackerPaywall &&
                            <div className="tab-content">
                                <div
                                    id="time_tracker"
                                    className={
                                        !queryParams ? "tab-pane fade in active" : "tab-pane fade in"
                                    }
                                >
                                    <TimeTrackingList openUpdateModalHandler={setIsTimeTrackerPaywall} />
                                </div>
                            </div>
                        }
                    </div>

                {/* </div>
            </div> */}
        </React.Fragment>
    );
};

export default withRouter(TimeTrackingTool);
