import React, { useEffect } from "react";
import SideNav from "../../../components/SideNav";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Downarrow from "../../../assets/images/downarrow.svg";
import Application from "../../../config/ApplicationWrapper";
import Norecords from "../../../assets/images/no-records.png";

const ViewRiskAssessment = (params) => {
  const [notes, setNotes] = React.useState([]);
  const [ratDetails, setRatDetails] = React.useState([]);
  const [riskRanges, setRiskRanges] = React.useState();
  const cookieDetails = Cookies.get("token");

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        let ratsData = await fetch(
            `${Application.api_url}/api/v2/rats/${params.match.params.id}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
        );
        if (ratsData.status === 200) {
          ratsData = await ratsData.json();
          setRatDetails(ratsData && ratsData.rat ? ratsData.rat : []);
          setNotes(ratsData && ratsData.notes ? ratsData.notes : []);
        } else if (ratsData.status === 403) {
          let oneSignalId = window.OneSignal.getUserId(function (userId) {
            return userId;
          });
          if (oneSignalId) {
            let logout = await fetch(`/users/sign_out?push_token=${oneSignalId}`)
            if (logout.status == 200) {
              window.location.href = '/';
            }
          }
        }

        let riskRangesData = await fetch(
            `${Application.api_url}/api/v2/risk_assesments/risk_ranges`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
        );
        riskRangesData = await riskRangesData.json();
        setRiskRanges(
            riskRangesData && riskRangesData.risk_range
                ? riskRangesData.risk_range
                : {}
        );
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, []);

  const CustomComponent = ({ obj }) => {
    if (riskRanges && riskRanges.risk_calc != "Multiplication") {
      return (
          <>
          <span class="badge badge-primary" style={{ marginRight: "4px" }}>
            PRE :{" "}
            {Number(obj.pre_f) +
                Number(obj.pre_np) +
                Number(obj.pre_p) +
                Number(obj.pre_sp)}
          </span>
            <span class="badge badge-primary">
            POST :{" "}
              {Number(obj.post_f) +
                  Number(obj.post_np) +
                  Number(obj.post_p) +
                  Number(obj.post_sp)}
          </span>
          </>
      );
    } else {
      return (
          <>
          <span class="badge badge-primary" style={{ marginRight: "4px" }}>
            PRE :{" "}
            {Number(obj.pre_f) *
                Number(obj.pre_np) *
                Number(obj.pre_p) *
                Number(obj.pre_sp)}
          </span>
            <span class="badge badge-primary">
            POST :{" "}
              {Number(obj.post_f) *
                  Number(obj.post_np) *
                  Number(obj.post_p) *
                  Number(obj.post_sp)}
          </span>
          </>
      );
    }
  };

  return (
      <React.Fragment>
        {/* <div className="msmcontainer dashboard-container">
          <div className="row d-flex">
            <SideNav /> */}
            <div className="content-wrap col-sm-12 create-risk-assessment">
              <div className="card">
                <div className="card-body">
                  <div class="risk-assessment-link" style={{ padding: "15px" }}>
                    <Link to="/risk-assessments" id="create-risk-assessment-link">
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </Link>
                    &nbsp; View Risk Assessment
                  </div>
                  <div style={{ padding: "0 15px 15px 15px" }}>
                    <h2>Risk Assessment Information</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Risk Type</label>
                        <input
                            className="form-control"
                            type="text"
                            readOnly
                            value={
                              ratDetails.ctra_risk_type &&
                              ratDetails.ctra_risk_type.name
                                  ? ratDetails.ctra_risk_type.name
                                  : ""
                            }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Assessment Name</label>
                        <input
                            className="form-control"
                            type="text"
                            value={ratDetails.name}
                            readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label style={{paddingTop: "10px"}}>Assessment Type</label>
                        <input
                            className="form-control"
                            type="text"
                            value={ratDetails.assessment_type}
                            readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {ratDetails.assessment_type === 'upload' ? (
                      <div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div
                                className="d-flex justify-content-between"
                                style={{margin: "10px 0"}}
                            >
                              <h2>Attachment</h2>
                            </div>
                            <img src={ratDetails.file.url} />
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div
                                className="d-flex justify-content-between"
                                style={{margin: "10px 0"}}
                            >
                              <h2>Approver</h2>
                            </div>
                            <table className="table table-striped approver">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Company</th>
                                <th>Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              {ratDetails &&
                              ratDetails.ctra_approved_users &&
                              ratDetails.ctra_approved_users.length > 0 ? (
                                  ratDetails.ctra_approved_users.map((val, index) => (
                                      <>
                                        <tr>
                                          <td>
                                            <div className="d-flex child-group">
                                              <img
                                                  className="downarrow"
                                                  src={Downarrow}
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target={`#collapseExample${index}`}
                                                  aria-expanded="false"
                                                  aria-controls="collapseExample"
                                              />
                                              <div>
                                      <span className="name">
                                        {val.display_name}
                                      </span>
                                                <br/>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{val.company_name}</td>
                                          <td>{val.status}</td>
                                        </tr>
                                        <tr
                                            className="collapse"
                                            id={`collapseExample${index}`}
                                        >
                                          <td colSpan="6" className="action_details">
                                            <div>
                                              <h4>Action Details</h4>
                                              <div className="card">
                                                <div className="card-body">
                                                  <div className="d-flex" style={{margin: "10px 0"}}>
                                                    <div
                                                        className="col-md-4"
                                                        style={{
                                                          borderRight: "1px solid #D3D3D3",
                                                          marginTop: "5px",
                                                        }}
                                                    >
                                                      <p>Name</p>
                                                      <h5
                                                          style={{marginBottom: "27px"}}
                                                      >
                                                        {val.display_name}
                                                      </h5>
                                                    </div>
                                                    <div
                                                        className="col-md-4"
                                                        style={{
                                                          borderRight: "1px solid #D3D3D3",
                                                          marginTop: "5px",
                                                        }}
                                                    >
                                                      <p>Time</p>
                                                      <h5>{val.approval_date}</h5>
                                                    </div>
                                                    <div className="col-md-4">
                                                      <p>Signature</p>
                                                      <p>
                                                        <img
                                                            src={val.signature}
                                                            alt=""
                                                            style={{
                                                              width: "150px",
                                                              marginTop: "10px",
                                                            }}
                                                        />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img"/>
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  ) : (
                      <div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div className="risk-assessment-step-header">
                              <h2>Risk Assessment Steps</h2>
                            </div>
                            {ratDetails &&
                            ratDetails.tasks &&
                            ratDetails.tasks.length > 0 ? (
                                ratDetails.tasks.map((val, index) => (
                                    <>
                                      <table class="table">
                                        <thead>
                                        <tr>
                                          <th>Task Name</th>
                                          <th>Hazards / Controls</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr key={index}>
                                          <td>
                                            <p className="task-name">{val.name}</p>
                                          </td>

                                          <td>
                                            {val &&
                                                val.hazards &&
                                                val.hazards.length > 0 &&
                                                val.hazards.map((obj, index) => (
                                                    <table className="hazards table table-striped">
                                                      <thead>
                                                      <tr>
                                                        <th>
                                                          <i
                                                              class="fa fa-exclamation-triangle"
                                                              aria-hidden="true"
                                                          ></i>
                                                          {obj.name}
                                                        </th>
                                                        <th>
                                                          <CustomComponent obj={obj}/>
                                                        </th>
                                                      </tr>
                                                      </thead>
                                                      <tbody>
                                                      {obj.controls.map((value, index) => (
                                                          <tr>
                                                            <td>{value.name}</td>
                                                          </tr>
                                                      ))}
                                                      </tbody>
                                                    </table>
                                                ))}
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </>
                                ))
                            ) : (
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th>Task Name</th>
                                    <th>Hazards / Controls</th>
                                    <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img"/>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                            )}
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body" style={{margin: "15px"}}>
                            <div
                                className="d-flex justify-content-between"
                                style={{margin: "10px 0"}}
                            >
                              <h2>Approver</h2>
                            </div>
                            <table className="table table-striped approver">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Company</th>
                                <th>Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              {ratDetails &&
                              ratDetails.ctra_approved_users &&
                              ratDetails.ctra_approved_users.length > 0 ? (
                                  ratDetails.ctra_approved_users.map((val, index) => (
                                      <>
                                        <tr>
                                          <td>
                                            <div class="d-flex child-group">
                                              <img
                                                  class="downarrow"
                                                  src={Downarrow}
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target={`#collapseExample${index}`}
                                                  aria-expanded="false"
                                                  aria-controls="collapseExample"
                                              />
                                              <div>
                                      <span class="name">
                                        {val.display_name}
                                      </span>
                                                <br/>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{val.company_name}</td>
                                          <td>{val.status}</td>
                                        </tr>
                                        <tr
                                            class="collapse"
                                            id={`collapseExample${index}`}
                                        >
                                          <td colspan="6" className="action_details">
                                            <div>
                                              <h4>Action Details</h4>
                                              <div class="card">
                                                <div class="card-body">
                                                  <div className="d-flex" style={{ margin: "10px 0" }}>
                                                    <div
                                                        className="col-md-4"
                                                        style={{
                                                          borderRight: "1px solid #D3D3D3",
                                                          marginTop: "5px",
                                                        }}
                                                    >
                                                      <p>Name</p>
                                                      <h5
                                                          style={{ marginBottom: "27px" }}
                                                      >
                                                        {val.display_name}
                                                      </h5>
                                                    </div>
                                                    <div
                                                        className="col-md-4"
                                                        style={{
                                                          borderRight: "1px solid #D3D3D3",
                                                          marginTop: "5px",
                                                        }}
                                                    >
                                                      <p>Time</p>
                                                      <h5>{val.approval_date}</h5>
                                                    </div>
                                                    <div className="col-md-4">
                                                      <p>Signature</p>
                                                      <p>
                                                        <img
                                                            src={val.signature}
                                                            alt=""
                                                            style={{
                                                              width: "150px",
                                                              marginTop: "10px",
                                                            }}
                                                        />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="card  notes">
                          <div className="card-body" style={{ margin: "15px" }}>
                            <div className="risk-assessment-step-header">
                              <h2>Notes</h2>
                            </div>
                            <table className="table table-striped ">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Created On</th>
                                <th>Notes</th>
                              </tr>
                              </thead>
                              <tbody>
                              {notes && notes.length > 0 ? (
                                  notes.map((val, index) => (
                                      <tr key={index}>
                                        <td>{val.submitter}</td>
                                        <td>{val.created_at}</td>
                                        <td>{val.note}</td>
                                      </tr>
                                  ))
                              ) : (
                                  <tr>
                                    <td colSpan={3} className="no-records">
                                      <img src={Norecords} className="no-records-img" />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          {/* </div>
        </div> */}
      </React.Fragment>
  );
};

export default ViewRiskAssessment;
