import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import useUpdate from '../../../shared/components/useUpdate'

const TopicsForm = ({
  initialValues = null,
  mutate,
  onClose = () => false,
  refetch,
  setFilterState,
  isMutating,
  baseData = {},
}) => {
  const { expiration_data = [], topics = [] } = baseData || {}

  const isEdit = !!initialValues
  const filteredData = isEdit
    ? topics.filter((item) =>
        initialValues.linked_topic_ids.includes(item.value)
      )
    : []
  const defaultValues = isEdit
    ? { ...initialValues, linked_topic_ids: filteredData }
    : {
        label: '',
        description: '',
        expiry_value: '',
        linked_topic_ids: [],
      }
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const createFilterData = (data) => [
    ...data.map((e) => ({
      ...e,
      label: e.key,
      value: e.key.trim().toLowerCase().replace(/\s+/g, '_') || e.value,
    })),
  ]

  const expirationFilterData = createFilterData(expiration_data)

  const onSubmit = (data) => {
    const payload = {
      topics_for_training: {
        ...data,
        linked_topic_ids: data?.linked_topic_ids?.map((e) => e.value) || [],
      },
    }

    if (isEdit) {
      payload.id = initialValues.id
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({
          message: isEdit
            ? 'Training Topic Updated Successfully'
            : 'Training Topic Created Successfully',
        })
        onClose()
        if (refetch && isEdit) {
          refetch()
        }
        if (!isEdit) {
          setFilterState({ page: 1, limit: 20 })
        }
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        {/*Title */}
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              placeholder="Create title"
              size="small"
              {...register('title', { required: 'Title is required' })}
              error={!!errors.title}
              helperText={errors.title ? errors.title.message : ''}
            />
          </FormControl>
        </Grid>
        {/* Description */}
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Description
              </Typography>
            </Box>
            <TextField
              placeholder="Enter Description"
              size="small"
              multiline
              rows={4}
              {...register('description')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth error={!!errors.linked_topic_ids}>
            <Box mb={1}>
              <Typography variant="body1" className="">
                Linked Topics
              </Typography>
            </Box>
            <Controller
              name="linked_topic_ids"
              control={control}
              // rules={{ required: 'Linked Topic is required' }}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  size="small"
                  limitTags={4}
                  id="multiple-limit-tags-topics"
                  options={topics.filter(
                    (option) =>
                      !(field.value || []).some((val) => val.key === option.key)
                  )}
                  getOptionLabel={(option) => option.key}
                  defaultValue={[]}
                  value={field.value || []}
                  onChange={(event, newValue) => {
                    field.onChange(newValue) // Update field value on change
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              )}
            />
            {/* {errors.linked_topic_ids && (
              <FormHelperText>{errors.linked_topic_ids.message}</FormHelperText>
            )} */}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Expiration
            </Typography>
          </Box>
          <Controller
            name="expiry_value"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {expirationFilterData.map((role) => (
                  <FormControlLabel
                    value={role.value}
                    control={<Radio />}
                    label={role.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
      >
        <div>
          <Button
            style={{
              textTransform: 'none',
              backgroundColor: '#ECECEC',
              color: '#5C5C5C',
              marginRight: '10px',
              outline: 'none',
              height: '48px',
              padding: '8px 16px',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              isMutating ? <CircularProgress size={16} color="inherit" /> : null
            }
            style={{
              textTransform: 'none',
              backgroundColor: '#407797',
              color: '#fff',
              outline: 'none',
              height: '48px',
              padding: '8px 16px',
            }}
            disabled={isMutating}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </div>
      </Box>
    </form>
  )
}

export const TopicsFormCreate = ({
  refetch,
  setFilterState,
  onClose,
  baseData = {},
}) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/rot_management/training_topics')
  return (
    <TopicsForm
      refetch={refetch}
      setFilterState={setFilterState}
      isMutating={isMutating}
      mutate={mutate}
      onClose={onClose}
      baseData={baseData}
    />
  )
}

export const TopicsFormEdit = ({
  id,
  setFilterState,
  refetch,
  onClose,
  baseData = {},
}) => {
  const { data: actionData, isLoading } = useFetch(
    `/api/v2/rot_management/training_topics/${id}`
  )
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/rot_management/training_topics')
  const { data = {} } = actionData || {}
  if (isLoading) {
    return <Loader />
  }
  return (
    <TopicsForm
      initialValues={data}
      refetch={refetch}
      setFilterState={setFilterState}
      isMutating={isMutating}
      onClose={onClose}
      mutate={mutate}
      baseData={baseData}
    />
  )
}
