import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowUp from "../../../../../assets/fonts/ArrowRight.svg";
import Searchbar from "../../../../../assets/images/searchbar.svg";
import PDF from "../../../../../assets/images/PDF.png";
import axios from "../../../../../config/axiosConfig";
import Application from "../../../../../config/ApplicationWrapper";
import Loader from "../../../../../components/MobileViewLoader";
import loader from "../../../../../assets/images/newloader.gif";


const Trainings = ({ history }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const mounted = useRef(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState();
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        getPageDetails(userDetails)
        setLoading(false)
    }, [])

    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            mounted.current && getPageDetails(userDetails, 1)
            mounted.current = true;
        }, 2000);

        return () => clearTimeout(redirectTimer);
    }, [search]);

    const getPageDetails = async (details, page) => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details.company_identifier}/internal_trainings`, {
                params: {
                    page: page ? page : pageNumber,
                    limit: 20,
                    topic_name_cont: search,
                },
            });
            if (resultObj.data) {
                let details = resultObj.data;
                
                if(!search){
                    setPageDetails(prevItems => [...prevItems, ...details.data])
                    setPageNumber(pageNumber + 1);
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                } else {
                    setPageDetails(details.data)
                }
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const goBack = () => {
        history.push('/internalsub_dashboard');
    }

    const viewPDF = async (data) => {
        window.open(`${Application.api_url}/api/record_of_trainings/${data.training_schedule_id}/edit`, '_blank'); 
    }

    function CheckValidate({ date }) {
        if (moment(date).format('DD/MM/yyyy') >= moment().format('DD/MM/yyyy')) {
            return <p className="active-training">Active</p>
        } else {
            return <p className="active-expire">Expired</p>
        }
    }

    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-trainings">
                    <div className="title">
                        <img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} /><span className="role">Training</span>
                    </div>
                    <div className="search-bar">
                        <input className="search-input" placeholder="Search" onChange={(e) => { setSearch(e.target.value) }} />
                        <img src={Searchbar} alt="Searchbar" className="search-icon" />
                    </div>
                    <div className="forms-list">
                        <InfiniteScroll
                            dataLength={pageDetails.length}
                            next={(e)=>{getPageDetails(userDetails)}}
                            hasMore={pageCount >= pageNumber } 
                            loader={
                                <div className="new-loader mobile-view" >
                                    <img src={loader} alt="" />
                                </div >
                            }
                        >
                            {
                                pageDetails.length > 0 &&
                                pageDetails.map((val, index) => {
                                    // debugger; // This will trigger the debugger when this block executes
                                    return (
                                        <div className="new-forms" key={index}>
                                            <div className="training-title">
                                                <p className="form-name">{val.title}</p>
                                                <CheckValidate date={val.expiry_date} />
                                            </div>
                                            <div className="form-info">
                                                <div className="details">
                                                    <label className="lable">Name:</label>
                                                    <label className="info">{val.training_participants[0].user.full_name}</label>
                                                </div>
                                                <div className="details">
                                                    <label className="lable">Completion Date:</label>
                                                    <label className="info">{val.completion_date}</label>
                                                </div>
                                                <div className="details">
                                                    <label className="lable">Expiration Date:</label>
                                                    <label className="info">{val.expiry_date}</label>
                                                </div>
                                            </div>
                                            <div className="form-btns ">
                                                <div className="view-pdf" onClick={(e) => { viewPDF(val) }}>
                                                    <img src={PDF} alt="back-arrow" className="back-arrow" />
                                                    <span>View PDF</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(Trainings);