import { createTheme, ThemeProvider } from '@mui/material'
import React from 'react'
import Loader from '../shared/components/loader'
import PlainTabs from '../shared/components/plainTabs'
import useFetch from '../shared/components/userFetch'
import ConfigWrapper from './Components/Configuration/ConfigWrapper'
import ROTWrapper from './Components/RecorsOfTraining/ROTWrapper'
import ScheduleWrapper from './Components/ScheduleExports/ScheduleWrapper'
import ApprovalWrapper from './Components/WaitingForApproval/ApprovalWrapper'
import { rotIcons } from './rotIcons'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, system-ui, -apple-system', // Change font family globally
  },
})

const ROTManagement = () => {
  const { data: permissionData, isLoading } = useFetch(
    '/api/v2/rot_management/feature_permission_setting'
  )
  const { data } = permissionData || {}
  const {
    allow_view_rot,
    allow_view_waiting_for_approval,
    allow_view_scheduled_exports,
    allow_view_configuration,
  } = data || {}

  const tabList = []

  if (allow_view_rot) {
    tabList.push({
      label: 'Records Of Training',
      value: 'rot',
    })
  }
  if (allow_view_waiting_for_approval) {
    tabList.push({
      label: 'Waiting For Approval',
      value: 'wap',
    })
  }
  if (allow_view_scheduled_exports) {
    tabList.push({
      label: 'Schedule Exports',
      value: 'se',
    })
  }
  if (allow_view_configuration) {
    tabList.push({
      label: 'Configuration',
      value: 'config',
    })
  }

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ paddingTop: 20, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '30px',
              paddingBottom: '10px',
            }}
          >
            {rotIcons.rot}
            <span
              style={{
                fontSize: '20px',
                color: '#5c5c5c',
                fontWeight: '400',
                padding: '0 5px',
              }}
            >
              Records Of Training
            </span>
          </div>
          <PlainTabs tabList={tabList}>
            {(active) => {
              const foundTab = tabList.find((e) => e.value === active)
              const title = foundTab ? foundTab.label : ''
              return (
                <>
                  <div style={{ padding: '20px 32px', background: '#F6F6F6' }}>
                    {active === 'rot' && (
                      <ROTWrapper title={title} active={active} />
                    )}
                    {active === 'wap' && (
                      <ApprovalWrapper title={title} active={active} />
                    )}
                    {active === 'se' && (
                      <ScheduleWrapper title={title} active={active} />
                    )}
                    {active === 'config' && (
                      <ConfigWrapper title={title} active={active} />
                    )}
                  </div>
                </>
              )
            }}
          </PlainTabs>
        </div>
      </ThemeProvider>
    </>
  )
}

export default ROTManagement
