import Axios from 'axios'
import { useState } from 'react'

const usePatch = (url) => {
  const [isLoading, setLoading] = useState(false)
  const mutate = async (payload, { onSuccess, onError }, overrideUrl) => {
    try {
      setLoading(true)
      const { data } = await Axios.patch(
        overrideUrl || `${url}/${payload.id}`,
        payload
      )
      if (onSuccess) {
        onSuccess(data)
      }
    } catch (error) {
      const errorData = error.response ? error.response.data : error.message
      if (onError) {
        onError(errorData)
      }
    } finally {
      setLoading(false)
    }
  }
  return { mutate, isLoading }
}

export default usePatch
