import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import UploadAttachments from '../../../shared/components/uploadAttachments'

export const TrainingRecordForm = ({
  register,
  errors,
  fileDetails,
  isEdit,
  control,
  topics = [],
  company_users = [],
  initialValues = null,
  setValue,
  handleDeleteFile,
  getValues,
  watch,
}) => {
  const selectedTopics = useWatch({ control, name: 'training_topics' })
  const completionDate = useWatch({ control, name: 'date_of_training' })
  useEffect(() => {
    if (selectedTopics?.length === 1 && completionDate) {
      const expiryValue = selectedTopics[0]?.expiry_value
      const completionDateObj = new Date(completionDate)
      let formattedExpiryDate = null
      if (expiryValue) {
        completionDateObj.setFullYear(
          completionDateObj.getFullYear() + expiryValue
        )
        formattedExpiryDate = completionDateObj.toISOString().split('T')[0]
      }

      setValue('expiry_date', formattedExpiryDate)
    } else if (selectedTopics?.length > 1 || selectedTopics?.length === 0) {
      setValue('expiry_date', '')
    }
  }, [selectedTopics, completionDate, setValue])

  return (
    <>
      <Grid container spacing={2}>
        {isEdit ? (
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                backgroundColor: '#f6f6f6',
                padding: '12px',
                borderRadius: '4px',
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={5.5}>
                  <Typography variant="body2" color="textSecondary">
                    Topic Name
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#080808',
                      fontWeight: '500',
                      fontSize: '14px',
                    }}
                  >
                    {initialValues.topic_name || '-'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: '30px' }}
                  />
                </Grid>

                <Grid item xs={5.5}>
                  <Typography variant="body2" color="textSecondary">
                    Participant
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#080808',
                      fontWeight: '500',
                      fontSize: '14px',
                    }}
                  >
                    {initialValues.user_full_name || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth error={!!errors.training_topics}>
                <Box mb={1}>
                  <Typography variant="body1" className="">
                    Select Topics <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Box>
                <Controller
                  name="training_topics"
                  control={control}
                  rules={{ required: 'Topics is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      size="small"
                      limitTags={4}
                      id="multiple-limit-tags-topics"
                      options={topics.filter(
                        (option) =>
                          !(field.value || []).some(
                            (val) => val.key === option.key
                          )
                      )}
                      getOptionLabel={(option) => option.key}
                      defaultValue={[]}
                      value={field.value || []}
                      onChange={(event, newValue) => {
                        field.onChange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                    />
                  )}
                />

                {errors.training_topics && (
                  <FormHelperText>
                    {errors.training_topics.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth error={!!errors.training_participants}>
                <Box
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">
                    Participant(s) <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Controller
                    name="training_participants"
                    control={control}
                    rules={{ required: 'Participant is required' }}
                    defaultValue={[]}
                    render={({ field }) => {
                      const isAllSelected =
                        field.value.length === company_users.length &&
                        company_users.length > 0
                      const isIndeterminate =
                        field.value.length > 0 &&
                        field.value.length < company_users.length

                      return (
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={isIndeterminate}
                            onChange={() => {
                              field.onChange(isAllSelected ? [] : company_users)
                            }}
                          />
                          <Typography
                            variant="body2"
                            style={{ fontSize: '14px', cursor: 'pointer' }}
                            onClick={() =>
                              field.onChange(isAllSelected ? [] : company_users)
                            }
                          >
                            Select All
                          </Typography>
                        </Box>
                      )
                    }}
                  />
                </Box>

                <Controller
                  name="training_participants"
                  control={control}
                  rules={{ required: 'Participant is required' }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      size="small"
                      limitTags={4}
                      id="multiple-limit-tags-participants"
                      options={company_users}
                      getOptionLabel={(option) => option.key}
                      disableCloseOnSelect
                      value={field.value || []}
                      onChange={(event, newValue) => field.onChange(newValue)}
                      renderOption={(props, option) => {
                        const isChecked = (field.value || []).some(
                          (item) => item.value === option.value
                        )

                        return (
                          <li
                            {...props}
                            key={option.value}
                            onClick={() => {
                              const alreadySelected = (field.value || []).some(
                                (item) => item.value === option.value
                              )

                              const newSelected = alreadySelected
                                ? field.value.filter(
                                    (item) => item.value !== option.value
                                  )
                                : [...field.value, option]

                              field.onChange(newSelected)
                            }}
                          >
                            <Checkbox
                              checked={isChecked}
                              style={{ marginRight: 8 }}
                            />
                            {option.key}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                    />
                  )}
                />

                {/* Show error message if validation fails */}
                {errors.training_participants && (
                  <FormHelperText>
                    {errors.training_participants.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.date_of_training}>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Completion Date <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <Controller
              name="date_of_training"
              control={control}
              rules={{
                required: 'Completion Date is required',
                validate: (value) => {
                  const expiryDate = getValues('expiry_date')
                  if (expiryDate && value > expiryDate) {
                    return 'Completion Date cannot be after Expiration Date'
                  }
                  return true
                },
              }}
              render={({ field }) => (
                <TextField {...field} size="small" type="date" fullWidth />
              )}
            />
            {errors.date_of_training && (
              <FormHelperText>{errors.date_of_training.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth error={!!errors.expiry_date}>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Expiration Date
              </Typography>
            </Box>
            <Controller
              name="expiry_date"
              control={control}
              rules={{
                validate: (value) => {
                  const completionDate = getValues('date_of_training')

                  if (!value) return true // Allow empty expiration date
                  if (completionDate && value < completionDate) {
                    return 'Expiration Date must be after Completion Date'
                  }
                  return true
                },
              }}
              render={({ field }) => (
                <TextField {...field} size="small" type="date" fullWidth />
              )}
            />
            {errors.expiry_date && (
              <FormHelperText>{errors.expiry_date.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Upload File
            </Typography>
          </Box>
          <UploadAttachments
            control={control}
            name="files"
            multiple={true}
            setValue={setValue}
            existingFiles={fileDetails.length ? fileDetails : []}
            handleDeleteFile={handleDeleteFile}
          />
        </Grid>
      </Grid>
    </>
  )
}
