import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setROTPageCount } from '../../../../reducers/common'
import { actionIcons } from '../../../Actions/ActionIcons'
import AlertDialog from '../../../shared/components/alretDialog'
import CustomModal from '../../../shared/components/CustomModal'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ButtonCreate,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { rotIcons } from '../../rotIcons'
import { TopicsFormCreate, TopicsFormEdit } from './TopicsForm'

const BulkUpdate = ({ selectedRows, setSelectedRows, setBulkArchive }) => {
  return (
    <div
      style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}
    >
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M3.75 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5Z"
            fill="white"
          />
          <path
            d="M16.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V16.6667C2 17.0203 2.14048 17.3594 2.39052 17.6095C2.64057 17.8595 2.97971 18 3.33333 18H16.6667C17.0203 18 17.3594 17.8595 17.6095 17.6095C17.8595 17.3594 18 17.0203 18 16.6667V3.33333C18 2.97971 17.8595 2.64057 17.6095 2.39052C17.3594 2.14048 17.0203 2 16.6667 2ZM14 11.064H6V8.9058H14V11.064Z"
            fill="#407797"
          />
        </svg>
        <span>Selected Rows</span>
        <span>{selectedRows.length}</span>
      </div>
      <Button
        onClick={() => setBulkArchive(true)}
        size="small"
        variant="outlined"
        style={{
          color: '#407797',
          backgroundColor: '#FFFFFF',
          borderColor: '#407797',
          textTransform: 'none',
        }}
      >
        Archive
      </Button>
      <Button
        size="small"
        variant="text"
        style={{
          textTransform: 'none',
          color: '#FF3030',
          textDecoration: 'underline',
        }}
        onClick={() => setSelectedRows([])}
      >
        Cancel
      </Button>
    </div>
  )
}

const BulkArchive = ({
  selectedRows = [],
  onCancel,
  refetch,
  setSelectedRows,
}) => {
  const { mutate, isLoading: isMutating } = usePatch()
  const onSubmit = () => {
    const payload = {
      topics_for_training: {
        topics_for_training_ids: selectedRows,
      },
    }
    mutate(
      payload,
      {
        onSuccess: () => {
          onCancel()
          showToast({ message: 'Topics archived successfully' })
          refetch()
          setSelectedRows([])
        },
      },
      '/api/v2/rot_management/training_topics/bulk_archive'
    )
  }

  return (
    <>
      <div style={{ background: '#F6F6F6', padding: '8px' }}>
        <Typography variant="body3">
          Selected Topics:{' '}
          <span style={{ fontWeight: 700 }}>{selectedRows.length}</span>
        </Typography>
      </div>
      <p style={{ color: '#5C5C5C' }}>
        Are you sure want to archive this{' '}
        <span style={{ fontWeight: 700 }}>Selected Topics?.</span> This will
        move all to the Archived.
      </p>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
      >
        <Button
          style={{
            textTransform: 'none',
            backgroundColor: '#ECECEC',
            color: '#5C5C5C',
            marginRight: '10px',
            outline: 'none',
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          endIcon={
            isMutating ? <CircularProgress size={16} color="inherit" /> : null
          }
          style={{
            textTransform: 'none',
            backgroundColor: '#D92929',
            color: '#fff',
            outline: 'none',
          }}
          disabled={isMutating}
          variant="contained"
          color="primary"
        >
          Archive
        </Button>
      </Box>
    </>
  )
}

const TrainingTopicsList = ({ rotCount, setROTPageCount, baseData }) => {
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange({ page: 1, limit: 20 })
  const {
    data: responseData = {},
    isLoading,
    refetch,
  } = useFetch('/api/v2/rot_management/training_topics', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { mutate, isLoading: isRestoring } = usePatch()

  const [selectedRows, setSelectedRows] = useState([])
  const [edit, setEdit] = useState(false)
  const [addNew, setNew] = useState(false)
  const [bulkArchive, setBulkArchive] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const { data = [], metadata = {} } = responseData || {}
  const { expiration_data = [] } = baseData || {}

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]

  const expirationFilterData = createFilterData(expiration_data)
  useEffect(() => {
    metadata.count &&
      setROTPageCount({
        config: {
          t_topics: metadata.count,
          a_topics: rotCount.config.a_topics,
        },
      })
  }, [metadata.count])

  const handleSelectedRows = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id])
    } else {
      const newValues = selectedRows.filter((e) => e !== id)
      setSelectedRows(newValues)
    }
  }
  const handleOk = () => {
    const payload = {
      id: deleteItem.id,
    }
    mutate(
      payload,
      {
        onSuccess: () => {
          showToast({ message: 'Action Deactivated Successfully' })
          callRefetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_topics/${deleteItem.id}/archive`
    )
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }
  const handleDelete = (ele) => {
    setDeleteItem({ ...ele })
    setAlertOpen(true)
  }
  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])
  const headers = [
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('title')}
        title="Name"
        filter={
          <InputSearchFilter
            style={{ width: '100%', background: '#fff' }}
            name="title_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Description" />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Expiration"
        isSort
        handleSort={() => handleSort('expiry_value')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              expirationFilterData.find(
                (e) => e.value === filterData['expiry_value_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'expiry_value_eq')
            }
            options={expirationFilterData}
            popupIcon={rotIcons.downArrow}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const { title, description, expiration } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell>
          <div className="flex" style={{ alignItems: 'center' }}>
            <Checkbox
              style={{ color: '#407797' }}
              checked={selectedRows.includes(ele.id)}
              onChange={handleSelectedRows(ele.id)}
            />
            <div className="flex flex-c">
              <div
                className="desc elips150"
                style={{
                  color: '#080808',
                  textDecoration: 'none',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                title={title}
              >
                {title || ''}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell>{description || '-'}</TableCell>
        <TableCell>{expiration}</TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <Stack spacing={2} direction="row" style={{ alignItems: 'center' }}>
            <Tooltip title={'Edit'} arrow>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setEdit(ele.id)}
              >
                {actionIcons.edit}
              </div>
            </Tooltip>
            <Tooltip title={'Delete'} arrow>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleDelete(ele)}
              >
                {actionIcons.delete}
              </div>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          border: 0,
          borderStyle: 'solid',
          borderColor: '#D3D3D3',
          borderWidth: '1px 1px 1px 1px',
          background: '#FFFFFF',
        }}
      >
        <Typography variant="h6">Training Topics</Typography>
        <ButtonCreate
          label="Add Topics"
          styleOverride={{
            color: '#fff',
            background: '#407797',
            fontWeight: '600',
          }}
          iconWhite
          onClick={() => setNew(true)}
        />
      </Box>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Archive Training Topic"
        description="Are You sure you want to archive this Training Topic?"
        okText="Confirm"
      />
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <>
            {selectedRows.length === 0 ? (
              <></>
            ) : (
              <BulkUpdate
                setBulkArchive={setBulkArchive}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            )}
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
      <CustomModal
        title="Bulk Archive"
        width="30%"
        height="auto"
        handleClose={() => setBulkArchive(false)}
        open={bulkArchive}
      >
        <BulkArchive
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          onCancel={() => setBulkArchive(false)}
          refetch={callRefetch}
        />
      </CustomModal>
      {addNew && (
        <CustomModal
          title="+ Add Topics"
          width="50%"
          height="auto"
          handleClose={() => setNew(false)}
          open={addNew}
        >
          <TopicsFormCreate
            onClose={() => setNew(false)}
            refetch={callRefetch}
            setFilterState={setFilterState}
            baseData={baseData}
          />
        </CustomModal>
      )}
      {edit && (
        <CustomModal
          title="+ Edit Topics"
          width="50%"
          height="auto"
          handleClose={() => setEdit(false)}
          open={!!edit}
          overrideStyle={{ padding: '24px' }}
        >
          <TopicsFormEdit
            onClose={() => setEdit(false)}
            id={edit}
            refetch={callRefetch}
            setFilterState={setFilterState}
            baseData={baseData}
          />
        </CustomModal>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrainingTopicsList)
