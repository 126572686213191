import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { experimentalStyled as styled } from '@mui/material/styles'
import React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { rotIcons } from '../../ROT_v2/rotIcons'
import AttachmentCard from './AttachmentCard'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F6F6F6',
  ...theme.typography.body2,
  padding: '10px',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const UploadAttachments = ({
  multiple = false,
  control,
  name,
  existingFiles = [],
  setValue,
  handleDeleteFile,
}) => {
  const filesList = useWatch({ control, name, defaultValue: {} })

  const handleDelete = (fileToDelete) => {
    const currentFiles = Array.isArray(filesList)
      ? filesList
      : Object.values(filesList)

    const updatedFilesList = currentFiles.filter(
      (file) => file !== fileToDelete
    )

    setValue(name, updatedFilesList)
  }
  const handleFileChange = (event, onChange) => {
    const file = multiple ? event.target.files : event.target.files[0]
    onChange(file)
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '125px',
          margin: '20px 0',
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field: { onChange } }) => (
            <input
              type="file"
              multiple={multiple}
              accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(event) => handleFileChange(event, onChange)}
              style={{ display: 'none' }}
              id={name}
            />
          )}
        />
        <label htmlFor={name}>
          <Button
            variant="outlined"
            component="span"
            sx={{ textTransform: 'none', color: '#65b5e3' }}
          >
            <Typography>{rotIcons.upload} Choose File</Typography>
          </Button>
        </label>
      </Box>
      {filesList && Object.keys(filesList).length > 0 && (
        <Box mb={1}>
          <Typography variant="body1" className="f-label">
            Attachments
          </Typography>
        </Box>
      )}
      <Box
        component="section"
        sx={{
          background: '#fff',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <Grid container spacing={{ xs: 2, md: 2 }} columns={15}>
          {filesList &&
            Object.values(filesList).map((file) => {
              console.log(file)
              return (
                <AttachmentCard file={file} handleDeleteFile={handleDelete} />
              )
            })}
        </Grid>
      </Box>
      {existingFiles && existingFiles.length > 0 && (
        <>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Existing Attachments
            </Typography>
          </Box>

          <Box
            component="section"
            sx={{
              background: '#fff',
              borderRadius: '8px',
              marginBottom: '16px',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 2 }} columns={15}>
              {existingFiles.map((file) => (
                <AttachmentCard
                  file={file}
                  handleDeleteFile={handleDeleteFile}
                />
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}

export default UploadAttachments
