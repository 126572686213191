import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as React from 'react'
import { useState } from 'react'
import { rotIcons } from '../../ROT_v2/rotIcons'
import ExportCSV from './exportCsv'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export default function MultiExport({ filterData = {} }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(false)
  const isArchived = Cookies.get('tab') === 'archived' ? '/archived' : ''

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v2/rot_management/record_of_trainings${isArchived}?pdf=true`,
        {
          responseType: 'arraybuffer',
          params: filterData ? { ...filterData } : {},
          headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        }
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const today = new Date()
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const formattedDate = today
        .toLocaleDateString('en-US', options)
        .replace(/\//g, '-')
      const blobUrl = window.URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute(
        'download',
        `records_of_training${
          isArchived === '/archived' ? '_archived' : ''
        }-${formattedDate}.pdf`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    } catch (err) {
      console.error('Error exporting PDF:', err)
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        sx={{
          background: '#65B5E3',
          color: '#FFF',
          border: '1.5px solid #fff',
          padding: '5px 16px',
          borderRadius: '4px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#65B5E3',
            color: '#FFF',
          },
        }}
        endIcon={rotIcons.downArrow}
      >
        Export
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple>
          <ExportCSV
            url={`/api/v2/rot_management/record_of_trainings${
              Cookies.get('tab') === 'archived' ? '/archived' : ''
            }`}
            fileName="Records_of_trainings"
            label={'Export To Excel'}
            icon={rotIcons.excel}
            params={{
              ...filterData,
            }}
          />
        </MenuItem>
        <MenuItem disableRipple>
          <div
            style={{
              cursor: loading ? 'not-allowed' : 'pointer',
              display: 'flex',
              alignItems: 'center',
              fontSize: '15px',
              justifyContent: 'space-between',
              opacity: loading ? 0.6 : 1,
            }}
            onClick={() => exportData()}
          >
            {rotIcons.pdf} <span style={{ paddingLeft: 5 }}>Export To PDF</span>
            {loading && (
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            )}
          </div>
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
