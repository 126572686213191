import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

function MultiSelectWithGrouping({
  groups = [],
  handleFilterChange,
  styleOverride = {},
  label = '',
  selectedValue = [],
}) {
  const [selectedItems, setSelectedItems] = useState(selectedValue || [])
  const [searchQuery, setSearchQuery] = useState('')

  // Handle selection changes
  const handleChange = (event, value) => {
    setSelectedItems(value) // Store the entire object (excluding children)
    setSearchQuery('') // Reset search query
  }

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    if (handleFilterChange) {
      handleFilterChange(selectedItems)
    }
  }, [selectedItems])

  useEffect(() => {
    if (!selectedValue || selectedValue.length === 0) {
      if (selectedItems.length !== 0) {
        setSelectedItems([]) // Only reset if there's something to clear
      }
    } else if (
      JSON.stringify(selectedItems) !== JSON.stringify(selectedValue)
    ) {
      setSelectedItems(selectedValue) // Only update if different
    }
  }, [selectedValue])

  // Toggle parent item selection
  const handleParentToggle = (group, children) => {
    const newSelectedItems = [...selectedItems]
    const parentIndex = newSelectedItems.findIndex(
      (item) => item.value === group.value
    )

    if (parentIndex === -1) {
      // Select the parent only if it's not already selected
      newSelectedItems.push({ ...group })

      // Select all children when the parent is selected
      children.forEach((child) => {
        selectChild(child, newSelectedItems)
      })
    } else {
      // Deselect the parent and all its children
      newSelectedItems.splice(parentIndex, 1)

      // Deselect all children when the parent is deselected
      children.forEach((child) => {
        deselectChild(child, newSelectedItems)
      })
    }

    setSelectedItems(newSelectedItems)
    setSearchQuery('') // Reset search query after selection
  }

  // Toggle child item selection
  const handleChildToggle = (child) => {
    const newSelectedItems = [...selectedItems]
    const index = newSelectedItems.findIndex(
      (item) => item.value === child.value
    )

    if (index === -1) {
      newSelectedItems.push({ ...child })
    } else {
      newSelectedItems.splice(index, 1)
    }

    setSelectedItems(newSelectedItems)
    setSearchQuery('') // Reset search query after selection
  }

  // Deselect child (recursive if there are subchildren)
  const deselectChild = (child, selectedItems) => {
    const index = selectedItems.findIndex((item) => item.value === child.value)
    if (index !== -1) selectedItems.splice(index, 1)

    if (child.children) {
      child.children.forEach((subChild) =>
        deselectChild(subChild, selectedItems)
      )
    }
  }

  // Select child (recursive if there are subchildren)
  const selectChild = (child, selectedItems) => {
    if (!selectedItems.some((item) => item.value === child.value)) {
      selectedItems.push({ label: child.label, value: child.value }) // Exclude children
    }

    if (child.children) {
      child.children.forEach((subChild) => selectChild(subChild, selectedItems))
    }
  }

  // Filter items based on search query
  const filterItems = (items) => {
    if (searchQuery) {
      return items.filter((item) => {
        return (
          item.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item.children &&
            item.children.some((child) =>
              child.label.toLowerCase().includes(searchQuery.toLowerCase())
            ))
        )
      })
    } else {
      return items
    }
  }

  // Render group (parent) item
  const renderGroup = (group, level = 0) => {
    const isParentSelected = selectedItems.some(
      (item) => item.value === group.value
    )

    return (
      <React.Fragment key={group.value}>
        <MenuItem
          style={{ marginLeft: `${level * 15}px` }} // Indentation based on recursion level
        >
          <Checkbox
            checked={isParentSelected} // Only check the parent if explicitly selected
            onChange={() => handleParentToggle(group, group.children)} // Toggle selection for the group
          />
          <ListItemText primary={group.label} />
        </MenuItem>

        {/* Recursively render children if any */}
        {group.children &&
          group.children.map((child) => renderChild(child, level + 1))}
      </React.Fragment>
    )
  }

  // Render child item
  const renderChild = (child, level = 1) => {
    return (
      <React.Fragment key={child.value}>
        <MenuItem
          style={{ marginLeft: `${level * 15}px` }} // Indentation for child items
        >
          <Checkbox
            checked={selectedItems.some((item) => item.value === child.value)} // Check if the child is selected
            onChange={() => handleChildToggle(child)} // Toggle selection for the child
          />
          <ListItemText primary={child.label} />
        </MenuItem>

        {/* Recursively render any sub-children */}
        {child.children &&
          child.children.map((subChild) => renderChild(subChild, level + 1))}
      </React.Fragment>
    )
  }

  const filteredItems = filterItems(groups) // Apply filtering logic

  return (
    <Box sx={{ width: 300, marginTop: 2, ...styleOverride }}>
      <Autocomplete
        multiple
        limitTags={2}
        options={filteredItems}
        value={selectedItems} // Set the entire object (without children) for selected items
        onChange={handleChange}
        getOptionKey={(option) => option.value}
        getOptionLabel={(option) => option.label} // Use the label to render the option in the dropdown
        isOptionEqualToValue={(option, value) =>
          option.label === value.label && option.value === value.value
        } // Compare objects by value
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <em style={{ color: '#909090', fontSize: '14px' }}>{label}</em> ||
              ''
            }
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
          />
        )}
        renderOption={(props, option) => {
          return (
            <React.Fragment key={option.value}>
              {renderGroup(option)}{' '}
              {/* Render the group and its children recursively */}
            </React.Fragment>
          )
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <Chip
                key={key}
                variant="outlined"
                label={option.label}
                size="small"
                {...tagProps}
                style={{ margin: '4px' }}
              />
            )
          })
        }
      />
    </Box>
  )
}

export default MultiSelectWithGrouping
