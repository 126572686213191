import React, { useEffect, useState } from "react";
import axios from "axios";
import Application from "../../config/ApplicationWrapper";
import PricingCard from "../../components/Pricing/PricingCard";
import PricingCardEnterprise from "../../components/Pricing/PricingCardEnterprise";
import PricingCardProject from "../../components/Pricing/PricingCardProject";
import "../../assets/styles/themes.scss";
import PricingHeaderLogo from '../../components/Pricing/PricingHeaderLogo.svg'
import PricingHeaderLogoPL from '../../components/Pricing//pl-logo2.svg'

const sortByPriceAscWithNullLast = (arr) => {
  return arr.sort((a, b) => {
    if (a.price === null) {
      return 1;
    }
    if (b.price === null) {
      return -1;
    }
    const priceA = parseFloat(a.price);
    const priceB = parseFloat(b.price);
    return priceA - priceB;
  });
};

const stripOutEnterprisePlan = (plans) => {
  const enterprisePlans = plans.filter(
    (plan) =>
      (plan.name === "Enterprise" || plan.name === "By Project") &&
      plan.show_on_pricing_page === true
  );

  const otherPlans = plans
    .filter((plan) => plan.name !== "Enterprise")
    .filter((plan) => plan.name !== "By Project")
    .filter((plan) => plan.show_on_pricing_page === true);

  return { enterprisePlans, otherPlans };
};

const PricingPage = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [enterprisePlans, setEnterprisePlans] = useState(null);

  // -------------------------------
  // 1. New states for currency & billing
  // -------------------------------
  const [currency, setCurrency] = useState("CAD"); // Default currency
  const [exchangeRate, setExchangeRate] = useState(0.70); // Example exchange rate for CAD -> USD
  const [billingCycle, setBillingCycle] = useState("monthly"); // "monthly" or "annual"

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const result = await axios.get(
          `${Application.api_url}/api/v2/subscription_plans`
        );

        if (result && result.data && result.data.data) {
          const sortedPlans = sortByPriceAscWithNullLast(result.data.data);
          const { enterprisePlans, otherPlans } = stripOutEnterprisePlan(
            sortedPlans
          );

          setSubscriptionPlans(otherPlans);
          setEnterprisePlans(enterprisePlans);
        } else {
          console.error("Cannot fetch subscription plans");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchSubscriptionPlans();
  }, []);

  // -------------------------------
  // 2. Handlers for toggles
  // -------------------------------
  const handleCurrencyToggle = () => {
    setCurrency((prev) => (prev === "CAD" ? "USD" : "CAD"));
  };

  const exchangeRates = {
    CAD: 1,
    USD: 0.70,
    EUR: 0.65,
    GBP: 0.60,
    AUD: 0.75,
    NZD: 0.78,
    JPY: 80,
    CHF: 0.64,
    CNY: 4.8,
    INR: 54,
    HKD: 5.5,
    SGD: 0.95,
  };

  // Map currency codes to their symbols
  const currencySymbols = {
    CAD: "$",
    USD: "$",
    EUR: "€",
    GBP: "£",
    AUD: "$",
    NZD: "$",
    JPY: "¥",
    CHF: "CHF",
    CNY: "¥",
    INR: "₹",
    HKD: "HK$",
    SGD: "S$",
  };


  const handleBillingCycleToggle = () => {
    setBillingCycle((prev) => (prev === "monthly" ? "annual" : "monthly"));
  };

  const convertPrice = (price) => {
    if (!price) return null;
    let finalPrice = parseFloat(price);
  
    // Convert currency using the appropriate exchange rate
    finalPrice *= exchangeRates[currency] || 1;
  
    // If annual, apply discount * 12
    if (billingCycle === "annual") {
      finalPrice = finalPrice  * 0.90;
    }
    return `${currencySymbols[currency]}${finalPrice.toFixed(0)}`;
  };

  return (
    <div className="home pricing-home" style={{paddingTop: "95px"}}>  
      <h1 style={{ fontSize: "30px" }}>Our Plans</h1>
      <p>
        <h3>More plans coming in late spring 2025.</h3>
        {/* <h3>By User, By Project -OR- <u>Flat Fee</u> monthly pricing. </h3> */}
        {/* //Now including ProjectLoop in your SafetyLoop subscription.</h3> */}
      </p>
      
      
      {/* 
        4. The toggles, placed together, right-aligned.
        For a quick pure-CSS toggle, you could use a
        switch-like approach with checkboxes, or 
        keep them as simple <button>s or <select>.
      */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "20px 0",
          gap: "20px",
        }}
      >
        {/* Currency Dropdown */}
      <div>
        <label style={{ marginRight: "8px", fontWeight: "bold" }}>
          Currency:
        </label>
        <select
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          style={{ padding: "4px 8px", fontSize: "14px" }}
        >
          <option value="CAD">CAD</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="AUD">AUD</option>
          <option value="NZD">NZD</option>
          <option value="JPY">JPY</option>
          <option value="CHF">CHF</option>
          <option value="CNY">CNY</option>
          <option value="INR">INR</option>
          <option value="HKD">HKD</option>
          <option value="SGD">SGD</option>
        </select>
      </div>

        {/* Billing Cycle Toggle */}
        <div>
          <label style={{ marginRight: "8px", fontWeight: "bold" }}>
            Billing:
          </label>
          <label className="switch">
            <input
              type="checkbox"
              checked={billingCycle === "annual"}
              onChange={handleBillingCycleToggle}
            />
            <span className="slider round"></span>
          </label>
          <span style={{ marginLeft: "8px" }}>
            {billingCycle === "monthly" ? "Monthly" : "Annual"}
          
          </span>
        </div>
      </div>

      <div style={{ textAlign: "center", fontSize: "14px" }}>
        {/* <a href="/">
         <img src={PricingHeaderLogo} alt='' />
        </a> */}
        {/* <span className="header-title" style={{fontSize:'36px', marginLeft:'20px', lineHeight:'60px', paddingTop:'20px'}}>+</span> */}
        {/* <a href="https://www.projectloop.com/?from=sl-pricing">
          <img src={PricingHeaderLogoPL} alt='' style={{height: '33px', marginLeft: '20px'}} />
        </a> */}
        <p>All prices currently in: <u>{currency}*</u>, excluding taxes (where applicable).</p>
        {/* <p>
          *Exchange rate: 1 CAD = {exchangeRate} USD (approximate)
        </p> */}
      </div>

      <div className="section__cards mx-auto">
        {subscriptionPlans.map((subscriptionPlan) => {
          return (
            subscriptionPlan.show_on_pricing_page && (
              <div key={subscriptionPlan.id} className="section__card">
                <PricingCard
                  id={subscriptionPlan.id}
                  name={subscriptionPlan.name}
                  description={subscriptionPlan.description}
                  price={convertPrice(subscriptionPlan.price)}
                  currency={currency}
                  interval={subscriptionPlan.interval}
                  intervalUnit={subscriptionPlan.interval_unit}
                  options={subscriptionPlan.options}
                  billingCycle={billingCycle}
                />
              </div>
            )
          );
        })}
      </div>

      <div className="row">
        <div className="section__cards__enterprise mx-auto">
          {enterprisePlans &&
            enterprisePlans.map((enterprisePlan) => {
              return enterprisePlan.name === "Enterprise" ? (
                <div
                  className="section__enterprise"
                  key={`${enterprisePlan.id}-enterprise`}
                >
                  <h2>By Project Plan</h2>
                  <div className="section__card">
                    <PricingCardEnterprise
                      id={enterprisePlan.id}
                      name={enterprisePlan.name}
                      description={enterprisePlan.description}
                      price={convertPrice(enterprisePlan.price)}
                      currency={currency}
                      interval={enterprisePlan.interval}
                      intervalUnit={enterprisePlan.interval_unit}
                      options={enterprisePlan.options}
                      billingCycle={billingCycle}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="section__enterprise"
                  key={`${enterprisePlan.id}-project`}
                >
                  <h2>By Project Plan</h2>
                  <div className="section__card">
                    <PricingCardProject
                      id={enterprisePlan.id}
                      name={enterprisePlan.name}
                      description={enterprisePlan.description}
                      price={convertPrice(enterprisePlan.price)}
                      currency={currency}
                      interval={enterprisePlan.interval}
                      intervalUnit={enterprisePlan.interval_unit}
                      options={enterprisePlan.options}
                      billingCycle={billingCycle}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* <div className="comparison-section">
        <h2 className="comparison-title">Compare plans and features</h2>
        <div className="comparison-table">
          <table>
            <thead>
              <tr>
                <th>Usage</th>
                <th>Free</th>
                <th>Basic</th>
                <th>Plus</th>
                <th>Professional</th>
                <th>Enterprise</th>
                <th>By Project</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Users</td>
                <td>2</td>
                <td>Pay per user</td>
                <td>Pay per user</td>
                <td>Pay per user</td>
                <td>Depends on Tier</td>
                <td>20 / project</td>
              </tr>
              <tr>
                <td>Groups / Projects</td>
                <td>1</td>
                <td>5</td>
                <td>15</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Form Templates</td>
                <td>3</td>
                <td>10</td>
                <td>20</td>
                <td>Unlimited</td>
                <td>Depends on Tier</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Forms Started Monthly</td>
                <td>30</td>
                <td>100</td>
                <td>300</td>
                <td>500</td>
                <td>Depends on Tier</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Document Storage Cap</td>
                <td>200mb</td>
                <td>1gb</td>
                <td>5gb</td>
                <td>15gb</td>
                <td>100gb</td>
                <td>10gb</td>
              </tr>
              <tr>
                <th>Safety management</th>
                <th>Free</th>
                <th>Basic</th>
                <th>Plus</th>
                <th>Professional</th>
                <th>Enterprise</th>
                <th>By Project</th>
              </tr>
              <tr>
                <td>Form Management</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Custom Forms</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>User management</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Documents / Files</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Training Management</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Reports and exports</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Mobile Access</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Actions</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Job Profiles</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Divisions</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Basic Analytics</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Document Matrix</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Asset Management</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>QR Codes</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Flagged Users</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>TODO Access</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Subcontractor Management</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Risk Assessments</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Actions</td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Emergency Alerts</td>
                <td></td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>External Forms</td>
                <td></td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>AuditSoft Integration</td>
                <td></td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Inventory</td>
                <td></td>
                <td></td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Time Tracking</td>
                <td></td>
                <td></td>
                <td></td>
                <td>✔️</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <p className="home__description">
        {`At SafetyLoop, Our goal is to help you simplify your occupational health & safety management system
        by using our ecosystem, custom forms & tools, and monitor in real-time today.`}
      </p>
    </div>
  );
};

export default PricingPage;