import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import * as React from 'react'

export default function CustomDateRangeFilter({
  value,
  fieldName,
  handleFiledChange,
}) {
  const onFiledChange = (newValue) => {
    const dateValue = dayjs(newValue).format('MM-DD-YYYY')
    handleFiledChange(dateValue, fieldName)
  }
  const fieldValue = value ? dayjs(value) : null
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{ padding: 0 }} components={['DatePicker']}>
        <DatePicker
          className="table-date-picker"
          value={fieldValue}
          format="MM-DD-YYYY"
          onChange={(newValue) => onFiledChange(newValue, fieldName)}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
