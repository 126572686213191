import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import SubTabs from '../../../shared/components/subTabs'
import ApprovedList from './ApprovedList'
import DeclinedList from './DeclinedList'
import WaitingList from './WaitingList'
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { actionIcons } from '../../../Actions/ActionIcons'
import KanbanView from './KanbanView'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'

const subTabs = {
  wap: [
    {
      label: 'Waiting',
      value: 'waiting',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Declined',
      value: 'declined',
    },
  ],
}

const ApprovalWrapper = ({ active, rotCount = {} }) => {
  const [view, setView] = useState('list');
  const [boardState, setBoardState] = useState({
    searchText: undefined,
    page: 1,
  })
  useEffect(() => {
    setBoardState({
      searchText: undefined,
      page: 1,
    });
  }, [view])
  const inputRef = useRef()
  return (
    <>
      <Stack direction="row" justifyContent="space-between" style={{ paddingBlock: 5, borderBottom: '1px solid #D3D3D3' }}>
        <Typography sx={{ fontWeight: 500 }} variant="body1">{view === 'list' ? 'List View' : 'Kanban View'}</Typography>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          {view === 'board' && <TextField
            size="small"
            inputRef={inputRef}
            className="search-input"
            placeholder="Search Topic"
            value={boardState.searchText || undefined}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setBoardState((prev) => ({
                  ...prev,
                  searchText: event.target.value || '',
                }))
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!boardState.searchText ? (
                    <IconButton
                      onClick={() => {
                        const value = inputRef.current.value || ''
                        setBoardState((prev) => ({
                          ...prev,
                          searchText: value,
                        }))
                      }}
                      edge="end"
                    >
                      <img src={SearchIcon} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setBoardState((prev) => ({ ...prev, searchText: '' }))
                        inputRef.current.value = ''
                      }}
                      edge="end"
                    >
                      {UserGroupIcons.clearInput}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />}
          {view === 'board' ? <Stack direction="row">
            <div style={{ cursor: 'pointer' }} onClick={() => setView('list')}>
              {actionIcons.listView}
            </div>
            <div style={{ cursor: 'pointer' }}>
              {actionIcons.boardViewSelected}
            </div>
          </Stack> : <Stack direction="row">
            <div style={{ cursor: 'pointer' }}>
              {actionIcons.listViewSelected}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setView('board')}>
              {actionIcons.boardView}
            </div>
          </Stack>}
        </Stack>
      </Stack>
      {view === 'list' ? <SubTabs
        tabList={subTabs[active] || []}
        pageCount={rotCount.wap}
      >
        {(activeKey) => (
          <>
            {activeKey === 'waiting' && <WaitingList />}
            {activeKey === 'approved' && <ApprovedList />}
            {activeKey === 'declined' && <DeclinedList />}
          </>
        )}
      </SubTabs> : <KanbanView boardState={boardState} setBoardState={setBoardState} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}

export default connect(mapStateToProps)(ApprovalWrapper)
