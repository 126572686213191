import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { formatBytes } from '../../../utils/util'
import { actionIcons } from '../../Actions/ActionIcons'
import { rotIcons } from '../../ROT_v2/rotIcons'
import { UserGroupIcons } from './userGroupIcons'

const AttachmentCard = ({ file, handleDeleteFile }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={6} key={file.id}>
        <Box
          sx={{
            border: '1px solid #ececec',
            borderRadius: '4px',
            boxShadow: 'none',
            background: '#F6F6F6',
            padding: '8px',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              {rotIcons.success}
              {actionIcons.png}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#909090',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  {file.file_name || file.name}
                </Typography>
                <span>{formatBytes(file.size || 0)}</span>
              </div>
            </Stack>
            <div
              style={{
                color: '#407797',
                fontWeight: 500,
                fontSize: '13px',
                cursor: 'pointer',
              }}
              variant="body2"
              onClick={() => handleDeleteFile(file)}
            >
              {UserGroupIcons.delete}
            </div>
          </Stack>
        </Box>
      </Grid>
    </>
  )
}

export default AttachmentCard
