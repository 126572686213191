import {
  Autocomplete,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AlertDialog from '../../../shared/components/alretDialog'
import { ChipList } from '../../../shared/components/ChipList'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { HeaderDetails } from '../../../shared/components/viewPageHeader'
import { rotIcons } from '../../rotIcons'

const TableWrapper = styled('div')`
  .custom_table_container {
    max-height: calc(100vh - 333px) !important;
  }
`

const UsersTabList = ({ setSelectedUser, selectedUser, users }) => {
  return (
    <Box style={{ height: 'calc(100vh - 70px)', overflowY: 'auto' }}>
      <Box style={{ background: '#E0F0F9', padding: 8 }}>
        <Autocomplete
          options={users}
          disableClearable
          value={selectedUser}
          size="small"
          getOptionLabel={(option) => option.key}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              style={{ background: '#fff', border: 0 }}
            />
          )}
          onChange={(event, newValue) =>
            setSelectedUser({ selectedUser: newValue, page: 1, limit: 20 })
          }
        />
      </Box>
      <List>
        {users.map((user, index) => (
          <ListItem
            button
            key={index}
            onClick={() =>
              setSelectedUser({ selectedUser: user, page: 1, limit: 20 })
            }
            style={{
              background:
                selectedUser.username === user.username ? '#ECF1F5' : '#fff',
              borderBottom: '1px solid #ECECEC',
            }}
          >
            <ListItemText primary={user.key} secondary={user.email} />
            <ListItemIcon style={{ minWidth: 'auto' }}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10.6665L5.66667 5.99984L1 1.33317"
                  stroke="#909090"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const EmployeeDetailAndTainingList = ({
  selectedUser,
  setEdit,
  setPagination,
  pagination,
}) => {
  const [deleteItem, setDeleteItem] = useState({ isDelete: false })
  const [alertOpen, setAlertOpen] = useState(false)
  const {
    data: responseData = {},
    isLoading,
    isFetching,
    refetch,
  } = useFetch(
    `/api/v2/entity_management/users/${selectedUser.value}/load_user_trainings?by_employee_trainings=true&page=${pagination.page}&limit=${pagination.limit}`
  )
  const { mutate, isLoading: isDeleting } = usePatch()

  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }
  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose, isDelete: true })
    setAlertOpen(true)
  }

  const handleOk = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully archived.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/archive`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  const handleDeleteConfirm = (row, setClose) => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Participant successfully deleted.' })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_participants/${deleteItem.id}/soft_delete`
    )
    if (alertOpen) {
      setDeleteItem({ isDelete: false })
      setAlertOpen(false)
    }
  }
  if (isLoading || isFetching) {
    return <Loader />
  }
  const { data = [], metadata = {} } = responseData || {}
  const headers = [
    <TableCell>
      <HeaderItem title="Training Name" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Date Completed" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Expiration Date" />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Status" />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      training_topic_name = '',
      completion_date = '',
      expiry_date = '',
      training_status = '',
    } = ele || {}
    return (
      <TableRow
        key={index}
        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
      >
        <TableCell>{training_topic_name}</TableCell>
        <TableCell>{completion_date}</TableCell>
        <TableCell>{expiry_date}</TableCell>
        <TableCell>
          <div
            style={{
              background: training_status === 'Active' ? '#E5F8E9' : '#FFEAEA',
              color: training_status === 'Active' ? '#00BA25' : '#D92929',
              padding: 5,
              borderRadius: 25,
              textAlign: 'center',
              maxWidth: '70px',
            }}
          >
            {training_status}
          </div>
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <>
            {' '}
            <ActionMenu>
              {(setClose) => {
                const menuItems = []
                menuItems.push(
                  <MenuItem>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault() // Prevents default navigation
                        window.open(
                          ele.pdf_link,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.view}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          View PDF
                        </span>
                      </Box>
                    </Link>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.edit}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Edit
                      </span>
                    </Box>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleRestore(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.archive}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Archive
                      </span>
                    </Box>
                  </MenuItem>
                )
                menuItems.push(
                  <MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.delete}{' '}
                      <span style={{ paddingLeft: 5, color: '#D92929' }}>
                        Delete
                      </span>
                    </Box>
                  </MenuItem>
                )
                return menuItems
              }}
            </ActionMenu>
          </>
        </TableCell>
      </TableRow>
    )
  })
  return (
    <TableWrapper>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={deleteItem.isDelete ? handleDeleteConfirm : handleOk}
        title={`${deleteItem.isDelete ? 'Delete' : 'Archive'} Participant(s)`}
        description={`Are You sure you want to ${
          deleteItem.isDelete ? 'Delete' : 'Archive'
        } this Participant(s)?`}
        okText={`${deleteItem.isDelete ? 'Delete' : 'Archive'}`}
      />
      <HeaderDetails
        overrideStyle={{ background: '#F6F6F6', alignItems: 'center' }}
        items={[
          {
            label: '',
            value: (
              <span
                style={{
                  color: '#080808',
                  fontSize: '20px',
                  fontWeight: '700',
                }}
              >
                {selectedUser.key}
              </span>
            ),
            width: 'w-auto',
            noBr: true,
          },
          {
            label: '',
            value: (
              <span
                style={{
                  background: '#ececec',
                  borderRadius: '15px',
                  padding: '8px 13px',
                }}
              >
                <span style={{ color: '#5c5c5c' }}>Emp ID :</span>{' '}
                {`${selectedUser.employee_id}`}
              </span>
            ),
            width: 'w-auto',
            noBr: true,
          },
          {
            label: '',
            value: (
              <span
                style={{
                  background: '#ececec',
                  borderRadius: '15px',
                  padding: '8px 13px',
                }}
              >
                <span style={{ color: '#5c5c5c' }}>Email ID :</span>{' '}
                {`${selectedUser.email || '-'}`}
              </span>
            ),
            width: 'w60',
            noBr: true,
          },
          {
            isDivider: true,
          },
          {
            label: 'Job Profile',
            // value: selectedUser.job_profile_names.map((v) => (
            //   <span
            //     style={{
            //       background: '#ececec',
            //       borderRadius: '15px',
            //       padding: '8px 13px',
            //     }}
            //   >
            //     {v}
            //   </span>
            // )),
            value: <ChipList chips={selectedUser.job_profile_names} />,
            width: 'w25',
          },
          {
            label: 'Role',
            value: '',
            width: 'w25',
          },
          {
            label: 'Username',
            value: selectedUser.username,
            width: 'w25',
          },
          {
            label: 'Employee Notes',
            value: selectedUser.notes,
            width: 'w25',
            noBr: true,
          },
        ]}
      />
      {!data.length ? (
        <Box
          style={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {rotIcons.no_data}
        </Box>
      ) : (
        <CustomTable
          headers={headers}
          rows={rows}
          renderActiveFilter={false}
          isSliderScroll={false}
        />
      )}

      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={pagination.page}
          handlePageChange={(_, nextPage) => {
            setPagination((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={pagination.limit}
          handleRowPerPageChange={(targetValue) => {
            setPagination((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
    </TableWrapper>
  )
}

const ByEmployeeList = ({ initialSelectedUser, users, setEdit }) => {
  const [listState, setListState] = useState({
    selectedUser: initialSelectedUser,
    page: 1,
    limit: 20,
  })
  return (
    <div
      style={{ padding: 8, border: '1px solid #D3D3D3', background: '#fff' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <UsersTabList
            users={users}
            selectedUser={listState.selectedUser}
            setSelectedUser={setListState}
          />
        </Grid>
        <Grid item xs={9}>
          <EmployeeDetailAndTainingList
            selectedUser={listState.selectedUser}
            setEdit={setEdit}
            setPagination={setListState}
            pagination={{ page: listState.page, limit: listState.limit }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const ByEmployeeListWrapper = ({ setEdit }) => {
  const { data: responseData = {}, isLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/load_company_users'
  )
  if (isLoading) {
    return <Loader />
  }
  const { users = [] } = responseData || {}
  const initialSelectedUser = users[0] || {}
  if (!initialSelectedUser.value) {
    return <>No Data</>
  }
  return (
    <ByEmployeeList
      users={users}
      initialSelectedUser={initialSelectedUser}
      setEdit={setEdit}
    />
  )
}
export default ByEmployeeListWrapper
