import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { actionIcons } from '../../../Actions/ActionIcons'
import FormWrapper from '../../../shared/components/formWrapper'
import {
  ShowNotification,
  showToast,
} from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import { rotIcons } from '../../rotIcons'
import { TrainingRecordForm } from './TrainingRecordForm'
const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55%',
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '12px',
  p: 4,
  '.action_status': {
    padding: '5px 12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '50px',
    fontSize: '14px',
    marginLeft: '8px',
  },
  '.to_do': {
    color: '#2A70D9',
    background: '#EBF3FF',
  },
  '.in_progress': {
    color: '#D69B25',
    background: '#FFF8EA',
  },
  '.completed': {
    color: '#009E1F',
    background: '#E5F8E9',
  },
  '.rejected': {
    color: '#e85342',
    background: '#ffdfdc',
  },
}

const NewTrainingRecord = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  refetch,
  setFilterState,
  isMutating,
  optimisticUpdate,
  handleDeleteFile,
}) => {
  const isEdit = !!initialValues
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [formValues, setFormData] = useState(null)
  const [notification, setNotification] = useState({
    open: false,
    message: 'test',
    severity: 'success',
  })
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/rot_management/record_of_trainings/base_data?create_rot=true'
  )

  const { topics = [], company_users = [] } = baseData || {}

  function formatDate(dateString) {
    const [month, day, year] = dateString.split('/')
    if (month && day && year) {
      return `${year}-${month}-${day}` // Convert to YYYY-MM-DD
    }
    throw new Error('Invalid date format')
  }
  const defaultValues = isEdit
    ? {
        ...initialValues,
        date_of_training:
          (initialValues.completion_date &&
            formatDate(initialValues.completion_date)) ||
          '',
        expiry_date:
          (initialValues.expiry_date &&
            formatDate(initialValues.expiry_date)) ||
          '',
      }
    : {
        training_topics: '',
        training_participants: '',
        date_of_training: '',
        expiry_date: '',
        files: [],
        new_schedule: false,
      }
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onSubmit = (data) => {
    setFormData(data)
    isEdit ? setConfirmOpen(true) : handleConfirmSubmit(false)
  }

  const handleConfirmSubmit = (isRenew = false) => {
    if (!formValues) return
    let data = formValues
    setConfirmOpen(false)
    const formData = new FormData()
    if (isEdit) {
      formData.append(
        'training_participant[date_of_training]',
        data.date_of_training || ''
      )
      formData.append(
        'training_participant[expiry_date]',
        data.expiry_date || ''
      )
      isRenew && formData.append('training_participant[renewal_training]', true)
      data.files &&
        Object.values(data.files).forEach((file) => {
          formData.append('training_participant[files][]', file)
        })
    } else {
      data.training_topics &&
        data.training_topics.map((topic) => {
          formData.append(
            'training[training_schedule][training_topics][]',
            topic.value || ''
          )
        })
      data.training_participants &&
        data.training_participants.map((participant) => {
          formData.append(
            'training[training_schedule][training_participants][]',
            participant.value || ''
          )
        })
      formData.append(
        'training[training_schedule][date_of_training]',
        data.date_of_training || ''
      )
      formData.append(
        'training[training_schedule][expiry_date]',
        data.expiry_date || ''
      )
      data.files &&
        Object.values(data.files).forEach((file) => {
          formData.append('training[training_schedule][files][]', file)
        })
    }
    const payload = formData
    if (!isEdit) {
      mutate(payload, {
        onSuccess: (responseData) => {
          showToast({ message: 'Created Successfully' })
          setOpen(false)
          if (refetch) {
            refetch()
          }
          if (optimisticUpdate && responseData && responseData.data) {
            optimisticUpdate(responseData.data, 'create')
          }
          setFilterState({ page: 1, limit: 20 })
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      })
    } else {
      mutate(
        payload,
        {
          onSuccess: (responseData) => {
            showToast({ message: 'Updated Successfully' })
            setOpen(false)
            if (refetch) {
              refetch()
            }
            if (optimisticUpdate && responseData && responseData.data) {
              optimisticUpdate(responseData.data, 'edit')
            }
          },
          onError: (e) => {
            showToast({
              type: 'error',
              message: typeof e === 'string' ? e : e.errors.toString() || '',
            })
          },
        },
        `/api/v2/rot_management/training_participants/${initialValues.id}`
      )
    }
  }

  if (baseDataLoading) {
    return null
  }

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })) // Hide notification, retain other state values
  }

  return (
    <>
      <Dialog
        open={confirmOpen}
        onClose={null}
        disableEscapeKeyDown
        sx={{ padding: '25px' }}
      >
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to renew training or update existing training ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="dialog-action-btn exist-btn"
            onClick={() => handleConfirmSubmit(false)}
          >
            Update Existing
          </Button>
          <Button
            className="dialog-action-btn renew-btn"
            onClick={() => handleConfirmSubmit(true)}
            variant="contained"
          >
            Renew
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        className="popup-title"
        onClick={handleOpen}
      >
        {actionIcons.add} Add Training
      </Button>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        disableEscapeKeyDown
        BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      >
        <FormWrapper>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={closeButtonStyle}
            >
              {rotIcons.close}
            </IconButton>
            <Typography
              className="popup-title"
              id="modal-title"
              variant="h6"
              component="h2"
            >
              {isEdit ? 'Edit' : 'New '} Training{' '}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 2 }}>
                <TrainingRecordForm
                  fileDetails={
                    (isEdit &&
                      initialValues.attachments &&
                      initialValues.attachments) ||
                    {}
                  }
                  isEdit={isEdit}
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  topics={topics}
                  company_users={company_users}
                  initialValues={initialValues}
                  handleDeleteFile={handleDeleteFile}
                  getValues={getValues}
                  watch={watch}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#ECECEC',
                      color: '#5C5C5C',
                      marginRight: '10px',
                      outline: 'none',
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#407797',
                      color: '#fff',
                      outline: 'none',
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    disabled={isMutating}
                    endIcon={
                      isMutating ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : null
                    }
                    color="primary"
                  >
                    {isEdit ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  )
}

export default NewTrainingRecord
