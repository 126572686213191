import React, { Component, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./Layout";
import SideBar from "../../components/SideBar";
// import InteralSubHeader from "../../components/InteralSubHeader";
import NavBar from "../../components/NavBar";
import HomeFooter from "../../pages/HomePage/Footer";
import PricingHeader from "../../components/Pricing/PricingHeader";
import PricingFooter from "../../components/Pricing/PricingFooter";
import PricingFullbackground from "../../components/Pricing/pricingback.jpg";
import CheckoutHeader from "../../components/Checkout/CheckoutHeader";
import InternalSubHeader from "../../components/NavBar/InternalSubHeader";
import InteralSubSidebar from "../../components/NavBar/InternalSubSidebar";
import Cookies from "js-cookie";
import SideMenu from "../../components/SideNav";
import { styled } from "@mui/material";
import SideNav from "../../components/SideNav";

export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

export const PrivateRoute = ({
  component: Component,
  is_authenticated: is_authenticated,
  layout: Layout,
  disableSideBar = false,
  enableNewLayout = false,
  ...rest
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let path = "/login";
  if (user) {
    const isInternalUser = user.is_internal_user;
    is_authenticated = isInternalUser ? false : is_authenticated;
    path = "/internalsub_dashboard";
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        is_authenticated === true ? (
          <Layout disableSideBar={disableSideBar}>
            {" "}
            <div className={`${!disableSideBar ? '' : 'msmcontainer'} dashboard-container`}>
              <div className="row d-flex">
              {enableNewLayout ? null : !disableSideBar && <SideNav /> }
                <Component {...props} />{" "}
              </div>
            </div>
          </Layout>
        ) : (
          <Redirect to={{ pathname: path, state: { from: props.location } }} />
        )
      }
    />
  );
};

export const InternalSubRoute = ({
  component: Component,
  is_authenticated: is_authenticated,
  layout: Layout,
  ...rest
}) => {
  let auth = Cookies.get("token");
  is_authenticated = auth ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        is_authenticated === true ? (
          <Layout>
            {" "}
            <Component {...props} />{" "}
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const AuthorizeRoute = ({
  component: Component,
  is_authenticated: is_authenticated,
  is_authorized: is_authorized,
  layout: Layout,
  ...rest
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let path = "/login";
  if (user) {
    const isInternalUser = user.is_internal_user;
    is_authenticated = isInternalUser ? false : is_authenticated;
    path = "/internalsub_dashboard";
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        is_authenticated === true && is_authorized === true ? (
          <Layout>
            {" "}
            <Component {...props} />{" "}
          </Layout>
        ) : is_authenticated === true ? (
          <Redirect
            to={{
              pathname: "/analytics-dashboard",
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect to={{ pathname: path, state: { from: props.location } }} />
        )
      }
    />
  );
};

export const PricingLayout = (props) => {
  return (
    <div className="pricing">
      <div
        className="bg-header-home"
        style={{
          // backgroundImage: `url(${PricingFullbackground})`,
        }}
      >
        <PricingHeader />
        {props.children}
      </div>
      <PricingFooter />
    </div>
  );
};

export const CheckoutLayout = (props) => {
  return (
    <div className="checkoutPage">
      <CheckoutHeader />
      {props.children}
    </div>
  );
};

export const HomeLayout = (props) => (
  <div>
    <Layout />
    {props.children}
    <HomeFooter />
  </div>
);

export const FeatureLayout = (props) => (
  <div>
    <Layout />
    <div style={{ width: "100%", paddingTop: "80px" }}>{props.children}</div>
    <HomeFooter />
  </div>
);

export const LoginLayout = (props) => <div>{props.children}</div>;

export const AppLayout = (props) => (
  <div>
    {/* <SideBar /> */}
    <NavBar />

    {props.children}
  </div>
);

const LayoutWrapper = styled("div")({
  display: "flex",
  overflow: "hidden",
  width: "100%",
  ".layout-main": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  ".navbar": {
    position: "sticky !important",
    marginLeft: "0px !important",
    marginBottom: "0px",
  },
  ".sidebar": {
    position: "static",
    overflowX: "hidden",
    height: "100vh",
    minHeight: "unset",
  },
  ".sidebar-collapse": {
    paddingLeft: 0,
  },
  ".layout-content": {
    width: "100%",
    overflowY: "auto",
    overflowX: 'hidden',
    height: "calc(100vh - 70px)",
  },
  ".header-logo-container": {
    position: "static",
    padding: "0px 10px 12px",
  },
  ".nav-company": {
    width: "100%",
    margin: 0,
  },
  ".menu-item": {
    position: "static",
  },
  ".sb-text": {
    position: "static !important",
    marginLeft: "0px !important",
  },
  ".new-tag": {
    marginLeft: "20px",
  },
  ".main": {
    overflow: "hidden",
    flex: 1,
  },
});

export const AuthLayout = ({ children, disableSideBar }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  return (
    <LayoutWrapper>
      {!disableSideBar && <SideMenu />}
      <div className="main">
        <div className="layout-main">
          <NavBar />
          <div className="layout-content">{children}</div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export const InternalSubLayout = (props) => (
  <div>
    <InternalSubHeader />
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <InteralSubSidebar />
        </div>
        <div className="col-md-10">{props.children}</div>
      </div>
    </div>
  </div>
);

export const InternalSubMobileLayout = (props) => (
  <div>
    <InternalSubHeader />
    <div className="container-fluid Internal-sub-mobile-Layout">
      <div className="row">
        <div className="col-md-12">{props.children}</div>
      </div>
    </div>
  </div>
);
