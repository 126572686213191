import React from 'react';
import { Button } from 'react-bootstrap';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';

import Company from "../../../../javascript/assets/fonts/companies.svg";
import Users from "../../../../javascript/assets/fonts/users.svg";
import Inspection from "../../../../javascript/assets/fonts/inspection.svg";

export default function NumberStatsSection() {
    return (
        <div className="feature-highlight-section">
            <ScrollAnimation
                animateIn="animate__fadeIn"
                duration={1.5}
                animateOnce={true}
                delay={0}
            >
                <div className="feature-highlight-container" style={{ marginBottom: "360px" }}>
                    <div style={{ maxWidth: "650px", marginBottom: "70px" }}>
                        <h2 className="feature-highlight-text">SafetyLoop delivers on promise.</h2>
                    </div>
                    <div className="feature-highlight-grid">
                        <div className="feature-card">
                            <img className='number-stats-image' src={Company} />
                            <h2 className="title number-stats-count"><CountUp end={850} enableScrollSpy={true} scrollSpyOnce={true} />+</h2>
                            <p className='sub-text'>General Contractors & Trades</p>
                        </div>
                        <div className="feature-card">
                            <img className='number-stats-image' src={Users} />
                            <h2 className="title number-stats-count"><CountUp end={50000} decimals={0} enableScrollSpy={true} scrollSpyOnce={true} />+</h2>
                            <p className='sub-text'>Users & growing</p>
                        </div>
                        <div className="feature-card">
                            <img className='number-stats-image' src={Inspection} />
                            <h2 className="title number-stats-count"><CountUp end={1000000} decimals={0} enableScrollSpy={true} scrollSpyOnce={true} />+</h2>
                            <p className='sub-text'>Inspections completed</p>
                        </div>
                        <div className="feature-card">
                            <img className='number-stats-image' src={Inspection} />
                            <h2 className="title number-stats-count"><CountUp end={20000} decimals={0} enableScrollSpy={true} scrollSpyOnce={true} />+</h2>
                            <p className='sub-text'>Projects</p>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    )
}
