import React, { useState } from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { GlobalProjectForm } from "./GlobalProjectForm";
import useFetch from "../../../shared/components/userFetch";
import FormWrapper from "../../../shared/components/formWrapper";
import { ShowNotification, showToast } from "../../../shared/components/showNotification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
  borderRadius: "12px",
  p: 4,
};

const NewGlobalProject = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  setFilterState,
}) => {
  const isEdit = !!initialValues;
  //activated_eq=true create_job_profile=true

  
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    "/api/v2/entity_management/global_projects/base_data?update_global_project=true"
  );

  const {
    companies = [],
    member_users = [],
    manager_users = [],
  } = baseData || {};
  const defaultValues = isEdit
    ? {
        ...initialValues,
        invite_company: initialValues.invite_company,
        group_managers: initialValues.manager_users,
        group_members: initialValues.member_users,
        group_companies: initialValues.group_companies,
      }
    : {
        name: "",
        companies: [],
        groups: [],
        managers: [],
      };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    const payload = {
      group: {
        group_managers: data.group_managers.map((item) => item.value),
        group_members: data.group_members.map((item) => item.value),
        invite_company: data.invite_company ? 1 : 0,
        group_companies: data.group_companies.map((item) => item.value),
        shared: "shared",
      },
    };

    const formData = new FormData();

    formData.append("group[invite_company]", payload.group.invite_company);
    formData.append("group[shared]", payload.group.shared);

    // Append array data by iterating through each array
    const appendGroupData = (groupKey, values) => {
      if (values && values.length > 0) {
        values.forEach((value) => {
          formData.append(`group[${groupKey}][]`, value);
        });
      } else {
        formData.append(`group[${groupKey}][]`, []); // Append empty array if no values
      }
    };
    
    // Use the helper function for each group
    appendGroupData('group_managers', payload.group.group_managers);
    appendGroupData('group_members', payload.group.group_members);
    appendGroupData('group_companies', payload.group.group_companies);    

    mutate(
      formData,
      {
        onSuccess: () => {
          showToast({ message: "Successfully joined the Global Project!" });
          setFilterState({ page: 1, limit: 20 });
          handleClose(); // Close the modal after submission
        },
      },
      `/api/v2/entity_management/global_projects/${data.id}`
    );
  };

  if (baseDataLoading) {
    return null;
  }
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Job Profile
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography
              id="modal-title"
              className="popup-title"
              variant="h6"
              component="h2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_9749_51883)">
                  <path
                    d="M12 9.33301H7.99998C7.29274 9.33301 6.61446 9.61396 6.11436 10.1141C5.61426 10.6142 5.33331 11.2924 5.33331 11.9997V23.9997C5.33331 24.7069 5.61426 25.3852 6.11436 25.8853C6.61446 26.3854 7.29274 26.6663 7.99998 26.6663H20C20.7072 26.6663 21.3855 26.3854 21.8856 25.8853C22.3857 25.3852 22.6666 24.7069 22.6666 23.9997V19.9997"
                    stroke="#080808"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 19.9997H16L27.3333 8.66632C27.8638 8.13589 28.1618 7.41646 28.1618 6.66632C28.1618 5.91617 27.8638 5.19675 27.3333 4.66632C26.8029 4.13588 26.0835 3.83789 25.3333 3.83789C24.5832 3.83789 23.8638 4.13588 23.3333 4.66632L12 15.9997V19.9997Z"
                    stroke="#080808"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.3333 6.66699L25.3333 10.667"
                    stroke="#080808"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9749_51883">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>{" "}
              Edit Shared Groups
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: "calc(100% - 50px)" }}
            >
              <Box
                sx={{ mt: 2 }}
                style={{ overflowY: "scroll", height: "calc(100% - 40px)" }}
              >
                <GlobalProjectForm
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  companies={companies}
                  groups={member_users}
                  managers={manager_users}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ECECEC",
                      color: "#5C5C5C",
                      marginRight: "10px",
                      outline: "none",
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#407797",
                      color: "#fff",
                      outline: "none",
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    color="primary"
                  >
                    Update
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default NewGlobalProject;
