import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { showToast } from '../../../../shared/components/showNotification'
import usePatch from '../../../../shared/components/usePatch'
import { actionIcons } from '../../../ActionIcons'
import PDFViewer from '../PDFViewer'
import SignatureModal from './SignatureModal'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .participant-table': {
    '& tr': {
      backgroundColor: 'inherit',
    },
    '& tr:nth-child(4n-3)': {
      backgroundColor: '#ffffff',
    },
    '& tr:nth-child(4n-1)': {
      backgroundColor: '#F5FCFF',
    },
  },
}))

const StyledTableHeadCell = styled(TableCell)(({ theme, open }) => ({
  backgroundColor: '#f6f6f6',
  '&:hover': {
    backgroundColor: '#f6f6f6',
  },
  ':last-child': {
    maxWidth: '300px',
  },
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  padding: '7px',
  fontFamily: 'Inter',
  borderBottom: '1px solid #ECECEC',
  '.action_status': {
    padding: '8px 16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '50px',
    marginTop: '15px',
  },
  '.to_do': {
    color: '#2A70D9',
    background: '#EBF3FF',
  },
  '.in_progress': {
    color: '#D69B25',
    background: '#FFF8EA',
  },
  '.completed': {
    color: '#009E1F',
    background: '#E5F8E9',
  },
  '.rejected': {
    color: '#e85342',
    background: '#ffdfdc',
  },
}))
const ActionDetailsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin-block: 16px;
  border-radius: 8px;
  padding: 8px;
  width: fit-content;
  .d-box {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc;
    padding: 0 20px 0 10px;
    &:last-child {
      border-right: none;
    }
  }
  ,
  .d-label,
  .value {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.084px;
    color: #909090;
    padding: 0.5em;
  }
  .value {
    color: #080808;
  }
`

function Row({ row, onClickAddSignature, onChangeStatus }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <StyledTableCell scope="row">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? actionIcons.arrowDown : actionIcons.arrowUp}
            </IconButton>
            <span>{row.full_name}</span>
          </div>
        </StyledTableCell>
        <StyledTableCell>{row.company_name}</StyledTableCell>
        <StyledTableCell>
          <span className={`action_status ${row.status}`}>
            {row.status_label}
          </span>
        </StyledTableCell>
        <StyledTableCell style={{ width: '400px', maxWidth: '400px' }}>
          <div
            className="d-flex"
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={row.status}
                onChange={(e) => onChangeStatus(e.target.value, row)}
              >
                {['to_do'].includes(row.status) && (
                  <MenuItem value={'to_do'}>To Do</MenuItem>
                )}
                <MenuItem value={'in_progress'}>In Progress</MenuItem>
                <MenuItem value={'completed'}>Completed</MenuItem>
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#65b5e3' }}
              onClick={() => {
                onClickAddSignature(row)
              }}
            >
              Add Signature
            </Button>
          </div>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: '#F6F6F6' }}
          colSpan={5}
        >
          <Collapse
            in={open}
            style={{ padding: '12px 7px' }}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom>
                Action Details
              </Typography>
              <ActionDetailsWrapper>
                <div className="d-box">
                  <span className="d-label">Name</span>
                  <span className="value">{row.full_name}</span>
                </div>
                <div className="d-box">
                  <span className="d-label">Time</span>
                  <span className="value">{row.time || '-'}</span>
                </div>
                <div className="d-box">
                  <span className="d-label">Signature</span>
                  <span className="value">
                    {row.signature_url ? (
                      <img
                        src={row.signature_url}
                        alt="signature"
                        style={{ width: 'auto', height: '35px' }}
                      />
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              </ActionDetailsWrapper>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  )
}

export default function AuditActionParticipants({
  data = [],
  id,
  callRefetch,
  page,
  pdf_link,
  display_audit_trail,
}) {
  const [signaturePopup, setSignaturePopup] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [ratId, setRatId] = React.useState('')
  const [tableData, setTableData] = React.useState(data || [])
  const basePath = `/api/v2/actions_management`
  const type = page === 'asset' ? 'asset_actions' : 'actions'
  const actionNotesType = page === 'asset' ? 'asset' : 'audit'

  const apiEndpoint = `${basePath}/${type}/${id}/update_${actionNotesType}_action_status`
  const { mutate, isLoading: isMutating, error } = usePatch(apiEndpoint)
  const { mutate: auditMutate, isLoading: isAuditMutating } = usePatch('')

  useEffect(() => {
    if (data.length > 0) {
      setTableData(data)
    }
  }, [data])

  const handleChange = (e) => {
    auditMutate(
      {
        audit_action: { display_audit_trail: e.target.checked },
      },
      {
        onSuccess: () => {
          showToast({ message: 'Append Audit Trail Notes Updated' })
          callRefetch()
        },
      },
      `/api/v2/actions_management/actions/${id}/update_audit_trail`
    )
  }

  const onClickAddSignature = async (data) => {
    setStatus('completed')
    setRatId(data.id)
    setSignaturePopup(true)
  }
  const onChangeStatus = async (status, data) => {
    setStatus(status)
    setRatId(data.id)
    const statusObj = {
      in_progress: 'In Progress',
      to_do: 'To Do',
      completed: 'Completed',
      rejected: 'Rejected',
    }
    tableData.map((row) => {
      if (row.id === data.id) {
        row.status = status
        row.status_label = statusObj[status]
      }
      return row
    })
    setTableData([...tableData])

    const formData = new FormData()
    formData.append('audit_action_user[status]', status)
    const payload = formData

    status === 'completed'
      ? setSignaturePopup(true)
      : mutate(
          payload,
          {
            onSuccess: () => {
              showToast({ message: 'Action status has been changed' })
              callRefetch()
            },
          },
          `${apiEndpoint}/${data.id}`
        )
  }

  const closeStatus = (data) => {
    setSignaturePopup(false)
    data && callRefetch()
  }

  return (
    <>
      <SignatureModal
        signature={signaturePopup}
        closepopup={closeStatus}
        status={status}
        ratId={ratId}
        isMutating={isMutating}
        mutate={mutate}
        id={id}
        callRefetch={callRefetch}
        page={page}
      />
      <StyledTableContainer component={Paper}>
        <Table aria-label="collapsible table" className="participant-table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>Name</StyledTableHeadCell>
              <StyledTableHeadCell>Company</StyledTableHeadCell>
              <StyledTableHeadCell>Staus</StyledTableHeadCell>
              <StyledTableHeadCell>Action</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((row) => (
                <Row
                  key={row.full_name}
                  row={row}
                  onClickAddSignature={onClickAddSignature}
                  onChangeStatus={onChangeStatus}
                />
              ))
            ) : (
              <TableRow>
                <StyledTableCell scope="row" colSpan={4}>
                  <p style={{ textAlign: 'center' }}>No records</p>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {2 && (
        <>
          <div
            className="d-flex"
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Typography
              variant={'h6'}
              sx={{ color: '#080808', fontWeight: '700', fontSize: '20px' }}
            >
              View Report
            </Typography>
            <FormControlLabel
              sx={{
                backgroundColor: '#f6f6f6',
                padding: '0 15px 0 5px',
                borderRadius: '8px',
              }}
              control={
                <Checkbox
                  checked={display_audit_trail}
                  onChange={handleChange}
                  color="primary"
                  style={{ color: '#407797' }}
                />
              }
              label="Append Audit Trail Notes"
            />
          </div>
          {pdf_link && <PDFViewer pdfUrl={`${window.location.origin}/api/v2/${pdf_link}`} />}
        </>
      )}
    </>
  )
}
