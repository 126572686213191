import React from 'react'
import useUpdate from '../../../shared/components/useUpdate'
import NewTrainingRecord from './NewTrainingRecord'

const CreateTraining = ({
  open,
  setOpen,
  setFilterState,
  refetch,
  optimisticUpdate,
}) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/rot_management/record_of_trainings')
  return (
    <NewTrainingRecord
      open={open}
      mutate={mutate}
      setOpen={setOpen}
      setFilterState={setFilterState}
      refetch={refetch}
      isMutating={isMutating}
      optimisticUpdate={optimisticUpdate}
    />
  )
}

export default CreateTraining
