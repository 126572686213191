import React from "react";
import SideNav from "../../components/SideNav";
import GroupQR from "./Group";
import TemplateQR from "./Template";

const QRCodes = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let templateId = params.get('template_id');
    return (
        <React.Fragment>
            {/* <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav /> */}
                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <h1>
                                {/* <img src={CloseButton} alt="" className="header-closer-img" /> */}
                                <i
                                    style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                                    className="fa fa-qrcode"
                                ></i>
                                <b>QR Codes</b>
                            </h1>
                            <ul className="nav nav-tabs">
                                <li className= {!templateId ? "active" : "" }>
                                    <a data-toggle="tab" href="#asset-tracking">
                                        Group QR Codes
                                    </a>
                                </li>
                                <li className={templateId ? "active" : "" }>
                                    <a data-toggle="tab" href="#asset-type">
                                        Template QR Codes
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="asset-tracking" className={!templateId ? "tab-pane fade in active" : "tab-pane fade in "}>
                                <GroupQR />
                            </div>
                            <div id="asset-type" className={templateId ? "tab-pane fade in active" : "tab-pane fade in "}>
                                <TemplateQR templateId={templateId}/>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </React.Fragment>
    );
};

export default QRCodes;