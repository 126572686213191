import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Button} from "@mui/material";
import SideNav from "../../../components/SideNav";
import ArrowUp from "../../../assets/fonts/ArrowRight.svg";
import Stack from "../../../assets/images/Stack.svg";
import Accordion from "./Accordion";
import AccordionContent from "./AccordionContent";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Cookies from "js-cookie";

const ShowWorkEntries = ({ history, match }) => {
    const cookieDetails = Cookies.get("token");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState();
    const [timeTypes, setTimeTypes] = React.useState([]);
    const [classifications, setClassifications] = React.useState([]);
    const [costCodes, setCostCodes] = React.useState([]);
    const [billingTos, setBillingTos] = React.useState([]);
    const [lunchIntervals, setLunchIntervals] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [userIds, setUserIds] = React.useState([]);
    const [groupIds, setGroupIds] = React.useState([]);
    const [workEntries, setWorkEntries] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const timeCardId = match.params.id;

    const goBack = () => {
        history.push("/time-tracking");
    };

    useEffect(() => {
        getWorkEntries();
    }, [refresh]);

    useEffect(() => {
        if(users.length > 0) {
            const userData = filterUsersByIds(users, userIds)
            setSelectedUsers(userData);
            const data = userData.map(user => ({
                title: user.name,
                userId: user.id,
                workEntries: workEntries,
                content: <AccordionContent
                    groups={groups}
                    costCodes={costCodes}
                    timeTypes={timeTypes}
                    classifications={classifications}
                    billingTos={billingTos}
                    lunchIntervals={lunchIntervals}
                    userId={user.id}
                    cardId={timeCardId}
                    workEntries={workEntries}
                    viewWorkEntry={true}
                />
            }));

            setItems(data)
        }
    }, [users, workEntries])

    const getWorkEntries = async () => {
        let time_card_data = await axios.get(
            `${Application.api_url}/api/v2/time_cards/${timeCardId}`
        );

        
        if (time_card_data.data && time_card_data.data.success === true) {
            setUserIds(time_card_data.data.data.user_ids)
            setGroupIds(time_card_data.data.data.group_ids)
        }

        let list = await axios.get(
            `${Application.api_url}/api/v2/common/time_card_base_data`
        );
        if (list.data && list.data.success === true) {

            
            setTimeTypes(list.data.data.time_types);
            setClassifications(list.data.data.classifications);
            setCostCodes(list.data.data.cost_codes);
            setBillingTos(list.data.data.billing_tos);
            setGroups(list.data.data.groups);
            setLunchIntervals(list.data.data.lunch_intervals);
            setUsers(list.data.data.users);
        }

        let workEntryData = await axios.get(
            `${Application.api_url}/api/v2/time_cards/${timeCardId}/work_entries`
        );
        if (workEntryData && workEntryData.data && workEntryData.data.success === true) {
            try {
              var rawData = workEntryData.data.data;
              if (!Array.isArray(rawData)) {
                console.error("Expected work entry data to be an array.");
                return;
              }
              var transformedData = rawData.map(function(item, index) {
                return {
                  id: index,
                  classification_id: (item.classification && item.classification.id) ? item.classification.id.toString() : "",
                  cost_code_id: (item.cost_code && item.cost_code.id) ? item.cost_code.id.toString() : "",
                  time_type_id: (item.time_type && item.time_type.id) ? item.time_type.id.toString() : "",
                  start_time: item.start_time || "",
                  end_time: item.end_time || "",
                  group_id: (item.group && item.group.id) ? item.group.id.toString() : "",
                  lunch_time: item.lunch_time ? item.lunch_time.toString() : "",
                  comments: item.comments || "",
                  is_billable: typeof item.is_billable !== "undefined" ? item.is_billable : false,
                  billing_to_id: (item.billing_to && item.billing_to.id) ? item.billing_to.id.toString() : "",
                  user_id: (item.user && item.user.id) ? item.user.id.toString() : "",
                  work_entry_id: (typeof item.id !== "undefined" ? item.id : null),
                  on_submit: false,
                  inside_of_geofence: (typeof item.inside_of_geofence !== "undefined" ? item.inside_of_geofence : false),
                  group_geofence_on: (typeof item.group_geofence_on !== "undefined" ? item.group_geofence_on : false),
                  from_web: true
                };
              });
              setWorkEntries(transformedData);
            } catch (error) {
              console.error("Error processing work entry data:", error);
            }
          }
    };

    function filterUsersByIds(users, userIds) {
        return users.filter(user => userIds.includes(user.id.toString()));
    }

    return (
        <React.Fragment>
            {/* <div className="msmcontainer dashboard-container create-time-card">
                <div className="row d-flex">
                    <SideNav /> */}
                    <div className="content-wrap add-internal-subs">
                        <div className="title-card">
                            <div className="title-content">
                                <div>
                                    <img
                                        src={ArrowUp}
                                        alt="back-arrow"
                                        className="back-arrow"
                                        onClick={(e) => {
                                            goBack();
                                        }}
                                    />
                                </div>
                                <h4>
                                    <b>View Time Card</b>
                                </h4>
                            </div>
                            <div>
                                <Button className="btn-create" variant="contained"
                                        onClick={() => goBack()}
                                >
                                  <span>
                                    <img src={Stack} alt="Stack" />
                                  </span>
                                    Exit
                                </Button>
                            </div>
                        </div>
                        <div className="body-card">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        items.length > 0 && <Accordion items={items} timeCardId={timeCardId} setRefresh={setRefresh} viewWorkEntry={true} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </React.Fragment>
    );
};

export default withRouter(ShowWorkEntries);
