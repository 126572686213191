import {
  Autocomplete,
  Box,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import { setROTPageCount } from '../../../../reducers/common'
import CustomModal from '../../../shared/components/CustomModal'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ActionMenu,
  ButtonCreate,
  CustomPagination,
  HeaderItem,
  TagRender,
} from '../../../shared/components/table'

import ActiveFilters from '../../../shared/components/activeFilters'
import useFilterChange from '../../../shared/components/useFilterChange'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { rotIcons } from '../../rotIcons'
import { ScheduleCreate, ScheduleEdit } from './ScheduleForm'

const ScheduleExportList = ({ rotCount, setROTPageCount, baseData }) => {
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange({ page: 1, limit: 20 })
  const {
    data: responseData = {},
    isLoading,
    refetch,
  } = useFetch('/api/v2/rot_management/training_scheduled_exports', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { mutate, isLoading: isDeleting } = usePatch()

  const [edit, setEdit] = useState(false)
  const [addNew, setNew] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  const changeActive = (id, data) => {
    mutate(
      { id, is_active: !data.is_active },
      {
        onSuccess: () => {
          showToast({
            message: (
              <>
                <b>{data.name}</b>
                {` Schedule exports has ${
                  data.is_active ? 'Inactivated' : 'Activated'
                }`}
              </>
            ),
            style: {
              backgroundColor: '#EBF3FF',
              color: '#080808',
            },
          })
          refetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/rot_management/training_scheduled_exports/${id}/update_active_status`
    )
  }

  useEffect(() => {
    metadata.count &&
      setROTPageCount({
        config: {
          t_topics: metadata.count || rotCount.config.t_topics,
          a_topics: rotCount.config.a_topics,
        },
      })
  }, [metadata.count])

  const {
    status = [],
    intervals = [],
    permissions: {
      allow_archive = true,
      allow_delete = true,
      allow_edit = true,
      allow_view = true,
    } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]

  const statusFilterData = createFilterData(status)
  const intervalFilterData = createFilterData(intervals)
  const headers = [
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('name')}
        title="Schedule Name"
        filter={
          <InputSearchFilter
            style={{ width: '100%', background: '#fff' }}
            name="name_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        isSort
        handleSort={() => handleSort('interval')}
        title="Interval"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              intervalFilterData.find(
                (e) => e.value === filterData['interval_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'interval_eq')}
            options={[
              ...intervalFilterData.map((e) => ({
                ...e,
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Groups / Projects Site" />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Created At"
        isSort
        handleSort={() => handleSort('created_at')}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        // isSort
        title="Status"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              statusFilterData.find(
                (e) => e.value === filterData['is_active_true']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'is_active_true')
            }
            options={statusFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const { name, interval, created_at, status_text, group_names } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell>{name || '-'}</TableCell>
        <TableCell>{interval || '-'}</TableCell>
        <TableCell>
          <TagRender data={group_names} users />
        </TableCell>
        <TableCell>{created_at || '-'}</TableCell>
        <TableCell>
          <span
            className={`action_status ${ele.is_active ? 'active' : 'expired'}`}
          >
            {status_text}
          </span>
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          {' '}
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              menuItems.push(
                <MenuItem onClick={() => setEdit(ele.id, setClose(true))}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.edit}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      Edit
                    </span>
                  </Box>
                </MenuItem>
              )
              menuItems.push(
                <MenuItem
                  onClick={() => changeActive(ele.id, ele, setClose(true))}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {rotIcons.reload}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      {`Set as ${ele.is_active ? 'Inactive' : 'Active'}`}
                    </span>
                  </Box>
                </MenuItem>
              )
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          border: 0,
          borderStyle: 'solid',
          borderColor: '#D3D3D3',
          borderWidth: '1px 1px 1px 1px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography variant="h6">Schedule Exports</Typography>
        <ButtonCreate
          label="New Schedule"
          styleOverride={{
            color: '#fff',
            background: '#407797',
            fontWeight: '600',
          }}
          iconWhite
          onClick={() => setNew(true)}
        />
      </Box>
      <CustomTable
        headers={headers}
        rows={rows}
        renderActiveFilter={
          <>
            <ActiveFilters
              filterData={filterState}
              update={setFilterState}
              keyName="action_notices"
            />
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}

      {addNew && (
        <CustomModal
          title="+ New Schedule"
          width="50%"
          height="auto"
          handleClose={() => setNew(false)}
          open={addNew}
        >
          <ScheduleCreate
            onClose={() => setNew(false)}
            refetch={callRefetch}
            setFilterState={setFilterState}
          />
        </CustomModal>
      )}
      {edit && (
        <CustomModal
          title="+ Edit Schedule"
          width="50%"
          height="auto"
          handleClose={() => setEdit(false)}
          open={!!edit}
          overrideStyle={{ padding: '24px' }}
        >
          <ScheduleEdit
            onClose={() => setEdit(false)}
            id={edit}
            refetch={callRefetch}
            setFilterState={setFilterState}
          />
        </CustomModal>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    rotCount: state.CommonReducer.rotCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setROTPageCount: (value) => dispatch(setROTPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleExportList)
